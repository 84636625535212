import React from 'react'
import Page from '../../../components/Page'

import InventoryHeader from './InventoryHeader'
import InventoryBody from './InventoryBody'

const InventoryIndex = () => {
   return (
      <Page title="Inventory">
         <InventoryHeader />
         <InventoryBody />
      </Page>
   )
}

export default InventoryIndex