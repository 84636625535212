import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { Button, Col, Modal, Row } from 'antd';
import CustomMultipleAutoCompleteField from '../../../../components/hookform/CustomAutoCompleteField';
import { createDeptItem, getDepartmentList, updateDeptItem } from '../../../../redux/slices/departmentSlice';
import RHFTextField from '../../../../components/hookform/RHFTextField';
import CustomLoadingScreen from '../../../../components/CustomLoadingScreen';
import RHFSelect from '../../../../components/hookform/RHFSelect';
import { MenuItem } from '@mui/material';

const AddDepartmentItem = ({ open, setOpen, recordData, isEdit }) => {
  const { postLoading, departmentList, loading } = useSelector((state) => state.department);

  const defaultValues = {};
  const dispatch = useDispatch();

  const departmentItemSchema = Yup.object().shape({
    item_name: Yup.string().required('Item  name is required'),
  });

  const methods = useForm({ defaultValues, resolver: yupResolver(departmentItemSchema) });
  const { handleSubmit, reset } = methods;

  const DepartmentItemSubmit = (data) => {
    const isSuccessful = (isSuccess) => {
      if (isSuccess) {
        setOpen(false);

        isEdit
          ? toast.success('Department Item Updated successfully')
          : toast.success('Department Item created successfully');
      }
    };
    if (isEdit) {
      dispatch(updateDeptItem({ data, id: recordData.deptItem_id, isSuccess: isSuccessful }));
    } else {
      dispatch(createDeptItem({ data, isSuccess: isSuccessful }));
    }
  };

  useEffect(() => {
    dispatch(getDepartmentList());
  }, [dispatch]);

  useEffect(() => {
    if (!isEdit) return;
    reset(recordData);
  }, [recordData, isEdit, reset]);


  return (
    <Modal
      title={isEdit ? 'Edit Department Item' : 'Add Department Item'}
      open={open}
      footer={[
        <Button key="back" onClick={() => setOpen(false)}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" onClick={handleSubmit(DepartmentItemSubmit)} disabled={postLoading}>
          OK
        </Button>,
      ]}
      onCancel={() => setOpen(false)}
    >
      <FormProvider {...methods}>
        {loading ? (
          <CustomLoadingScreen height="10vh" />
        ) : (
          <Row gutter={[8, 12]}>
            <Col span={12}>
              <CustomMultipleAutoCompleteField
                name="dept_id"
                label="Select Department*"
                options={departmentList || []}
                getOptionLabel={(option) => option?.dept_name}
                isOptionEqualToValue={(option, value) => option.detp_id === value.dept_id}
                // sx={{ width: '14.5rem' }}
              />
            </Col>
            <Col span={12}>
              <RHFTextField name="item_name" label="Item Name" />
            </Col>
            {/* <Col span={24}>
                     <CustomMultipleAutoCompleteField
                        name="quality_ref"
                        label="Select Quality*"
                        multiple
                        options={departmentList || []}
                        getOptionLabel={(option) => option?.dept_name}
                        isOptionEqualToValue={(option, value) => option.detp_id === value.dept_id}
                     // sx={{ width: '14.5rem' }}
                     />
                  </Col> */}

            <Col span={24}>
              <CustomMultipleAutoCompleteField
                name="ingredients"
                label="Select Ingredients*"
                multiple
                options={departmentList || []}
                getOptionLabel={(option) => option?.dept_name}
                isOptionEqualToValue={(option, value) => option.detp_id === value.dept_id}
                // sx={{ width: '14.5rem' }}
              />
            </Col>
            <Col span={12}>
              <RHFTextField name="quantity" label="Quantity" />
            </Col>
            <Col span={12}>
              <RHFSelect name="unit" label="Unit">
                <MenuItem value="kg">kg</MenuItem>
                <MenuItem value="gm">gm</MenuItem>
                <MenuItem value="pcs">pcs</MenuItem>
                <MenuItem value="ltr">Liter</MenuItem>
              </RHFSelect>
            </Col>
          </Row>
        )}
      </FormProvider>
    </Modal>
  );
};

export default AddDepartmentItem;
