import * as React from 'react';
// import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Controller, useFormContext } from 'react-hook-form';
import PropTypes from 'prop-types';


// loopting through selection like this

// {userBreak?.map((option) => (
//   <MenuItem
//     key={option.id}
//     value={option.minutes}
//     // onClick={() => handleSelectService(index, option.name)}
//   >
//     {option.minutes} minutes
//   </MenuItem>
// ))}

export default function CustomSelectField({ children, name, label = 'label', labelShrink = false, ...other }) {
  const [age, setAge] = React.useState('');
  const { control } = useFormContext();

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <FormControl fullWidth>
          <InputLabel id="selectFieldForm" shrink={Boolean(field.value)}>
            {label}
          </InputLabel>
          <Select value={age} labelId="selectFieldForm" label={label} onChange={handleChange} {...field} {...other}>
            {children}
          </Select>
        </FormControl>
      )}
    />
  );
}
