import React from 'react';
import HeaderBreadcrumbs from '../../../../components/HeaderBreadcrumbs';
import { Button } from '@mui/material';
import Iconify from '../../../../components/Iconify';
import { PATH_DASHBOARD } from '../../../../routes/paths';
import AddDepartmentItem from './AddDepartmentItem';

const DeptItemHeader = () => {
  const [addDeptItem, setAddDeptItem] = React.useState(false);
  return (
    <>
      <HeaderBreadcrumbs
        heading="Department Items"
        links={[
          { name: 'Dashboard', href: PATH_DASHBOARD.root },
          { name: 'Department', href: PATH_DASHBOARD.department.root },
          { name: 'Department Items', href: PATH_DASHBOARD.department.items },
        ]}
        action={
          <Button
            variant="contained"
            startIcon={<Iconify icon={'eva:plus-fill'} width={20} height={20} />}
            onClick={() => setAddDeptItem(true)}
          >
            Items
          </Button>
        }
      />
      {addDeptItem && <AddDepartmentItem open={addDeptItem} setOpen={setAddDeptItem} />}
    </>
  );
};

export default DeptItemHeader;
