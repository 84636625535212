import { Box, Button, CircularProgress } from '@mui/material';
import React from 'react';
import { Modal, Typography } from 'antd';
import CustomLoadingScreen from './CustomLoadingScreen';
import { WarningOutlined } from '@ant-design/icons';

// openDialogBox is a bolean, deleteDialogBoxClose is a function and onAccept is a function

function CustomDeleteDialog({ status, handleClose, onAccept, title = 'Are you sure you want to delete?', loading }) {
  return (
    <Modal
      open={status}
      onCancel={handleClose}
      footer={null}
      closable={false}
      style={{ zIndex: '3000' }}
      className="customDelete"
    >
      <Box className="deleteObjectDialogBox">
        <Typography
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            fontSize: '2vw',
            color: '#fff',
          }}
        >
          Confirmation
        </Typography>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%', // Add this to ensure the content takes full width
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center', position: 'relative' }}>
            <CircularProgress
              size={55}
              color="error"
              thickness={7}
              sx={{
                marginRight: '1rem',
                borderRadius: '30px',
              }}
            />
            <WarningOutlined
              style={{
                color: '#fff',
                position: 'absolute',
                left: 18,
                top: 19,
                fontSize: '20px',
              }}
            />
          </div>

          {/* Add your text here */}
          <Typography variant="body2" color={'#fff'} style={{ flex: 1, color: '#fff' }}>
            {title}
          </Typography>
        </div>
        <Box className="deleteObjectDialogBoxBody">
          <Button
            variant="outlined"
            color="error"
            size="small"
            onClick={handleClose}
            sx={{
              backgroundColor: 'error',
              color: '#fff',
              // border: '1px solid #fff',
            }}
            // style={{ marginRight: '0.5rem', color: '#fff', border: '1px solid #fff !important' }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="error"
            size="small"
            onClick={onAccept}

            // style={{ marginRight: '0.5rem', color: '#fff', border: '1px solid #fff !important' }}
          >
            Confirm
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}

export default CustomDeleteDialog;
