import React from 'react'
import Page from '../../../components/Page'
import CreateUserHeader from './CreateUserHeader'
import CreateUserBody from './CreateUserBody'

const UserCreate = () => {
   return (
      <Page title="User Create">
         <CreateUserHeader />
         <CreateUserBody />
      </Page>
   )
}

export default UserCreate