import { Stack } from '@mui/material';
import React from 'react';
import MomoChooseCategory from './MomoChooseCategory';
import MomoChooseBatch from './MomoChooseBatch';
import MomoIngredients from './MomoIngredients';
import SanitationCheckList from '../SanitationCheckList';
import MomoSummary from './MomoSummary';
import MomoQuality from './MomoQuality';
import ProcessingLoadingScreen from '../ProcessingLoadingScreen';

export default function MomoDepartmentActiveStepper({ activeStep, allSkusScanned }) {
  return (
    <Stack>
      {activeStep === 0 && <MomoChooseCategory />}
      {activeStep === 1 && <MomoChooseBatch />}
      {activeStep === 2 && <SanitationCheckList />}
      {activeStep === 3 && <MomoIngredients />}
      {activeStep === 4 && <ProcessingLoadingScreen />}
      {activeStep === 5 && <MomoQuality />}
      {activeStep === 6 && <MomoSummary />}
    </Stack>
  );
}
