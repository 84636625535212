import { Stack } from '@mui/material';
import React from 'react';
import SauceChooseCategory from './SauceChooseCategory';
import SauceChooseBatch from './SauceChooseBatch';
import SanitationCheckList from '../SanitationCheckList';
import SauceIngredient from './SauceIngredient';
import ProcessingLoadingScreen from '../ProcessingLoadingScreen';
import SauceQuality from './SauceQuality';
import SauceSummary from './SauceSummary';

function SauceDepartmentActiveStepper({ activeStep }) {
  return (
    <Stack>
      {activeStep === 0 && <SauceChooseCategory />}
      {activeStep === 1 && <SauceChooseBatch />}
      {activeStep === 2 && <SanitationCheckList />}
      {activeStep === 3 && <SauceIngredient />}
      {activeStep === 4 && <ProcessingLoadingScreen />}
      {activeStep === 5 && <SauceQuality />}
      {activeStep === 6 && <SauceSummary />}
    </Stack>
  );
}

export default SauceDepartmentActiveStepper;
