import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { FormControl, MenuItem, Select } from '@mui/material';
import { Badge, Button, Collapse, List, Modal, Space, Table, Tag } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import CustomDeleteDialog from '../../../components/CustomDeleteDialog';
import CustomLoadingScreen from '../../../components/CustomLoadingScreen';
import { deleteInventory, getInventoryList } from '../../../redux/slices/inventorySlice';
import AddInventoryItem from './AddInventory';

const { Panel } = Collapse;

const InventoryBody = () => {
  const dispatch = useDispatch();
  const [isEdit, setIsEdit] = React.useState(false);
  const [recordData, setRecordData] = React.useState(null);
  const [isDelete, setIsDelete] = useState({ productSkus: null, status: false });
  const [selectedCategory, setSelectedCategory] = useState(null);
  const { inventoryList, loading } = useSelector((state) => state.inventory);
  const [sort, setSort] = useState('descend');
  const user = JSON.parse(localStorage.getItem('factoryUserDetails'));
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentSkus, setCurrentSkus] = useState([]);
  const [currentProductName, setCurrentProductName] = useState('');

  const userGroup = user?.roles;

  const formatCurrency = (value) => {
    const formattedValue = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    }).format(value);

    return formattedValue;
  };

  const showModal = (productName, skus) => {
    setCurrentSkus(skus);
    setCurrentProductName(productName);
    setIsModalVisible(true);
  };

  const handleClose = () => {
    setIsModalVisible(false);
  };

   const toggleSortOrder = () => {
     setSort((prev) => (prev === 'ascend' ? 'descend' : 'ascend'));
   };

  const handleCategoryChange = (value) => {
    setSelectedCategory(value);
  };


  const filteredData = selectedCategory
    ? inventoryList?.filter((item) => item.productCategory === selectedCategory)
    : inventoryList;

  const columns = [
    // { title: 'S.N', dataIndex: 'productId', key: 'productId' },
    {
      title: 'SKU',
      dataIndex: 'skuList',
      key: 'skuList',
      render: (skus, record) => (
        <Badge
          count={skus.length}
          style={{ backgroundColor: '#52c41a', cursor: 'pointer' }}
          onClick={() => showModal(record.productName, skus)}
        >
          <a style={{ marginRight: '1rem' }}>SKU</a>
        </Badge>
      ),
    },
    { title: 'Product Name', dataIndex: 'productName', key: 'productName' },
    { title: 'Category', dataIndex: 'productCategory', key: 'productCategory' },
    { title: 'Quantity ', dataIndex: 'quantity', key: 'quantity' },
    { title: 'Net Weight', dataIndex: 'totalWeight', key: 'totalweight', render: (text) => text?.replace('-', ' ') },
    {
      title: 'Price',
      dataIndex: 'totalPrice',
      key: 'totalPrice',
      render: (text) => (text === null ? 'N/A' : formatCurrency(text)),
    },
    {
      title: 'Freshness',
      dataIndex: 'freshness',
      key: 'freshness',
      render: (freshness) => (
        <Tag color={freshness === 3 ? 'green' : freshness === 2 ? 'yellow' : freshness === 1 ? 'orange' : 'red'}>
          {freshness === 3
            ? 'Fresh'
            : freshness === 2
            ? 'MID'
            : freshness === 1
            ? 'LOW'
            : freshness === 0
            ? 'Expired'
            : 'N/A'}
        </Tag>
      ),
      sorter: (a, b) => b.freshness - a.freshness,
      sortOrder: sort,
      onHeaderCell: () => ({
        onClick: toggleSortOrder,
      }),
    },
    userGroup.includes('ADMIN')
      ? {
          title: 'Action',
          key: 'action',
          render: (text, record) => (
            <Space size="middle">
              {
                <Button
                  onClick={() => {
                    setRecordData(record);
                    setIsEdit(true);
                  }}
                >
                  <EditOutlined />
                </Button>
              }

              {
                <Button
                  onClick={() => {
                    setIsDelete({ productSkus: record?.skuList, status: true });
                  }}
                >
                  <DeleteOutlined style={{ color: 'red' }} />
                </Button>
              }
            </Space>
          ),
        }
      : {},
  ];
  const deleteRecord = () => {
    const isSuccessful = (isSuccess) => {
      if (isSuccess) {
        setIsDelete({ id: null, status: false });
        toast.success('Product deleted successfully');
      }
    };

    dispatch(deleteInventory({ productSkus: isDelete?.productSkus, isSuccess: isSuccessful }));
  };

  useEffect(() => {
    dispatch(getInventoryList());
  }, [dispatch]);
  return (
    <>
      {loading ? (
        <CustomLoadingScreen />
      ) : (
        <>
          {}
          <div style={{ display: 'flex', justifyContent: '', width: '100%' }}>
            <FormControl style={{ width: 200, marginBottom: 20 }} variant="outlined">
              <span style={{ fontSize: '14px', marginBottom: '5px' }}>Filter By Categories</span>
              <Select
                size="small"
                value={selectedCategory || ''}
                variant="standard"
                onChange={(event) => handleCategoryChange(event.target.value)}
                label="Category"
              >
                {[...new Set((inventoryList || []).map((item) => item.productCategory))].map((category) => (
                  <MenuItem key={category} value={category}>
                    {category}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {selectedCategory && (
              <Tag
                closable={true}
                color="green"
                size="small"
                style={{ width: 'fit-content', height: 'fit-content', marginTop: '30px', marginLeft: '20px' }}
                onClose={() => setSelectedCategory(null)}
              >
                {selectedCategory}
              </Tag>
            )}
          </div>
          <Table columns={columns} dataSource={filteredData || []} loading={loading} rowKey="id" />
        </>
      )}
      {isDelete.status && (
        <CustomDeleteDialog
          status={isDelete.status}
          handleClose={() => setIsDelete({ id: null, status: false })}
          onAccept={deleteRecord}
        />
      )}

      {isEdit && (
        <AddInventoryItem isModalOpen={isEdit} setIsModalOpen={setIsEdit} recordData={recordData} isEdit={true} />
      )}

      {/* {set product Name as Modal title} */}
      <Modal title={`${currentProductName} SKU List`} open={isModalVisible} onOk={handleClose} onCancel={handleClose}>
        <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'center', flexDirection: 'column' }}>
          <List
            size="default"
            dataSource={currentSkus}
            renderItem={(item, index) => (
              <List.Item key={item} style={{ width: '100%' }}>
                {item}
              </List.Item>
            )}
          />
        </div>
      </Modal>
    </>
  );
};

export default InventoryBody;
