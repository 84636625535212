import { CircularProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';

function TimeComponent() {
  const [currentTime, setCurrentTime] = useState(new Date());
  const [remainingTime, setRemainingTime] = useState(10 * 60);
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(new Date());

      setRemainingTime((prevTime) => (prevTime > 0 ? prevTime - 1 : 0));
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
  };
  return (
    <div style={styles.container}>
      <div style={styles.card}>
        <div style={styles.text}>
          <div>Current Time: {currentTime.toLocaleTimeString()}</div>
          <div>Remaining Time: {formatTime(remainingTime)}</div>
        </div>
        <CircularProgress style={styles.loader} />
      </div>
    </div>
  );
}

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '40vh',
  },
  card: {
    padding: '20px',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: '20px',
  },
  text: {
    marginBottom: '20px',
  },
  loader: {
    color: '#007BFF', // You can change the color to your preference
  },
  scanCard: {
    width: '300px', // Set the width as needed
    margin: 'auto',
  },
};

export default TimeComponent;
