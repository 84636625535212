import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
// import instance from './../../api';
import { API } from '../../utils/url';
import instance from './../../api';

export const getInventoryList = createAsyncThunk('inventory/getInventoryList', async (thunkAPI) => {
  try {
    const response = await instance.get(API.inventoryProducts.batchProducts);
    return response.data?.data;
    console.log(response.data);
  } catch (error) {
    toast.error(error?.response?.data?.message);
    throw error;
  }
});

export const createInventory = createAsyncThunk('inventory/createInventory', async (props, thunkAPI) => {
  try {
    const response = await instance.post(`/product/batch-save`, props.data);
    if (response.status === 200) {
      props.isSuccess(true);
      thunkAPI.dispatch(getInventoryList());
    }
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    throw error;
  }
});
// export const createInventory = createAsyncThunk('inventory/createInventory', async (props, thunkAPI) => {
//    try {
//       const response = await axios.post('https://653b72a92e42fd0d54d520e7.mockapi.io/demo/v1/inventory', props.data);
//       console.log("🚀 => createInventory => response=>", response)
//       if (response.status === 201) {
//          props.isSuccess(true)
//          thunkAPI.dispatch(getInventoryList());
//       }
//       return response.data;
//    } catch (error) {
//       toast.error(error?.response?.data?.message);
//       throw error;
//    }
// });

export const deleteInventory = createAsyncThunk('inventory/deleteInventory', async (props, thunkAPI) => {
  console.log(props?.productSkus, 'props');
  try {
    const response = await instance.delete(`${API.inventoryProducts.batchDeleteProduct}`, {
      data: { productSkus: props?.productSkus },
    });
    if (response.status === 200) {
      props.isSuccess(true);
      thunkAPI.dispatch(getInventoryList());
    }
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    throw error;
  }
});

// export const deleteInventory = createAsyncThunk('inventory/deleteInventory', async (props, thunkAPI) => {
//    try {
//       const response = await axios.delete(`https://653b72a92e42fd0d54d520e7.mockapi.io/demo/v1/inventory/${props.id}`);
//       if (response.status === 200) {
//          props.isSuccess(true)
//          thunkAPI.dispatch(getInventoryList());
//       }
//       return response.data;
//    } catch (error) {
//       toast.error(error?.response?.data?.message);
//       throw error;
//    }
// });

export const updateInventory = createAsyncThunk('inventory/updateInventory', async (props, thunkAPI) => {
  try {
    const response = await instance.post(`product/update`, props.data);
    if (response.status === 200) {
      props.isSuccess(true);
      thunkAPI.dispatch(getInventoryList());
    }
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    throw error;
  }
});
// export const updateInventory = createAsyncThunk('inventory/updateInventory', async (props, thunkAPI) => {
//    try {
//       const response = await axios.put(`https://653b72a92e42fd0d54d520e7.mockapi.io/demo/v1/inventory/${props.id}`, props.data);
//       if (response.status === 200) {
//          props.isSuccess(true)
//          thunkAPI.dispatch(getInventoryList());
//       }
//       return response.data;
//    } catch (error) {
//       toast.error(error?.response?.data?.message);
//       throw error;
//    }
// });

const inventorySlice = createSlice({
   name: 'inventory',
   initialState: {
      inventoryList: [],
      loading: false,
      postLoading: false,
   },
   reducers: {},
   extraReducers: {
      [getInventoryList.pending]: (state) => {
         state.loading = true;
      },
      [getInventoryList.fulfilled]: (state, action) => {
         state.inventoryList = action.payload;
         state.loading = false;
      },
      [getInventoryList.rejected]: (state) => {
         state.loading = false;
      },

      [createInventory.pending]: (state) => {
         state.postLoading = true;
      },
      [createInventory.fulfilled]: (state, action) => {
         state.inventoryList = action.payload;
         state.postLoading = false;
      },
      [createInventory.rejected]: (state) => {
         state.postLoading = false;
      },
      [updateInventory.pending]: (state) => {
         state.postLoading = true;
      },
      [updateInventory.fulfilled]: (state, action) => {
         state.postLoading = false;
      },
      [updateInventory.rejected]: (state) => {
         state.postLoading = false;
      },
   }
});

export default inventorySlice.reducer;