import React, { useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { getDeptSkulist } from '../../../redux/slices/departmentSlice';
import { Grid, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import Iconify from '../../../components/Iconify';
import QrCodeScanner from '../../../components/QrCodeScanner';

function RawMeatIngredient() {
  const [barcodeScan, setBarcodeScan] = React.useState(false);
  const [prevData, setPrevData] = React.useState(null);
  const [scannedSkus, setScannedSkus] = React.useState(new Set());

  const dispatch = useDispatch();

  const { deptSkuList } = useSelector((state) => state.department);

  const { watch, setValue, control } = useFormContext();
  const deptName = 'Raw Meat';
  const selectedCategory = watch('selectedCategory');

  const selectedBatch = watch('selectedBatch');

  const handleBarcodeScan = (data) => {
    if (prevData && data.text === prevData) {
      setScannedSkus(new Set(scannedSkus.add(prevData)));
      updateVerificationStatus(prevData, true);
    }
    setBarcodeScan(false);
  };

  const updateVerificationStatus = (sku, isVerified) => {
    // Update the verification status in the state
    const updatedSkus = new Set(scannedSkus);
    updatedSkus.add(sku);
    setScannedSkus(updatedSkus);

    // Update the verification status in the form data
    const updatedIngredients = watch('ingredients').map((item) => {
      const updatedSkuList = item.sku.map((s) => (s.code === sku ? { ...s, isVerified } : s));
      return { ...item, sku: updatedSkuList };
    });

    setValue('ingredients', updatedIngredients);
  };

  useEffect(() => {
    dispatch(getDeptSkulist({ batch: selectedBatch, deptCategory: selectedCategory, deptName: deptName }));
  }, [dispatch, selectedBatch, selectedCategory]);

  useEffect(() => {
    if (!deptSkuList.length) return setValue('ingredients', []);

    const resetData = deptSkuList?.map((item) => {
      const skus = item?.sku?.map((sku) => ({ code: sku, isVerified: false }));

      return { ...item, sku: skus }; // Corrected syntax here
    });

    setValue('ingredients', resetData);
  }, [deptSkuList, setValue]);

  const ingredientsList = useWatch({
    control,
    name: 'ingredients',
  });
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={9}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Ingredient Name</TableCell>
              <TableCell>Ingredient Qty</TableCell>
              <TableCell>SKU</TableCell>
              <TableCell>Scan</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {ingredientsList?.map((item, parentIndex) => (
              <TableRow key={parentIndex}>
                <TableCell>
                  {item?.ingredient?.productName} {item?.ingredient?.productType}
                </TableCell>
                <TableCell>
                  {item?.ingredient?.quantity} {item?.ingredient?.unit}
                </TableCell>

                <TableCell>
                  {item.sku?.map((sku, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        {sku.code}{' '}
                        {scannedSkus.has(sku.code) && (
                          <Iconify icon="emojione:check-mark-button" width={15} height={15} />
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableCell>
                <TableCell>
                  {item?.sku?.map((sku) => (
                    <TableRow key={sku}>
                      <TableCell>
                        <Iconify
                          icon="mdi:barcode-scan"
                          width={20}
                          height={20}
                          style={{ cursor: 'pointer' }}
                          onClick={() => {
                            setBarcodeScan(true);
                            setPrevData(sku.code);
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Grid>
      <Grid item xs={12} md={3} justifyContent={'center'} alignItems={'center'}>
        {barcodeScan && <QrCodeScanner onClose={() => setBarcodeScan(false)} onScan={handleBarcodeScan} />}
      </Grid>
    </Grid>
  );
}

export default RawMeatIngredient;
