import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Filter1Outlined } from '@mui/icons-material';
import { MenuItem } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Button, Modal, Space, Switch, Table, Tag } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import CustomDeleteDialog from '../../../components/CustomDeleteDialog';
import {
  deleteProductReference,
  getProductsReference,
  updateProductReference,
} from '../../../redux/slices/productSlice';
import AddProduct from './AddProduct';
const ProductReferenceBody = () => {
  const dispatch = useDispatch();
  const [isEdit, setIsEdit] = useState(false);
  const [expandedDescription, setExpandedDescription] = useState(null);
  const [recordData, setRecordData] = useState(null);
  const { referenceProducts, loading } = useSelector((state) => state.products);
  const [isDelete, setIsDelete] = useState({ id: null, status: false });
  const [isSwitchModalVisible, setIsSwitchModalVisible] = useState(false);
  const [changedProductSwitch, setChangedProductSwitch] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const user = JSON.parse(localStorage.getItem('factoryUserDetails'));
  const userGroup = user?.roles;

  const handleSwitchChange = (record) => {
    setIsSwitchModalVisible(true);
    setChangedProductSwitch({
      ...record,
      reorderSwitch: !record.reorderSwitch,
      productUnit: record?.productUnit,
    });
  };

  const handleCategoryChange = (value) => {
    setSelectedCategory(value);
  };

  const filteredData = selectedCategory
    ? referenceProducts?.data.filter((item) => item.productCategory === selectedCategory)
    : referenceProducts?.data;

  console.log('filteredData', filteredData);
  const productReorderChangeOk = () => {
    dispatch(updateProductReference({ data: changedProductSwitch, id: changedProductSwitch?.id }));
    setIsSwitchModalVisible(false);
  };
  const columns = [
    { title: 'S.N', dataIndex: 'id', key: 'id' },
    { title: 'Name', dataIndex: 'productName', key: 'productName' },
    { title: 'Category', dataIndex: 'productCategory', key: 'productCategory' },
    { title: 'Product Unit', dataIndex: 'productUnit', key: 'productUnit' },
    { title: 'Reorder Quantity', dataIndex: 'reorderQty', key: 'reorderQty' },

    {
      title: 'Reorder',
      dataIndex: 'reorderSwitch',
      key: 'reorderSwitch',
      render: (text, record) => (
        <Switch
          checked={record.reorderSwitch}
          onChange={() => handleSwitchChange(record)}
          style={{ background: record?.reorderSwitch ? 'green' : '' }}
          disabled={userGroup.includes('FACTORYTECH')}
        />
      ),
    },
    userGroup.includes('ADMIN') || userGroup.includes('MANAGER')
      ? {
          title: 'Action',
          key: 'action',
          render: (text, record) => (
            <Space size="middle">
              {(userGroup.includes('ADMIN') || userGroup.includes('MANAGER')) && (
                <Button
                  onClick={() => {
                    setRecordData(record);
                    setIsEdit(true);
                  }}
                >
                  <EditOutlined />
                </Button>
              )}

              {/* <Popconfirm
            title={`Are you sure you want to delete ${record?.productName} from Product list?`}
            onConfirm={() => deleteRecord(record)}
            placement="top"
          > */}
              {userGroup.includes('ADMIN') && (
                <Button
                  onClick={() => {
                    setIsDelete({ id: record.id, status: true });
                  }}
                >
                  <DeleteOutlined style={{ color: 'red' }} />
                </Button>
              )}
              {/* </Popconfirm> */}
            </Space>
          ),
        }
      : {},
  ];

  const deleteRecord = () => {
    const isSuccessful = (isSuccess) => {
      if (isSuccess) {
        setIsDelete({ id: null, status: false });
        toast.success('Product deleted successfully');
      }
    };

    dispatch(deleteProductReference({ id: isDelete.id, isSuccess: isSuccessful }));
  };

  useEffect(() => {
    dispatch(getProductsReference());
  }, [dispatch]);
  return (
    <div style={{ overflowX: 'auto' }}>
      <div style={{ display: 'flex' }}>
        <FormControl style={{ width: 200, marginBottom: 20 }} variant="outlined">
          <span style={{ fontSize: '14px', marginBottom: '5px' }}>Filter By Categories</span>
          <Select
            size="small"
            value={selectedCategory || ''}
            variant="standard"
            onChange={(event) => handleCategoryChange(event.target.value)}
            label="Category"
          >
            {[
              ...new Set(
                Array.isArray(referenceProducts?.data) ? referenceProducts.data.map((item) => item.productCategory) : []
              ),
            ].map((category) => (
              <MenuItem key={category} value={category}>
                {category}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {selectedCategory && (
          <Tag
            closable={true}
            color="green"
            size="small"
            style={{ width: 'fit-content', height: 'fit-content', marginTop: '30px', marginLeft: '20px' }}
            onClose={() => setSelectedCategory(null)}
          >
            {selectedCategory}
          </Tag>
        )}
      </div>

      <Table
        columns={columns}
        dataSource={Array.isArray(filteredData) ? filteredData : []}
        loading={loading}
        rowKey="id"
      />

      {isEdit && <AddProduct isModalOpen={isEdit} setIsModalOpen={setIsEdit} recordData={recordData} isEdit={true} />}

      {isDelete.status && (
        <CustomDeleteDialog
          status={isDelete.status}
          handleClose={() => setIsDelete({ id: null, status: false })}
          onAccept={deleteRecord}
        />
      )}
      <Modal
        title="Do you want to update Reorder Status?"
        open={isSwitchModalVisible}
        onOk={() => {
          productReorderChangeOk();
        }}
        onCancel={() => setIsSwitchModalVisible(false)}
      ></Modal>
    </div>
  );
};

export default ProductReferenceBody;
