import { Stack } from '@mui/material';
import React from 'react';
import SanitationCheckList from '../SanitationCheckList';
import ProcessingLoadingScreen from '../ProcessingLoadingScreen';
import CookedFoodChooseCategory from './CookedFoodChooseCategory';
import CookedFoodChooseBatch from './CookedFoodChooseBatch';
import CookedFoodIngredients from './CookedFoodIngredients';
import CookedFoodQuality from './CookedFoodQuality';
import CookedFoodSummary from './CookedFoodSummary';

function CookedFoodDepartmentActiveStepper({ activeStep }) {
  return (
    <Stack>
      {activeStep === 0 && <CookedFoodChooseCategory />}
      {activeStep === 1 && <CookedFoodChooseBatch />}
      {activeStep === 2 && <SanitationCheckList />}
      {activeStep === 3 && <CookedFoodIngredients />}
      {activeStep === 4 && <ProcessingLoadingScreen />}
      {activeStep === 5 && <CookedFoodQuality />}
      {activeStep === 6 && <CookedFoodSummary />}
    </Stack>
  );
}

export default CookedFoodDepartmentActiveStepper;
