import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';

// slices
import authSlice from './slices/authSlice';
import inventorySlice from './slices/inventorySlice';
import vendorSlice from './slices/vendorSlice';
import productSlice from './slices/productSlice';
import departmentSlice from './slices/departmentSlice';

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

const appReducer = combineReducers({
  auth: authSlice,
  inventory: inventorySlice,
  vendors: vendorSlice,
  products: productSlice,
  department: departmentSlice,
});

const rootReducer = (state, action) => {
  if (action.type === 'USER_LOGOUT') {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

export { rootPersistConfig, rootReducer };
