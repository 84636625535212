import { Button, Col, Modal, Row } from 'antd';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { createVendor, getAllVendors } from '../../../redux/slices/vendorSlice';
import { toast } from 'react-toastify';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import RHFTextField from '../../../components/hookform/RHFTextField';
import RHFSelect from '../../../components/hookform/RHFSelect';
import { MenuItem, TextField } from '@mui/material';
import ReactInputMask from 'react-input-mask';

const AddVendor = ({ open, setOpen, isEdit, record }) => {
  const dispatch = useDispatch();
  const [value, setValue] = React.useState('');
  const defaultValues = {};
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const vendorSchema = Yup.object().shape({
    vendorName: Yup.string().required('Name is required'),
    vendorEmail: Yup.string().email().required('email is required'),
    vendorPhone: Yup.string().matches(phoneRegExp, 'Phone number is not valid'),
    vendorPriority: Yup.string().required('Priority is required'),
  });
  const methods = useForm({ defaultValues, resolver: yupResolver(vendorSchema) });
  const { handleSubmit, reset } = methods;

  const VendorSubmit = (data) => {
    const isSuccessful = (isSuccess) => {
      if (isSuccess) {
        setOpen(false);
        dispatch(getAllVendors());
        isEdit ? toast.success('Vendor Updated successfully') : toast.success('Vendor created successfully');
      }
    };
    if (isEdit) {
      dispatch(createVendor({ data, isSuccess: isSuccessful }));
    } else {
      dispatch(createVendor({ data: { ...data, vendorActive: true }, isSuccess: isSuccessful }));
    }
  };

  useEffect(() => {
    if (!isEdit) return;
    reset(record);

    if (record) {
      reset({
        ...record,
        vendorPriority: record?.vendorPriority,
      });
    }
  }, [isEdit, record, reset]);
  return (
    <Modal
      title={isEdit ? 'Edit Vendor' : 'Add Vendor'}
      width={'30rem'}
      open={open}
      footer={[
        <Button key="back" onClick={() => setOpen(false)}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" onClick={handleSubmit(VendorSubmit)}>
          OK
        </Button>,
      ]}
      onCancel={() => setOpen(false)}
    >
      <FormProvider {...methods}>
        <Row gutter={[8, 12]}>
          <Col span={24}>
            <RHFTextField name="vendorName" label="Name*" />
          </Col>
          <Col span={24}>
            <RHFTextField name="vendorEmail" label="Email*" />
          </Col>
          {/* <Col span={24}>
            <RHFTextField name="address" label="Address*" />
          </Col> */}
          <Col span={24}>
            {/* <RHFTextField name="vendorPhone" label="Phone*" placeholder="1 (702) 123-4567" /> */}
            <Controller
              name="vendorPhone"
              control={methods.control}
              render={({ field, fieldState: { error } }) => (
                <ReactInputMask
                  maskChar=""
                  mask="999-999-9999"
                  alwaysShowMask={false}
                  label="Phone *"
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                  {...field}
                >
                  {(inputProps) => <TextField {...inputProps} fullWidth error={!!error} helperText={error?.message} />}
                </ReactInputMask>
              )}
            />
          </Col>
          <Col span={24}>
            <Controller
              name="yearsInBusiness"
              control={methods.control}
              defaultValue={0}
              rules={{ required: 'This field is required' }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  label="Years in Business *"
                  type="number"
                  error={!!error}
                  helperText={error?.message}
                  fullWidth
                />
              )}
            />
          </Col>
          <Col span={24}>
            <RHFSelect name="vendorPriority" label="Priority">
              <MenuItem value={2}>High</MenuItem>
              <MenuItem value={1}>Medium</MenuItem>
              <MenuItem value={0}>Low</MenuItem>
            </RHFSelect>
          </Col>
        </Row>
      </FormProvider>
    </Modal>
  );
};

export default AddVendor;
