import * as React from 'react';
import { experimentalStyled as styled } from '@mui/material/styles';
import MuiButton from '@mui/material/Button';
import { Icon, Typography } from '@mui/material';

import Container from '@mui/material/Container';
import Box from '@mui/material/Box';

const backgroundImage = '../../assets/photo.jpg';

export default function FactoryHero() {
  return (
    <ProductHeroLayout
      sxBackground={{
        backgroundImage: `url(${require('../../assets/photo.jpg')})`,
        backgroundColor: '#7fc7d9', // Average color of the background image.
        backgroundPosition: 'center',
      }}
    >
      <img style={{ display: 'none' }} src={backgroundImage} alt="increase priority" />
      <Typography color="inherit" align="center" variant="h2" marked="center">
        Factory Processing
      </Typography>
      <Typography color="inherit" align="center" variant="h5" sx={{ mb: 4, mt: { xs: 4 } }}>
        Choose Production Department
      </Typography>
      <Button
        color="secondary"
        variant="contained"
        size="large"
        component="a"
        onClick={() => {
          const windowHeight = window.innerHeight;
          const scrollDistance = windowHeight * 0.8; // 50% of the window height

          // Scroll down by the calculated distance
          window.scrollTo({
            top: scrollDistance,
            behavior: 'smooth', // Optional: for smooth scrolling
          });
        }}
        sx={{ minWidth: 200 }}
      >
        Choose Department
      </Button>
    </ProductHeroLayout>
  );
}

const ButtonRoot = styled(MuiButton)(({ theme, size }) => ({
  borderRadius: 5,
  fontWeight: theme.typography.fontWeightMedium,
  fontFamily: theme.typography.h1.fontFamily,
  padding: theme.spacing(2, 4),
  fontSize: theme.typography.pxToRem(14),
  boxShadow: 'none',
  '&:active, &:focus': {
    boxShadow: 'none',
  },
  ...(size === 'small' && {
    padding: theme.spacing(1, 3),
    fontSize: theme.typography.pxToRem(13),
  }),
  ...(size === 'large' && {
    padding: theme.spacing(2, 5),
    fontSize: theme.typography.pxToRem(16),
  }),
}));

function Button(props) {
  return <ButtonRoot {...props} />;
}

const ProductHeroRoot = styled('section')(({ theme }) => ({
  color: theme.palette.common.white,
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  [theme.breakpoints.up('sm')]: {
    height: '500px',
  },
}));

const Background = styled(Box)({
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  zIndex: -2,
});

function ProductHeroLayout(props) {
  const { sxBackground, children } = props;

  return (
    <ProductHeroRoot>
      <Container
        sx={{
          mt: 3,
          mb: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        {children}
        <Box
          sx={{
            position: 'absolute',
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            backgroundColor: 'common.black',
            opacity: 0.5,
            zIndex: -1,
          }}
        />
        <Background sx={sxBackground} />
      </Container>
    </ProductHeroRoot>
  );
}
