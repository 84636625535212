import { Table } from 'antd';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CustomLoadingScreen from '../../../components/CustomLoadingScreen';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import { getProducts } from '../../../redux/slices/productSlice';
import { PATH_DASHBOARD } from '../../../routes/paths';

const FinishedGoods = () => {
  const dispatch = useDispatch();

  const { allProducts, loading } = useSelector((state) => state?.products);

  useEffect(() => {
    dispatch(getProducts());
  }, [dispatch]);

  const finishedGoods = allProducts?.data?.filter((item) => item.productType === 'FINISHED_GOOD') || [];

  const columns = [
    {
      title: 'SKU',
      dataIndex: 'productSku',
      key: 'productSku',
    },
    { title: 'Product Name', dataIndex: 'productName', key: 'productName' },
    { title: 'Category', dataIndex: 'productCategory', key: 'productCategory' },
    {
      title: 'Batch',
      dataIndex: 'batch',
      key: 'batch',
    },
    { title: 'Net Weight', dataIndex: 'totalWeight', key: 'totalweight', render: (text) => text?.replace('-', ' ') },
    {
      title: 'Processed By',
      dataIndex: 'userId',
      key: 'userId',
    },
  ];

  if (loading) {
    return <CustomLoadingScreen />;
  }

  return (
    <div>
      <HeaderBreadcrumbs
        heading="Finished Goods"
        links={[
          { name: 'Dashboard', href: PATH_DASHBOARD.root },
          { name: 'Inventory', href: PATH_DASHBOARD.inventory.root },
          { name: 'Finished Goods', href: PATH_DASHBOARD.inventory.finishedGoods },
        ]}
      />
      <Table columns={columns} dataSource={finishedGoods || []} loading={loading} rowKey="id" />
    </div>
  );
};

export default FinishedGoods;
