import React from 'react';
import ProductHeader from './ProductHeader';
import Page from '../../../components/Page';
import ProductReferenceBody from './ProductReferenceBody';

const Index = () => {
  return (
    <Page title="Products">
      <ProductHeader />
      <ProductReferenceBody />
    </Page>
  );
};

export default Index;
