import { Navigate, useRoutes } from 'react-router-dom';
import LogoOnlyLayout from '../layouts/logoOnlyLayouts';
import Page404 from '../pages/Page404';
import Login from '../pages/auth/Login';
import { PATH_AUTH } from './paths';
// import Register from '../pages/auth/Register';
import { PATH_AFTER_LOGIN } from '../config';
import AuthGuard from '../guards/AuthGuard';
import FeatureGuard from '../guards/FeatureGuard';
import GuestGuard from '../guards/GuestGuard';
import DashboardLayout from '../layouts/dashboard';
import ChooseIndex from '../pages/ChooseIndex';
import SorryIndex from '../pages/SorryIndex';
import GeneralApp from '../pages/dashboard/GeneralApp';
import DepartmentIndex from '../pages/dashboard/departments';
import DepartmentItemIndex from '../pages/dashboard/departments/deptItems';
import InventoryIndex from '../pages/dashboard/inventory';
import ProductReference from '../pages/dashboard/productReference';
import UserCreate from '../pages/dashboard/user/UserCreate';
import VendorIndex from '../pages/dashboard/vendor';
import FactoryIndex from '../pages/factory';
import MomoIndex from '../pages/factory/momo/MomoIndex';
import WorkerPerformance from '../pages/dashboard/workerPerformance';
import HACCP from '../pages/dashboard/haccpPlan';
import FinishedGoods from '../pages/dashboard/inventory/FinishedGoods';
import SauceIndex from '../pages/factory/sauce/SauceIndex';
import DesertIndex from '../pages/factory/desert/DesertIndex';
import CookedFoodIndex from '../pages/factory/cooked-food/CookedFoodIndex';
import RawMeatIndex from '../pages/factory/raw-meat/RawMeatIndex';

export default function Router() {
  const user = JSON.parse(localStorage.getItem('factoryUserDetails'));

  const userGroup = user?.roles;

  const noUserGroup = user?.roles === null || user?.roles === undefined;
  return useRoutes([
    {
      path: 'auth',
      children: [
        { element: <Navigate to={PATH_AUTH.login} replace />, index: true },
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        // {
        //   path: 'register',
        //   element: (
        //     <GuestGuard>
        //       <Register />
        //     </GuestGuard>
        //   ),
        // },
      ],
    },

    // if logged in and is admin let him choose where he wanna go
    // {
    //   path: 'choose',
    //   element: (
    //     <AuthGuard>
    //       <FeatureGuard featureName={['ADMIN', 'FACTORYTECH']}>
    //         <ChooseIndex />
    //       </FeatureGuard>
    //     </AuthGuard>
    //   ),
    // },

    {
      path: 'sorry',
      element: (
        <AuthGuard>
          <SorryIndex />
        </AuthGuard>
      ),
    },
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <FeatureGuard featureName={['MANAGER', 'ADMIN', 'FACTORYTECH']}>
            <DashboardLayout />
          </FeatureGuard>
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },

        {
          path: 'app',
          element: (
            <FeatureGuard featureName={['MANAGER', 'ADMIN', 'FACTORYTECH']}>
              <GeneralApp />
            </FeatureGuard>
          ),
        },
        {
          path: 'department',
          children: [
            {
              element: <Navigate to="/dashboard/department/list" replace />,
              index: true,
            },
            {
              path: 'list',
              element: (
                // <FeatureGuard featureName={'MANAGER'}>
                <DepartmentIndex />
                // </FeatureGuard>
              ),
            },
            {
              path: 'deptItems',
              element: (
                // <FeatureGuard featureName={'MANAGER'}>
                <DepartmentItemIndex />
                // </FeatureGuard>
              ),
            },
          ],
        },
        {
          path: 'user',
          children: [
            {
              element: (
                <FeatureGuard featureName={['ADMIN', 'MANAGER']}>
                  <UserCreate />
                </FeatureGuard>
              ),
              index: true,
            },
          ],
        },
        {
          path: 'performance',
          children: [
            {
              element: (
                <FeatureGuard featureName={['ADMIN']}>
                  <WorkerPerformance />
                </FeatureGuard>
              ),
              index: true,
            },
          ],
        },

        {
          path: 'product',
          children: [{ element: <ProductReference />, index: true }],
        },
        {
          path: 'inventory',
          children: [{ element: <InventoryIndex />, index: true }],
        },
        {
          path: 'inventory/finishedGoods',
          children: [{ element: <FinishedGoods />, index: true }],
        },

        {
          path: 'vendors',
          children: [{ element: <VendorIndex />, index: true }],
        },
        {
          path: 'haccp',
          children: [
            {
              element: (
                <FeatureGuard featureName={['ADMIN']}>
                  <HACCP />
                </FeatureGuard>
              ),
              index: true,
            },
          ],
        },
      ],
    },

    {
      path: 'factory',
      element: (
        <AuthGuard>
          <FeatureGuard featureName={['FACTORYTECH']}>
            <FactoryIndex />
          </FeatureGuard>
        </AuthGuard>
      ),
    },
    {
      path: 'factory/momo',
      element: (
        <AuthGuard>
          <FeatureGuard featureName={['FACTORYTECH']}>
            <MomoIndex />
          </FeatureGuard>
        </AuthGuard>
      ),
    },
    {
      path: 'factory/sause',
      element: (
        <AuthGuard>
          <FeatureGuard featureName={['FACTORYTECH']}>
            <SauceIndex />
          </FeatureGuard>
        </AuthGuard>
      ),
    },
    {
      path: 'factory/dessert',
      element: (
        <AuthGuard>
          <FeatureGuard featureName={['FACTORYTECH']}>
            <DesertIndex />
          </FeatureGuard>
        </AuthGuard>
      ),
    },
    {
      path: 'factory/cookedFood',
      element: (
        <AuthGuard>
          <FeatureGuard featureName={['FACTORYTECH']}>
            <CookedFoodIndex />
          </FeatureGuard>
        </AuthGuard>
      ),
    },
    {
      path: 'factory/rawMeat',
      element: (
        <AuthGuard>
          <FeatureGuard featureName={['FACTORYTECH']}>
            <RawMeatIndex />
          </FeatureGuard>
        </AuthGuard>
      ),
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    {
      path: '/',
      // element: <Navigate to="/auth/login" replace />
      element: user ? <Navigate to="/dashboard" /> : <Navigate to="/auth/login" replace />,
    },

    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

function RedirectToRoleGroup({ userGroup, user }) {
  if (userGroup === null || userGroup === undefined) {
    return <Navigate to="/sorry" replace />;
  } else if (userGroup?.includes('ADMIN') && user?.selectedRole === 'inventory') {
    return <Navigate to="/dashboard" replace />;
  } else if (userGroup?.includes('ADMIN') && user?.selectedRole === 'factory') {
    return <Navigate to="/factory" replace />;
  } else if (userGroup?.includes('MANAGER') && user?.selectedRole === 'factory') {
    return <Navigate to="/factory" replace />;
  } else if (userGroup?.includes('MANAGER') && user?.selectedRole === 'inventory') {
    return <Navigate to="/dashboard" replace />;
  } else if (userGroup?.includes('FACTORYTECH') && user?.selectedRole === 'factory') {
    return <Navigate to="/factory" replace />;
  } else if (userGroup?.includes('FACTORYTECH') && user?.selectedRole === 'inventory') {
    return <Navigate to="/dashboard" replace />;
  } else {
    return null; // Navigate to default page or login
  }
}
