import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Col, Input, Modal, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { Controller, FormProvider, set, useFieldArray, useForm, useFormContext } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import RHFTextField from '../../../components/hookform/RHFTextField';

import { toast } from 'react-toastify';
import CustomMultipleAutoCompleteField from '../../../components/hookform/CustomAutoCompleteField';
import { createInventory, updateInventory } from '../../../redux/slices/inventorySlice';
import { getProductsReference } from '../../../redux/slices/productSlice';
import { getAllVendors } from '../../../redux/slices/vendorSlice';
import { CameraOutlined, DeleteOutlined, DollarOutlined, PlusOutlined } from '@ant-design/icons';
import QrCodeScanner from '../../../components/QrCodeScanner';
import CustomLoadingScreen from '../../../components/CustomLoadingScreen';
import { InputAdornment, MenuItem } from '@mui/material';
import RHFSelect from '../../../components/hookform/RHFSelect';
import moment from 'moment';

const AddInventoryItem = ({ isModalOpen, setIsModalOpen, recordData = null, isEdit = false }) => {
  const { postLoading } = useSelector((state) => state.inventory);
  const { vendors, loading: vendorLoading } = useSelector((state) => state.vendors);
  const { referenceProducts, loading: productRefLoading } = useSelector((state) => state.products);



const defaultValues = {};
const dispatch = useDispatch();
const inventorySchema = Yup.object().shape({
  // vendorList: Yup.object().shape({ vendorId: Yup.number().required('') }),
  productQty: Yup.string().required('Product Quantity is a required Field'),
  productPrice: Yup.string().required('Product Price is a required Feild'),
  productExpiry: Yup.string().required('Product Expiry is a required Field'),
  // productSkus: Yup.array()
  //   .of(
  //     Yup.object().shape({
  //       productSku: Yup.string().required('SKU is required'),
  //     })
  //   )
  //   .test('skuCountMatch', 'Number of SKUs must match Product Quantity', function (value) {
  //     const { productQty } = this.parent;
  //     return value.length === productQty;
  //   }),
});
const methods = useForm({ defaultValues, resolver: yupResolver(inventorySchema) });
const { handleSubmit, reset, watch, control, setValue } = methods;
const [barCodeScan, setBarcodeScan] = useState(false);
const [maxSkusErrorMesage, setMaxSkusErrorMessage] = useState('');
const [currentIndex, setCurrentIndex] = useState(null);
const handleScan = (index) => {
  setCurrentIndex(index);
  setBarcodeScan(true);
};

const currentDate = moment().format('YYY-MM-DD');

const findVendorById = (vendorId) => {
  return vendors.find((vendor) => vendor.vendorId === vendorId);
};

if (parseInt(watch('productQty')) === 0) {
  setMaxSkusErrorMessage('');
}
const { fields, append, remove } = useFieldArray({
  control,
  name: 'productSkus',
});
const InventoryCreateHandle = (data) => {
  const qty = parseInt(data?.productQty);

  const skus = data?.productSkus;
  if (qty !== skus.length) {
    return toast.error('Number of SKUs must match Product Quantity');
  }
  const finalData = {
    productDtoList: data.productSkus.map((skuData) => {
      return {
        productName: data?.productName?.productName,
        vendorId: data?.vendorId?.vendorId,
        productType: data?.productType,
        productPrice: parseFloat(data?.productPrice),
        productCategory: data?.productCategory,
        // reOrderQty: parseInt(data?.reOrderQty),
        productWeight:
          parseFloat(data?.productWeight) / parseInt(data?.productQty) +
          '-' +
          referenceProducts.data
            ?.filter((item) => item?.productName === data?.productName?.productName)
            .map((p) => p.productUnit)[0],
        productQty: parseInt(data?.productQty),
        productType: 'RAW_MATERIAL',
        productUnit: referenceProducts.data
          ?.filter((item) => item?.productName === data?.productName?.productName)
          .map((p) => p.productUnit)[0],
        productExpiry: moment().add(Number(data?.productExpiry), 'days').format('YYYY-MM-DD'),
        productSku: skuData?.productSku,
      };
    }),
  };

  const isSuccessful = (isSuccess) => {
    if (isSuccess) {
      setIsModalOpen(false);
      toast.success('Inventory list updated successfully');
    }
  };

  if (!isEdit) {
    dispatch(createInventory({ data: finalData, isSuccess: isSuccessful }));
  } else {
    const finalData = {
      productDtoList: data.productSkus.map((skuData) => {
        return {
          productName: data?.productName?.productName,
          vendorId: data?.vendorId?.vendorId,
          productType: data?.productType,
          productPrice: parseFloat(data?.productPrice),
          productCategory: data?.productCategory,
          // reOrderQty: parseInt(data?.reOrderQty),
          productWeight:
            (parseFloat(data?.productWeight.split('-')[0]) / parseInt(data?.productQty)).toFixed(2) +
            '-' +
            referenceProducts.data
              ?.filter((item) => item?.productName === data?.productName?.productName)
              .map((p) => p.productUnit)[0],
          productQty: parseInt(data?.productQty),
          productType: 'RAW_MATERIAL',
          productUnit: referenceProducts.data
            ?.filter((item) => item?.productName === data?.productName?.productName)
            .map((p) => p.productUnit)[0],
          productExpiry: moment().add(Number(data?.productExpiry), 'days').format('YYYY-MM-DD'),
          productSku: skuData?.productSku,
        };
      }),
    };
    dispatch(updateInventory({ data: finalData, id: recordData.productId, isSuccess: isSuccessful }));
  }
};

useEffect(() => {
  if (recordData) {
    console.log(recordData);
    reset({
      ...recordData,
      productType: recordData.productType,
      productSkus: recordData?.skuList?.map((sku) => ({
        productSku: sku,
      })),
      skuList: recordData?.skuList,
      productQty: recordData?.quantity,
      productWeight: recordData?.totalWeight.split('-')[0],
      productPrice: recordData?.totalPrice,
      productName: referenceProducts?.data?.find((el) => el.productName === recordData?.productName),
      vendorId: findVendorById(recordData?.vendorId),
      productExpiry:
        moment(recordData?.productExpiry).diff(moment().format('YYYY-MM-DD'), 'days') <= 7
          ? 7
          : moment(recordData?.productExpiry).diff(moment().format('YYYY-MM-DD'), 'days') <= 14
          ? 14
          : moment(recordData?.productExpiry).diff(moment().format('YYYY-MM-DD'), 'days') <= 30
          ? 30
          : moment(recordData?.productExpiry).diff(moment().format('YYYY-MM-DD'), 'days') <= 60
          ? 60
          : moment(recordData?.productExpiry).diff(moment().format('YYYY-MM-DD'), 'days') <= 90
          ? 90
          : 90,
    });
  }
}, [recordData, reset, referenceProducts]);

useEffect(() => {
  dispatch(getProductsReference());
  dispatch(getAllVendors());
}, [dispatch]);

//handle barcode Scan
const handleBarcodeScan = (result) => {
  if (currentIndex !== null) {
    setValue(`productSkus[${currentIndex}].productSku`, result?.text);
  }
  setBarcodeScan(false);
};

const expiry = watch('productExpiry');

const product_name = watch('productName');
const maxSkus = watch('productQty');

const handleAddSku = () => {
  if (fields.length < parseInt(maxSkus)) {
    append({ productSku: '' });
  } else {
    setMaxSkusErrorMessage('Number of SKUs must match Product Quantity');
  }
};

const handleRemoveSku = (index) => {
  remove(index);
};

return (
  <>
    <Modal
      title={isEdit ? 'Edit Product' : 'Add Product'}
      open={isModalOpen}
      onOk={handleSubmit(InventoryCreateHandle)}
      onCancel={() => setIsModalOpen(false)}
    >
      {vendorLoading && productRefLoading ? (
        <CustomLoadingScreen />
      ) : (
        <>
          <FormProvider {...methods}>
            <Row gutter={[8, 12]}>
              <Col span={12}>
                <CustomMultipleAutoCompleteField
                  name="productName"
                  label="Select Product*"
                  options={referenceProducts?.data?.filter(
                    (value, index, self) => self.findIndex((obj) => obj.productName === value.productName) === index
                  )}
                  getOptionLabel={(option) => option?.productName}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  sx={{ width: '14.5rem' }}
                />
              </Col>
              <Col span={12}>
                <CustomMultipleAutoCompleteField
                  name="productCategory"
                  label="Category"
                  options={referenceProducts?.data
                    ?.filter((i) => i.productName === product_name?.productName)
                    .map((item) => item?.productCategory)}
                />
              </Col>

              <Col span={12}>
                <CustomMultipleAutoCompleteField
                  name="vendorId"
                  label="Vendor"
                  options={vendors}
                  getOptionLabel={(option) => option?.vendorName}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  sx={{ width: '14.5rem' }}
                />
              </Col>
              <Col span={12}>
                <RHFTextField name="productQty" type="number" label="Quantity of PK/BG/CS" />
              </Col>
              <Col span={12}>
                <RHFTextField
                  name="productWeight"
                  type="number"
                  label="Net Weight"
                  prefix
                  // onChange={(e) => {
                  //   // Remove non-alphanumeric characters
                  //   const formattedValue = e.target.value.replace(/[^0-9a-zA-Z]/g, '');

                  //   // Insert a hyphen between numbers and letters
                  //   const formattedWithHyphen = formattedValue.replace(/(\d+)([a-zA-Z]+)/, '$1-$2');

                  //   // Update the input value
                  //   e.target.value = formattedWithHyphen;
                  // }}
                />
              </Col>
              {/* <Col span={12}>
                  <RHFTextField name="productUnit" type="text" label="Unit" />
                </Col> */}
              <Col span={12}>
                <RHFTextField
                  name="productPrice"
                  type="text"
                  label="Unit Price"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <DollarOutlined />
                      </InputAdornment>
                    ),
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  prefix={true}
                  // value={formatCurrency(watch('productPrice')) !== NaN ? formatCurrency(watch('productPrice')) : ' '}
                />
              </Col>
              <Col span={12}>
                <RHFTextField name="productDesc" label="Description" />
              </Col>
              <Col span={12}>
                <RHFSelect name="productExpiry" label="Expiry">
                  <MenuItem value={7}>1 Week</MenuItem>
                  <MenuItem value={14}>2 Weeks</MenuItem>
                  <MenuItem value={30}>1 Month</MenuItem>
                  <MenuItem value={60}>2 Months</MenuItem>
                  <MenuItem value={90}>3 Months</MenuItem>
                </RHFSelect>
              </Col>

              {/* <Col span={12}>
              <RHFTextField name="department" label="Department" />
            </Col> */}
              {fields.map((field, index) => (
                <>
                  <Col span={8} key={field.id}>
                    <Controller
                      name={`productSkus[${index}].productSku`}
                      control={control}
                      render={({ field }) => (
                        <div style={{ position: 'relative' }}>
                          <RHFTextField
                            {...field}
                            name={`productSkus[${index}].productSku`}
                            label="Product SKU"
                            defaultValue={isEdit ? recordData.skuList[index] : ''}
                          />
                          <CameraOutlined
                            style={{
                              position: 'absolute',
                              right: '10px',
                              top: '50%',
                              transform: 'translateY(-50%)',
                              cursor: 'pointer',
                            }}
                            onClick={() => {
                              handleScan(index);
                            }}
                          />
                        </div>
                      )}
                    />
                  </Col>
                  <Col span={4} style={{ display: 'flex', alignItems: 'center' }}>
                    <Button type="dashed" onClick={() => handleRemoveSku(index)}>
                      <DeleteOutlined style={{ color: 'red' }} />
                    </Button>
                  </Col>
                </>
              ))}
              {fields.length < maxSkus && (
                <Col span={12}>
                  <Button type="dashed" onClick={handleAddSku}>
                    <PlusOutlined /> Add SKU
                  </Button>
                </Col>
              )}
            </Row>
          </FormProvider>
          {maxSkusErrorMesage && <p style={{ color: 'red' }}>{maxSkusErrorMesage}</p>}
          {barCodeScan && (
            <QrCodeScanner onClose={() => setBarcodeScan(false)} onScan={(result) => handleBarcodeScan(result)} />
          )}{' '}
        </>
      )}
    </Modal>
  </>
);
};

export default AddInventoryItem;
