import React, { useEffect } from 'react';
import FactoryHero from './FactoryHero';
import FactoryDepartmentSection from './FactoryDepartmentSection';
import { useDispatch } from 'react-redux';
import { getDepartmentList } from '../../redux/slices/departmentSlice';

const FactoryIndexBody = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getDepartmentList());
  }, [dispatch]);
  return (
    <>
      <FactoryHero />
      <FactoryDepartmentSection />
    </>
  );
};

export default FactoryIndexBody;
