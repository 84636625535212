import { Button } from '@mui/material';
import React, { useRef, useEffect } from 'react';

const CaptureImage = ({ onCapture, onClose }) => {
  const videoRef = useRef(null);
  const canvasRef = useRef(null);

  // Function to stop the camera stream
  const stopStream = () => {
    if (videoRef.current && videoRef.current.srcObject) {
      videoRef.current.srcObject.getTracks().forEach((track) => track.stop());
      videoRef.current.srcObject = null;
    }
  };

  useEffect(() => {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices
        .getUserMedia({ video: true })
        .then((stream) => {
          if (videoRef.current) {
            videoRef.current.srcObject = stream;
          }
        })
        .catch(console.error);
    }

    // Cleanup function to stop the camera when the component is unmounted
    return () => {
      stopStream();
    };
  }, []);

  const captureImage = () => {
    const canvas = canvasRef.current;
    const video = videoRef.current;
    if (canvas && video) {
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      canvas.getContext('2d').drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
      const imageDataUrl = canvas.toDataURL('image/png');
      onCapture(imageDataUrl);
      stopStream(); // Stop the camera after capturing the image
      onClose();
    }
  };

  const handleCloseCamera = () => {
    stopStream();
    onClose();
  };

  return (
    <div>
      <video ref={videoRef} autoPlay style={{ width: '300px', height: '300px' }} />
      <Button onClick={captureImage}>Capture</Button>
      <Button onClick={handleCloseCamera}>Close Camera</Button>
      <canvas ref={canvasRef} style={{ display: 'none' }} />
    </div>
  );
};

export default CaptureImage;
