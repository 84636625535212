import { yupResolver } from '@hookform/resolvers/yup';
import { MenuItem } from '@mui/material';
import { Button, Col, Modal, Row } from 'antd';
import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import CustomLoadingScreen from '../../../components/CustomLoadingScreen';
import CustomSelectField from '../../../components/CustomSelectField';
import RHFSwitch from '../../../components/hookform/RHFSwitch';
import RHFTextField from '../../../components/hookform/RHFTextField';
import { createProductReference, updateProductReference } from '../../../redux/slices/productSlice';
import { getAllVendors } from '../../../redux/slices/vendorSlice';

const AddProduct = ({ isModalOpen, setIsModalOpen, recordData = null, isEdit = false }) => {
  const defaultValues = {
    productUnit: null,
  };
  const dispatch = useDispatch();
  const productRefSchema = Yup.object().shape({
    productName: Yup.string().required('Name is required'),
    productCategory: Yup.string().required('Product Category is required'),
    reorderQty: Yup.number().required('Reorder Quantity is required').typeError(''),
    productUnit: Yup.string().required('Product Unit is required'),
  });
  const methods = useForm({ defaultValues, resolver: yupResolver(productRefSchema) });
  const { handleSubmit, reset, watch } = methods;

  const handleProductSubmit = (data) => {
    const isSuccessful = (isSuccess) => {
      if (isSuccess) {
        setIsModalOpen(false);
        toast.success('Product list updated successfully');
      }
    };
    if (!isEdit) {
      const finalData = { ...data, vendorId: data?.vendorId?.id, reorderSwitch: data?.reorderSwitch ? true : false };

      dispatch(createProductReference({ data: finalData, isSuccess: isSuccessful }));
    } else {
      dispatch(updateProductReference({ data: data, isSuccess: isSuccessful }));
    }
  };

  const { vendors, loading } = useSelector((state) => state.vendors);
  const { postLoading } = useSelector((state) => state.products);

  useEffect(() => {
    dispatch(getAllVendors());
  }, [dispatch]);

  useEffect(() => {
    if (recordData) {
      reset({
        ...recordData,
        vendorId: vendors.find((vendor) => vendor?.id === recordData?.vendorId),
        productUnit: recordData.productUnit,
      });
    }
  }, [recordData, reset, vendors]);

  const units = ['tbsp', 'oz', 'lb', 'g', 'kg', 'mL', 'fl oz', 'qt', 'liters', 'cup', 'gallon'];
  return (
    <Modal
      title={isEdit ? 'Edit Product' : 'Add Product'}
      open={isModalOpen}
      footer={[
        <Button key="back" onClick={() => setIsModalOpen(false)}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" onClick={handleSubmit(handleProductSubmit)} disabled={postLoading}>
          {postLoading ? <CustomLoadingScreen circularWidth="20px" /> : 'OK'}
        </Button>,
      ]}
      onCancel={() => setIsModalOpen(false)}
    >
      <FormProvider {...methods}>
        <Row gutter={[8, 12]}>
          <Col span={24}>
            <RHFTextField name="productName" label="Name*" />
          </Col>
          <Col span={12}>
            <RHFTextField name="productCategory" label="Product Category*" />
          </Col>
          <Col span={12}>
            <RHFTextField name="reorderQty" label="Reorder Quantity*" type="number" />
          </Col>
          <Col span={12}>
            <CustomSelectField name="productUnit" label="Product Unit" labelShrink={true}>
              {units?.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </CustomSelectField>
          </Col>

          <Col span={12}>
            <RHFSwitch name="reorderSwitch" label="Re-Order" />
          </Col>
        </Row>
      </FormProvider>
    </Modal>
  );
};

export default AddProduct;
