import SorryIndex from '../pages/SorryIndex';

export default function FeatureGuard({ featureName, children }) {
  const user = JSON.parse(localStorage.getItem('factoryUserDetails'));
  const userGroup = user?.roles;
  // const hasUserGroup = userGroup && userGroup.length > 0;
  const hasMatchingFeature = featureName?.some((feature) => userGroup?.includes(feature));

  if (!userGroup) {
    return <SorryIndex />;
  }

  if (userGroup.includes('ADMIN') || hasMatchingFeature) {
    return <>{children}</>;
  }

  return <p>Page not Found</p>;
}
