import { Outlet } from 'react-router-dom';
import Logo from '../components/logo';




export default function LogoOnlyLayout() {
   return (
      <>

         <Logo />
         <Outlet />
      </>
   );
}
