import React, { useEffect } from 'react';
import RHFSwitch from '../../../components/hookform/RHFSwitch';
import { Grid, TextField } from '@mui/material';
import RHFTextField from '../../../components/hookform/RHFTextField';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';

function DessertSummary() {
  const { watch, setValue } = useFormContext();
  const { departmentAllData } = useSelector((state) => state.department);

  const data = watch();

  useEffect(() => {
    const selectedDeptData = departmentAllData?.find(
      (item) => item?.deptCategory === data?.selectedCategory && item?.batch === data?.selectedBatch
    );
    setValue('productWeight', selectedDeptData?.estimatedProduction ?? 0);
  }, [departmentAllData]);

  const isEstimationMet = watch('estimatedProductionMet');
  useEffect(() => {
    setValue('productUnit', 'lb');
  }, [setValue]);
  return (
    <Grid container spacing={2}>
      <Grid item xs={6} sm={4} lg={3}>
        <TextField fullWidth disabled id="outlined-disabled" label="Department" defaultValue="Momo" />
      </Grid>
      <Grid item xs={6} sm={4} lg={3}>
        <RHFTextField name={'selectedCategory'} disabled label={'Category'} />
      </Grid>
      <Grid item xs={6} sm={4} lg={3}>
        <RHFTextField name={'selectedBatch'} disabled label={'Batch'} />
      </Grid>
      <Grid item xs={6} sm={4} lg={3}>
        <RHFTextField name={'selectedCategory'} disabled label={'Product Name'} />
      </Grid>
      <Grid item xs={6} sm={4} lg={3}>
        <RHFTextField name={'newProductSKU'} disabled label={'Product SKU'} />
      </Grid>
      <Grid item xs={6} sm={4} lg={3}>
        <TextField fullWidth disabled id="outlined-disabled" label="Product-Type" defaultValue="FINISHED_GOOD" />
      </Grid>
      <Grid item xs={6} sm={4} lg={3}>
        <RHFTextField name={'selectedCategory'} disabled label={'Product Category'} />
      </Grid>
      <Grid item xs={6} sm={4} lg={3}>
        <TextField fullWidth disabled id="outlined-disabled" label="Product-Expiry" defaultValue="1 Week" />
      </Grid>
      <Grid item xs={6} sm={4} lg={3}>
        <TextField fullWidth disabled id="outlined-disabled" label="Product-Quantity" defaultValue="1" />
      </Grid>
      {/* <Grid item xs={6} sm={4} lg={3}>
        <TextField fullWidth disabled id="outlined-disabled" label="Product-Unit" defaultValue="lb" />
      </Grid> */}
      <Grid item xs={6} sm={4} lg={3}>
        <TextField fullWidth disabled id="outlined-disabled" label="Product-Price" defaultValue="0" />
      </Grid>
      <Grid item xs={6} sm={4} lg={3}>
        <RHFTextField name={'productQuality'} disabled label={'Product Quality'} />
      </Grid>
      <Grid item xs={6}>
        <RHFSwitch name={'estimatedProductionMet'} label={'Estimated Production Met'} />
        {!isEstimationMet && <RHFTextField name={'productUnit'} label={'Product Unit '} />}
      </Grid>

      <Grid item xs={12}>
        <RHFTextField name={'productDesc'} label={'Product Description '} />
      </Grid>

      <Grid item xs={12}>
        <p>Quality Image: </p>
        {data.imageUrl && <img src={data.imageUrl} alt="Quality Image" style={{ height: '200px', width: '200px' }} />}
      </Grid>
    </Grid>
  );
}

export default DessertSummary;
