const awsmobile = {
   // ...other configuration options
   Auth: {
      identityPoolId: 'your-identity-pool-id',
      region: 'your-region',
      userPoolId: 'us-east-1_n0T8qRghf',
      userPoolWebClientId: '7f52rp48puunkj2v1097gr5fq0',
   },
   // ...other configuration options
};

export default awsmobile;
