import { Paper, Stack, Typography } from '@mui/material';
import React from 'react';

const WorkerCard = ({ username, finishedGoods, totalPoints }) => {
  return (
    <Paper
      square={false}
      elevation={3}
      sx={{
        // background: 'linear-gradient(90deg, rgb(23,41,57) 0%, rgba(0,0,0,11%) 100%)',
        margin: '10px',
        padding: '18px',
        boxSizing: 'border-box',
        width: '100%',
        display: 'flex',
        flex: 1,

        background: '#172939',
        background: 'linear-gradient(90deg, #172939, #8D87B2)',
      }}
    >
      <Stack direction="row">
        <Stack direction={'column'} style={{ marginRight: '2rem' }}>
          <Typography style={{ color: '#fff', fontSize: '20px', fontWeight: 600 }} variant="h4">
            {username}
          </Typography>

          <Typography style={{ color: 'white', fontSize: '20px', fontWeight: 600, marginTop: '1rem' }} variant="h5">
            Finished Goods : {finishedGoods}
          </Typography>
        </Stack>
        <div
          style={{
            background: '#fff',
            height: '100px',
            width: '100px',
            borderRadius: '50%',
            marginLeft: 'auto',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <span
            style={{
              color: '#242c99',
              fontSize: '30px',
              fontWeight: 'bold',
            }}
          >
            {totalPoints}
          </span>
        </div>
      </Stack>
    </Paper>
  );
};

export default WorkerCard;
