import React, { useState } from 'react';
import { Modal, List, Button } from 'antd';
import { CheckCircleOutlined, RightOutlined } from '@ant-design/icons';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import Checkbox from '@mui/material/Checkbox';
import { FormControlLabel } from '@mui/material';

const SanitationCheckList = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentPoint, setCurrentPoint] = useState(null);
  const { control, setValue, getValues } = useFormContext();

  const showModal = (item) => {
    setCurrentPoint(item);
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const watchData = useWatch({
    control,
    name: 'checkedList',
  });

  const handleCheckboxChange = (item, checked) => {
    const updatedData = watchData?.map((dataItem) => {
      if (dataItem.point === item.point) {
        return { ...dataItem, isChecked: checked };
      }
      return dataItem;
    });

    setValue('checkedList', updatedData);
    showModal(item);
  };

  const handleSelectAllChange = (bool) => {
    if (bool) {
      const updatedData = watchData?.map((dataItem) => {
        return { ...dataItem, isChecked: true };
      });

      setValue('checkedList', updatedData);
    } else {
      const updatedData = watchData?.map((dataItem) => {
        return { ...dataItem, isChecked: false };
      });

      setValue('checkedList', updatedData);
    }
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <form onSubmit={(data) => console.log(data)}>
        <List size="default" bordered>
          {watchData?.map((item, index) => (
            <List.Item key={index}>
              <Controller
                name={`checkedList[${index}].isChecked`}
                control={control}
                defaultValue={item.isChecked}
                render={({ field }) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={item?.isChecked}
                        onChange={(e) => handleCheckboxChange(item, e.target.checked)}
                      />
                    }
                    label={item.point}
                  />
                )}
              />
              {item.isChecked && <CheckCircleOutlined style={{ color: 'green', marginLeft: '10px' }} />}
            </List.Item>
          ))}
          <List.Item>
            <FormControlLabel
              control={
                <Checkbox
                  checked={getValues('checkedList').every((item) => item?.isChecked)}
                  onChange={(event) => handleSelectAllChange(event.target.checked)}
                />
              }
              label="Select All"
            />
          </List.Item>
        </List>

        <Modal
          title={`Review ${currentPoint?.point}`}
          open={isModalVisible}
          onOk={handleOk}
          onCancel={handleOk}
          footer={[
            <Button key="submit" type="primary" onClick={handleOk}>
              OK
            </Button>,
          ]}
        >
          <p style={{ padding: '10px', fontSize: '16px' }}>{currentPoint?.content}</p>

          <Button
            type="primary"
            href="https://www.ecfr.gov/current/title-9/chapter-III/subchapter-E/part-416"
            target="_blank"
            style={{
              borderRadius: '16px',
              textDecoration: 'none',
            }}
          >
            Read More
            <RightOutlined />
          </Button>
        </Modal>
      </form>
    </div>
  );
};

export default SanitationCheckList;
