import { TextField } from '@mui/material';
import moment from 'moment';
import { useFormContext, Controller } from 'react-hook-form';

export default function RHFTextField({ name, placeholder, size = 'large', type = 'text', prefix = false, ...other }) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <TextField
          {...field}
          fullWidth
          value={type === 'date' && field.value ? moment(field.value).format('YYYY-MM-DD') : field.value}
          error={!!error}
          helperText={error?.message}
          type={type}
          {...other}
        />
      )}
    />
  );
}



