import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { toast } from 'react-toastify';
import { baseUrl, API } from '../../utils/url';
import apiURL from '../../api';
import instance from '../../api';
// create a vendor

export const createVendor = createAsyncThunk('createVendor', async (data, thunkAPI) => {
  try {
    const response = await instance.post(`${baseUrl}${API.vendor.addVendors}`, data.data);
    if (response.status === 200) {
      thunkAPI.dispatch(getAllVendors());
      data.isSuccess(true);
    }
    return response.data; // Assuming valid JSON response
  } catch (error) {
    console.log(error, 'error');
    toast.error(error.response.data.message ?? 'Something went wrong');
    throw error;
  }
});

export const getAllVendors = createAsyncThunk('getAllVendors', async () => {
  try {
    const response = await instance.get(`${baseUrl}${API.vendor.allVendors}`);

    if (response.status === 200) {
      return response.data?.data;
    }
    throw new Error(`HTTP status ${response.status}`);
  } catch (error) {
    toast.error(error?.response?.data?.message);
    throw error;
  }
});

// Delete a vendor

export const deleteVendor = createAsyncThunk('deleteVendor', async (props, thunkAPI) => {
  try {
    const response = await instance.get(`vendor/delete/${props.id}`);
    if (response.status === 200) {
      toast.success('Vendor deleted successfully');
      thunkAPI.dispatch(getAllVendors());
      props.isSuccess(true);
    }
    throw new Error(`HTTP status ${response.status}`);
  } catch (error) {
    throw error;
  }
});

// update vendor

export const updateVendor = createAsyncThunk('updateVendor', async (data, thunkAPI) => {
  try {
    const response = await instance.post(`${baseUrl}${data?.id}`, data);
    if (response.status === 200) {
      thunkAPI.dispatch(getAllVendors());
      return response.data;
    }
    throw new Error(`HTTP status ${response.status}`);
  } catch (error) {
    throw error;
  }
});

export const vendorSlice = createSlice({
  name: 'vendors',
  initialState: {
    vendors: [],
    loading: false,
    postLoading: false,
    error: null,
  },
  extraReducers: {
    [createVendor.pending]: (state) => {
      state.loading = true;
    },
    [createVendor.fulfilled]: (state, action) => {
      state.loading = false;
      state.vendors.push(action.payload);
    },
    [createVendor.rejected]: (state, action) => {
      state.loading = false;
      state.vendors = action.payload;
    },
    [getAllVendors.pending]: (state) => {
      state.loading = true;
    },
    [getAllVendors.fulfilled]: (state, action) => {
      state.loading = false;
      state.vendors = action.payload;
    },
    [getAllVendors.rejected]: (state, action) => {
      state.loading = false;
      state.vendors = action.payload;
    },

    [updateVendor.pending]: (state) => {
      state.loading = true;
    },
    [updateVendor.fulfilled]: (state, action) => {
      state.loading = false;
      // state.vendors = state.vendors.map((ele) => (ele?.id === action?.payload?.id ? action.payload : ele));
    },
    [updateVendor.rejected]: (state, action) => {
      state.loading = false;
      state.vendors = action.payload;
    },
    [deleteVendor.pending]: (state) => {
      state.postLoading = true;
    },
    [deleteVendor.fulfilled]: (state, action) => {
      state.postLoading = false;
      // const { id } = action.payload;

      // state.vendors = state.vendors.filter((i) => i?.id !== id);
    },
    [deleteVendor.rejected]: (state, action) => {
      state.postLoading = false;
      state.vendors = action.payload;
    },
  },
});

export default vendorSlice.reducer;
