import { PATH_DASHBOARD } from '../../../routes/paths';
import SvgIconStyle from '../../../components/SvgIconStyle';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import EngineeringIcon from '@mui/icons-material/Engineering';
import { HddOutlined } from '@ant-design/icons';

const getIcon = (name) => <SvgIconStyle src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  users: getIcon('ic_users'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  booking: getIcon('ic_booking'),
  invoice: getIcon('ic_invoice'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  menuItem: getIcon('ic_menu_item'),
  food: getIcon('ic_foods'),
  stocks: getIcon('ic_stocks'),
  file: getIcon('ic_file'),
};

const user = JSON.parse(localStorage?.getItem('factoryUserDetails')) || [];
const userGroup = user?.roles;

const navConfig = [
  { title: 'Overview', path: PATH_DASHBOARD.general.app, icon: ICONS.dashboard },
  // {
  //   title: 'Department',
  //   path: PATH_DASHBOARD.department.root,
  //   icon: ICONS.menuItem,
  //   children: [
  //     { title: 'Department list', path: PATH_DASHBOARD.department.list },
  //     { title: 'Department Item', path: PATH_DASHBOARD.department.deptItems },
  //   ],
  // },
  {
    title: 'Department',
    path: PATH_DASHBOARD.department.root,
    icon: ICONS.menuItem,
  },

  ...(userGroup?.includes('ADMIN')
    ? [
        {
          title: 'User',
          path: PATH_DASHBOARD.user.root,
          icon: ICONS.users,
          children: [
            { title: 'Create User', path: PATH_DASHBOARD.user.root },
            { title: 'Worker Performance', path: PATH_DASHBOARD.workerPerformance.root },
          ],
        },
        {
          title: 'HACCP Plan',
          path: PATH_DASHBOARD.haccpPlan.root,
          icon: <FileCopyIcon />,
        },
        // {
        //   title: 'Worker Performance',
        //   path: PATH_DASHBOARD.workerPerformance.root,
        //   icon: <Work />,
        // },
      ]
    : []),
  {
    title: 'product',
    path: PATH_DASHBOARD.product.root,
    icon: ICONS.food,
  },

  {
    title: 'Inventory',
    icon: ICONS.stocks,
    children: [
      {
        title: 'Manage Inventory',
        path: PATH_DASHBOARD.inventory.root,
      },
      { title: 'Finished Goods', path: PATH_DASHBOARD.finishedGoods.root },
    ],
  },
  {
    title: 'vendor',
    path: PATH_DASHBOARD.vendor.root,
    icon: ICONS.banking,
  },
  {
    title: 'Factory Processing',
    path: PATH_DASHBOARD.factoryProcessing.root,
    icon: <EngineeringIcon />,
  },
];

// const navConfig = [
//   {
//     subheader: 'general',
//     items: [{ title: 'Overview', path: PATH_DASHBOARD.general.app, icon: ICONS.dashboard }],
//   },
//   {
//     subheader: 'management',
//     items: [
//       {
//         title: 'department',
//         path: PATH_DASHBOARD.department.root,
//         icon: ICONS.menuItem,
//         children: [
//           { title: 'Department list', path: PATH_DASHBOARD.department.list },
//           { title: 'Department Item', path: PATH_DASHBOARD.department.deptItems },
//         ],
//       },
//       // {
//       //   title: 'user',
//       //   path: PATH_DASHBOARD.user.root,
//       //   icon: ICONS.user,
//       //   children: [
//       //     { title: ' User list', path: PATH_DASHBOARD.user.list },
//       //     { title: 'Account', path: PATH_DASHBOARD.user.account },
//       //   ],
//       // },
//       {
//         title: 'user',
//         path: PATH_DASHBOARD.user.root,
//         icon: ICONS.users,
//       },
//       {
//         title: 'product',
//         path: PATH_DASHBOARD.product.root,
//         icon: ICONS.food,
//       },
//       {
//         title: 'Inventory',
//         path: PATH_DASHBOARD.inventory.root,
//         icon: ICONS.stocks,
//       },
//       {
//         title: 'vendor',
//         path: PATH_DASHBOARD.vendor.root,
//         icon: ICONS.banking,
//       },
//     ],
//   },
// ];

export default navConfig;
