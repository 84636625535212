import React from 'react';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import { Button } from '@mui/material';
import AddDepartment from './AddDepartment';
import { PATH_DASHBOARD } from '../../../routes/paths';
import Iconify from '../../../components/Iconify';

const DepartmentHeader = () => {
  const [addDept, setAddDept] = React.useState(false);
  const user = JSON.parse(localStorage.getItem('factoryUserDetails'));
  const userGroup = user?.roles;
  return (
    <>
      <HeaderBreadcrumbs
        heading="Departments"
        links={[
          { name: 'Dashboard', href: PATH_DASHBOARD.root },
          { name: 'Department list', href: PATH_DASHBOARD.department.root },
        ]}
        action={
          (userGroup.includes('ADMIN') || userGroup.includes('MANAGER')) && (
            <Button
              variant="contained"
              startIcon={<Iconify icon={'eva:plus-fill'} width={20} height={20} />}
              onClick={() => setAddDept(true)}
            >
              Department
            </Button>
          )
        }
      />
      {addDept && <AddDepartment open={addDept} setOpen={setAddDept} />}
    </>
  );
};

export default DepartmentHeader;
