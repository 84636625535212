import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import axios from 'axios';
import api from '../../api';
import { API, baseUrl } from '../../utils/url';
import instance from '../../api';

export const getProducts = createAsyncThunk('products/getProducts', async (thunkAPI) => {
  try {
    const response = await instance.get(API.inventoryProducts.allProducts);
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    throw error;
  }
});
export const getProductsReference = createAsyncThunk('products/getProductsReference', async (thunkAPI) => {
  try {
    const response = await instance.get(`${baseUrl}${API.productRef.allProductsRef}`);
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    throw error;
  }
});
export const getProductReport = createAsyncThunk('product/report', async (thunkAPI) => {
  try {
    const response = await instance.get(`${baseUrl}${API.report.productReport}`);
    return response.data?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    throw error;
  }
});

//worker Perfomance Index
export const getWorkerPerfomance = createAsyncThunk('product/workerPerfomance', async (thunkAPI) => {
  try {
    const response = await instance.get(`${baseUrl}${API.workerPerfomance.performanceReport}`);
    return response.data?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    throw error;
  }
});
export const getChartData = createAsyncThunk('product/data', async (thunkAPI) => {
  try {
    const response = await instance.get(`${baseUrl}${API.report.finishedData}`);
    console.log(response, 'chartData');
    return response.data?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    throw error;
  }
});

export const createProduct = createAsyncThunk('products/createProduct', async (props, thunkAPI) => {
  try {
    const response = await instance.post(api.products, props.data);
    console.log('🚀 => createProduct => response=>', response);
    if (response.status === 201) {
      props.isSuccess(true);
      thunkAPI.dispatch(getProducts());
    }
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    throw error;
  }
});

export const getInventoryList = createAsyncThunk('inventory/getInventoryList', async (thunkAPI) => {
  try {
    const response = await instance.get('inventory');
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    throw error;
  }
});

export const createProductReference = createAsyncThunk('products/createProductReference', async (props, thunkAPI) => {
  try {
    const response = await instance.post('productref/add', props.data);
    if (response.status === 201) {
      props.isSuccess(true);
      thunkAPI.dispatch(getProductsReference());
    }
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    throw error;
  }
});

export const deleteProduct = createAsyncThunk('products/deleteProduct', async (props, thunkAPI) => {
  try {
    const response = await instance.delete(`${api.products}${props.id}`);
    if (response.status === 200) {
      props.isSuccess(true);
      thunkAPI.dispatch(getProducts());
    }
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    throw error;
  }
});
export const deleteProductReference = createAsyncThunk('products/deleteProductReference', async (props, thunkAPI) => {
  try {
    const response = await instance.delete(`${baseUrl}${API.productRef.deleteProductRef}${props.id}`);
    if (response.status === 200) {
      props.isSuccess(true);
      thunkAPI.dispatch(getProductsReference());
    }
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    throw error;
  }
});

export const updateProduct = createAsyncThunk('products/updateProduct', async (props, thunkAPI) => {
  try {
    const response = await instance.put(`${api.products}${props.id}`, props.data);
    if (response.status === 200) {
      props.isSuccess(true);
      thunkAPI.dispatch(getProducts());
    }
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    throw error;
  }
});
export const updateProductReference = createAsyncThunk('products/updateProductReference', async (props, thunkAPI) => {
  try {
    const response = await instance.put(`${baseUrl}${API.productRef.updateProductRef}`, props.data);
    if (response.status === 200) {
      thunkAPI.dispatch(getProductsReference());
      props.isSuccess(true);
    }
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    throw error;
  }
});

const productSlice = createSlice({
  name: 'products',
  initialState: {
    allProducts: [],
    referenceProducts: [],
    productReport: [],
    chartData: [],
    workerPerfomance: [],
    loading: false,
    postLoading: false,
  },
  reducers: {},
  extraReducers: {
    [getProducts.pending]: (state) => {
      state.loading = true;
    },
    [getProducts.fulfilled]: (state, action) => {
      state.allProducts = action.payload;
      state.loading = false;
    },
    [getProducts.rejected]: (state) => {
      state.loading = false;
    },

    [createProduct.pending]: (state) => {
      state.postLoading = true;
    },
    [createProduct.fulfilled]: (state, action) => {
      state.allProducts = action.payload;
      state.postLoading = false;
    },
    [createProduct.rejected]: (state) => {
      state.postLoading = false;
    },
    [updateProduct.pending]: (state) => {
      state.postLoading = true;
    },
    [updateProduct.fulfilled]: (state, action) => {
      state.postLoading = false;
    },
    [updateProduct.rejected]: (state) => {
      state.postLoading = false;
    },

    // reference

    [getProductsReference.pending]: (state) => {
      state.loading = true;
    },

    [getProductsReference.fulfilled]: (state, action) => {
      state.referenceProducts = action.payload;
      state.loading = false;
    },
    [getProductsReference.rejected]: (state) => {
      state.loading = false;
    },

    [createProductReference.pending]: (state) => {
      state.postLoading = true;
    },
    [createProductReference.fulfilled]: (state, action) => {
      state.referenceProducts = action.payload;
      state.postLoading = false;
    },
    [createProductReference.rejected]: (state) => {
      state.postLoading = false;
    },
    [updateProductReference.pending]: (state) => {
      state.postLoading = true;
    },
    [updateProductReference.fulfilled]: (state, action) => {
      state.postLoading = false;
    },
    [updateProductReference.rejected]: (state) => {
      state.postLoading = false;
    },
    //report
    [getProductReport.pending]: (state) => {
      state.loading = true;
    },

    [getProductReport.fulfilled]: (state, action) => {
      state.productReport = action.payload;
      state.loading = false;
    },
    [getProductReport.rejected]: (state) => {
      state.loading = false;
    },
    //chartData
    [getChartData.pending]: (state) => {
      state.loading = true;
    },

    [getChartData.fulfilled]: (state, action) => {
      state.chartData = action.payload;
      state.loading = false;
    },
    [getChartData.rejected]: (state) => {
      state.loading = false;
    },

    //workerPerfomance
    [getWorkerPerfomance.pending]: (state) => {
      state.loading = true;
    },

    [getWorkerPerfomance.fulfilled]: (state, action) => {
      state.workerPerfomance = action.payload;
      state.loading = false;
    },
    [getWorkerPerfomance.rejected]: (state) => {
      state.loading = false;
    },
  },
});

export default productSlice.reducer;
