import React from 'react'
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs'
import { PATH_DASHBOARD } from '../../../routes/paths'

const CreateUserHeader = () => {
   return (
      <HeaderBreadcrumbs heading="Create User"
         links={[{ name: 'Dashboard', href: PATH_DASHBOARD.root }, { name: 'User', href: PATH_DASHBOARD.user.root }]}
      //   action={
      //      <Button
      //         variant="contained"
      //         startIcon={<Iconify icon={'eva:plus-fill'} width={20} height={20} />}
      //         onClick={setAddInventoryItem}
      //      >
      //         Inventory
      //      </Button>
      //   }
      />
   )
}

export default CreateUserHeader