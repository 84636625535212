import { Button, Stack, Typography } from '@mui/material';
import { Col, Row } from 'antd';
import React from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import CustomMultipleAutoCompleteField from '../../../components/hookform/CustomAutoCompleteField';
import { useSelector } from 'react-redux';
import IndividualIngredient from './IndividualIngredient';

const DepartmentIngredients = () => {
  const { control } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'ingredients',
  });
  //   console.log('🚀 => DepartmentIngredients => fields=>', fields);

  return (
    <>
      {fields.map((item, index) => {
        return <IndividualIngredient index={index} key={index} remove={remove} />;
      })}
      <Stack direction="row" spacing={2} marginTop={'1rem'} justifyContent="flex-end">
        <Button variant="contained" size="small" onClick={() => append({ productType: '', unit: '' })}>
          + Add Ingredient
        </Button>
      </Stack>
    </>
  );
};

export default DepartmentIngredients;
