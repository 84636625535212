import {
  Alert,
  Button,
  Container,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import Iconify from '../../../components/Iconify';
import UserPool from '../../../UserPool';
import { toast } from 'react-toastify';

const CreateUserBody = () => {
  const [showPassword, setShowPassword] = React.useState(false);
  const [givenName, setGivenName] = React.useState('');
  const [familyName, setFamilyName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [username, setUsername] = React.useState('');
  const [loading, setLoading] = React.useState(false);

  const methods = useForm({});

  const {
    reset,
    setError,
    handleSubmit,
    formState: { errors },
  } = methods;

  const onUserCreate = (e) => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    if (username === '' || password === '' || email === '' || givenName === '' || familyName === '') {
      return toast.error('Please fill all the fields');
    }
    if (!email.match(emailRegex)) {
      return toast.error('Invalid email address.');
    }
    if (password.length < 8 || !password.match(passwordRegex)) {
      return toast.error(
        'Password must contain at least 8 characters, one uppercase, one lowercase, one number and one special character.'
      );
    }

    setLoading(true);

    const attributeList = [
      {
        Name: 'email',
        Value: email,
      },
      {
        Name: 'given_name',
        Value: givenName,
      },
      {
        Name: 'family_name',
        Value: familyName,
      },
    ];
    UserPool.signUp(username, password, attributeList, null, (err, data) => {
      if (err) {
        toast.error(err.message);
        console.log(err);
        setLoading(false);
        setError('afterSubmit', { message: err.message });
      } else {
        toast.success('User created successfully');
        resetForm();
      }
    });
  };

  const resetForm = () => {
    reset();
    setLoading(false);
    setGivenName('');
    setFamilyName('');
    setEmail('');
    setPassword('');
    setUsername('');
  };
  return (
    <Container>
      <Stack spacing={2} sx={{ mb: 5, position: 'relative' }}>
        <Typography variant="h6">Create a new user for your company</Typography>
      </Stack>
      <Stack spacing={1} sx={{ mb: 5, position: 'relative' }}>
        <FormProvider {...methods}>
          <Grid container spacing={1}>
            {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>}

            <Grid item xs={12} sm={6} md={4}>
              <TextField
                value={givenName}
                fullWidth
                type="text"
                onChange={(e) => setGivenName(e.target.value)}
                label="Given name"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                value={familyName}
                fullWidth
                type="text"
                onChange={(e) => setFamilyName(e.target.value)}
                label="Family name"
              />
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                value={username}
                fullWidth
                type="text"
                onChange={(e) => setUsername(e.target.value)}
                label="Username"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                value={password}
                fullWidth
                type={showPassword ? 'text' : 'password'}
                onChange={(e) => setPassword(e.target.value)}
                label="Password"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                        <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                value={email}
                fullWidth
                type="text"
                onChange={(e) => setEmail(e.target.value)}
                label="Email Address"
              />
            </Grid>
          </Grid>
          <Stack direction="row" style={{ marginTop: '30px' }} justifyContent="flex-start">
            <Button
              variant="contained"
              color="primary"
              size="medium"
              onClick={handleSubmit(onUserCreate)}
              disabled={loading}
            >
              {/* <CustomLoadingScreen circularWidth={'20px'} color="secondary" /> */}
              Create account
            </Button>
          </Stack>
        </FormProvider>
      </Stack>
    </Container>
  );
};

export default CreateUserBody;
