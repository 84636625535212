import { Button, Card, CardContent, Checkbox, FormControlLabel, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Modal } from 'antd';
import instance from '../../../api';
import { toast } from 'react-toastify';
import { Warning } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

function RawMeatChooseBatch() {
  const { departmentAllData } = useSelector((state) => state.department);

  const { setValue, watch } = useFormContext();
  const [selectedBatch, setSelectedBatch] = useState('');
  const [fileurl, setFileurl] = useState('');
  const [haccpModal, setHaccpModal] = useState(false);
  const [batchList, setBatchList] = useState(new Set());

  const selectedCategory = watch('selectedCategory');
  const selectedDeptName = 'Raw Meat';

  const navigate = useNavigate();

  const handleBatchChange = (batch) => {
    setSelectedBatch(batch);
    setValue('selectedBatch', batch); // Set the form value
  };

  useEffect(() => {
    instance
      .get(`file?fileName=HACCP-RAW-MEAT-${selectedCategory}`)
      .then((res) => {
        setFileurl(res.data?.data?.url);
      })
      .catch((error) => {
        console.error(error.message);
        setFileurl('');
      });
  }, [selectedCategory]);

  useEffect(() => {
    const batchList = departmentAllData
      .filter(
        (item) =>
          item.deptName.toLowerCase() === selectedDeptName.toLowerCase() &&
          item.deptCategory.toLowerCase() === selectedCategory.toLowerCase()
      )
      .map((item) => item.batch);

    setBatchList(batchList);
  }, [departmentAllData, selectedDeptName, selectedCategory]);
  return (
    <>
      <div>
        <Button
          size="sm"
          variant="contained"
          style={{ marginBottom: '20px' }}
          onClick={() => {
            setHaccpModal(true);
          }}
        >
          Review HACCP Plan
        </Button>
        <Typography variant="h5" gutterBottom>
          Choose Momo Batch
        </Typography>
        {batchList.length > 0 ? (
          batchList.map((batch) => (
            <Card key={batch} sx={{ marginBottom: 2 }}>
              <CardContent>
                <FormControlLabel
                  control={<Checkbox checked={selectedBatch === batch} onChange={() => handleBatchChange(batch)} />}
                  label={batch}
                />
              </CardContent>
            </Card>
          ))
        ) : (
          <Typography variant="body2">No batches available for the selected category and department.</Typography>
        )}
      </div>

      <Modal
        title="HACCP Plan"
        open={haccpModal}
        onCancel={() => {
          setHaccpModal(false);
        }}
        footer={[
          <Button key="back" onClick={() => setHaccpModal(false)}>
            Close
          </Button>,
        ]}
      >
        {fileurl === '' ? (
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <Warning sx={{ color: 'red', marginRight: '10px' }} />
              <Typography variant="h6" gutterBottom>
                {' '}
                HACCP plan is not uploaded
              </Typography>
            </div>
            <h6
              style={{
                color: '#000',
                cursor: 'pointer',
              }}
              onClick={() => {
                navigate('/dashboard/haccp');
              }}
            >
              ADMIN only: Upload HACCP Plan
            </h6>
          </div>
        ) : (
          // eslint-disable-next-line jsx-a11y/iframe-has-title
          <iframe src={fileurl} width="100%" height="500px" />
        )}
      </Modal>
    </>
  );
}

export default RawMeatChooseBatch;
