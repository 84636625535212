import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { alpha } from '@mui/material/styles';
import { Box, Divider, Typography, Stack, MenuItem, Avatar, IconButton } from '@mui/material';
// routes
import { PATH_DASHBOARD, PATH_AUTH } from '../../../routes/paths';
// auth
// import { useAuthContext } from '../../../auth/useAuthContext';
// components
// import { CustomAvatar } from '../../../components/custom-avatar';
// import { useSnackbar } from '../../../components/snackbar';
import MenuPopover from '../../../components/menu-popover';
import { useDispatch } from 'react-redux';
import { setLogout } from '../../../redux/slices/authSlice';
import CustomAvatar from '../../../components/CustomAvatar';
import LogoutIcon from '@mui/icons-material/Logout';
import IconButtonAnimate from '../../../components/IconButtonAnimate';
import { toast } from 'react-toastify';

const OPTIONS = [
  // {
  //   label: 'Home',
  //   linkTo: '/',
  // },
];

export default function AccountPopover() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const user = JSON.parse(localStorage.getItem('factoryUserDetails'));
  const userGroup = user?.roles;

  // const { user, logout } = useAuthContext();

  // const { enqueueSnackbar } = useSnackbar();

  const [openPopover, setOpenPopover] = useState(null);

  const handleOpenPopover = (event) => {
    setOpenPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  const handleLogout = async () => {
    try {
      // logout();
      dispatch(setLogout());
      navigate(PATH_AUTH.login, { replace: true });
      handleClosePopover();
    } catch (error) {
      console.error(error);
      toast.error('Unable to logout!');
      // enqueueSnackbar('Unable to logout!', { variant: 'error' });
    }
  };

  const handleClickItem = (path) => {
    handleClosePopover();
    navigate(path);
  };

  return (
    <>
      <Stack direction={'row'}>
        <IconButtonAnimate
          onClick={handleOpenPopover}
          sx={{
            p: 0,
            ...(openPopover && {
              '&:before': {
                zIndex: 1,
                content: "''",
                width: '100%',
                height: '100%',
                borderRadius: '100%',
                position: 'absolute',
                bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
              },
            }),
          }}
        >
          {/* <CustomAvatar src={'https://cdn.pixabay.com/photo/2020/07/01/12/58/icon-5359553_1280.png'} alt={user?.displayName} name={user?.displayName} /> */}
          <Avatar
            src={''}
            alt={''}
            style={{
              color: 'black',
              backgroundColor: 'white',
              width: '45px',
              height: '45px',
              borderRadius: '50%',
            }}
          />
        </IconButtonAnimate>
        <div style={{ marginRight: '10px' }} />
        <IconButton
          onClick={handleLogout}
          // Add your styles here...
        >
          <LogoutIcon />
        </IconButton>
        <div style={{ marginRight: '50px' }} />
        {/* <Stack style={{ marginTop: '5px', marginLeft: '5px', marginRight: '50px' }}>
          <Typography variant="subtitle2" noWrap style={{ color: 'black' }}>
            {user?.fullname}
          </Typography>
          <Typography variant="subtitle2" noWrap style={{ color: 'black' }}>
            {userGroup ? user?.roles[0] : 'No Group'}
          </Typography>
        </Stack> */}
      </Stack>
      <MenuPopover open={openPopover} onClose={handleClosePopover} sx={{ width: 200, p: 0 }}>
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {user?.fullname}
          </Typography>
          {/* <Divider /> */}
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {userGroup ? user?.roles[0] : 'No Group'}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {user?.email}
          </Typography>
          {/* <Divider /> */}

          {/* <Divider /> */}
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        {/* <Stack sx={{ p: 1 }}>
          {OPTIONS.map((option) => (
            <MenuItem key={option.label} onClick={() => handleClickItem(option.linkTo)}>
              {option.label}
            </MenuItem>
          ))}
        </Stack> */}

        <Divider sx={{ borderStyle: 'dashed' }} />

        {/* <MenuItem onClick={handleLogout} sx={{ m: 1 }}>
          Logout
        </MenuItem> */}
      </MenuPopover>
    </>
  );
}
