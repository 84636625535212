import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import axios from 'axios';
import apiURL from '../../api';

const api = 'https://654c5ff077200d6ba858c91b.mockapi.io/api/v1/';

export const getDepartmentList = createAsyncThunk('department/getDepartmentList', async () => {
  try {
    const response = await apiURL.get('department/dropdown');

    return response?.data?.data;
  } catch (err) {
    toast.error(err?.response?.data?.message);
    throw err;
  }
});
export const getDepartmentProductionList = createAsyncThunk('department/seven-day-production', async () => {
  try {
    const response = await apiURL.get('department/seven-day-production');

    return response?.data?.data;
  } catch (err) {
    toast.error(err?.response?.data?.message);
    throw err;
  }
});

export const createDepartment = createAsyncThunk('department/createDepartment', async (props, thunkAPI) => {
  try {
    const response = await apiURL.post('department/add', props?.data);
    console.log('🚀 => createDepartment => response=>', response);

    if (response.status === 201) {
      props?.isSuccess(true);
      thunkAPI.dispatch(getDepartmentAllData());
    }
  } catch (err) {
    toast.error(err?.response?.data?.message);
    throw err;
  }
});

export const getDepartmentAllData = createAsyncThunk('department/getDepartmentAllData', async (props, thunkAPI) => {
  try {
    const response = await apiURL.get('department');
    return response?.data?.data;
  } catch (err) {
    toast.error(err?.response?.data?.message);
    throw err;
  }
});

export const updateDepartment = createAsyncThunk('inventory/updateDepartment', async (props, thunkAPI) => {
  try {
    const response = await apiURL.put('department/update', props?.data);
    if (response.status === 200) {
      thunkAPI.dispatch(getDepartmentList());
      props?.isSuccess(true);
    }
    return response.data;
  } catch (err) {
    toast.error(err?.response?.data?.message);
    throw err;
  }
});

export const deleteDepartment = createAsyncThunk('inventory/deleteDepartment', async (data, thunkAPI) => {
  try {
    const response = await apiURL.delete(`department/delete/${data?.id}`);
    if (response.status === 200) {
      thunkAPI.dispatch(getDepartmentAllData());
      data?.isSuccess(true);
    }
    return response.data;
  } catch (err) {
    toast.error(err?.response?.data?.message);
    throw err;
  }
});

export const getDeptItemList = createAsyncThunk('inventory/getDeptItemList', async (data, thunkAPI) => {
  try {
    const response = await axios.get(`${api}deptItem`);
    return response.data;
  } catch (err) {
    toast.error(err?.response?.data?.message);
    throw err;
  }
});

export const createDeptItem = createAsyncThunk('inventory/createDeptItem', async (props, thunkAPI) => {
  try {
    const response = await axios.post(`${api}deptItem`, props?.data);
    if (response.status === 201) {
      //FIXME: check if this is the correct status code
      thunkAPI.dispatch(getDeptItemList());
      props?.isSuccess(true);
    }
    return response.data;
  } catch (err) {
    toast.error(err?.response?.data?.message);
    throw err;
  }
});

export const updateDeptItem = createAsyncThunk('inventory/updateDeptItem', async (data, thunkAPI) => {
  try {
    const response = await axios.put(`${api}deptItem/${data?.id}`, data?.data);
    if (response.status === 200) {
      thunkAPI.dispatch(getDeptItemList());
      data?.isSuccess(true);
    }
    return response.data;
  } catch (err) {
    toast.error(err?.response?.data?.message);
    throw err;
  }
});

export const deleteDeptItem = createAsyncThunk('inventory/deleteDeptItem', async (data, thunkAPI) => {
  try {
    const response = await axios.delete(`${api}deptItem/${data?.id}`);
    if (response.status === 200) {
      thunkAPI.dispatch(getDeptItemList());
      data?.isSuccess(true);
    }
    return response.data;
  } catch (err) {
    toast.error(err?.response?.data?.message);
    throw err;
  }
});

export const getDeptSkulist = createAsyncThunk('inventory/getDeptSkulist', async (data) => {
  try {
    const response = await apiURL.post('product/product-name-and-sku', data);

    return response?.data?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    throw error;
  }
});

const departmentSlice = createSlice({
  name: 'department',
  initialState: {
    departmentList: [],
    departmentAllData: [],
    deptItemList: [],
    deptProductionList: [],
    loading: false,
    postLoading: false,
    deptSkuList: [],
    skuLoading: false,
  },
  reducers: {},
  extraReducers: {
    [getDeptSkulist.pending]: (state) => {
      state.skuLoading = true;
    },
    [getDeptSkulist.fulfilled]: (state, action) => {
      state.skuLoading = false;
      state.deptSkuList = action.payload;
    },
    [getDeptSkulist.rejected]: (state) => {
      state.skuLoading = false;
      state.deptSkuList = [];
    },
    [getDepartmentAllData.pending]: (state) => {
      state.loading = true;
    },
    [getDepartmentAllData.fulfilled]: (state, action) => {
      state.loading = false;
      state.departmentAllData = action.payload;
    },
    [getDepartmentAllData.rejected]: (state) => {
      state.loading = false;
    },
    [getDepartmentList.pending]: (state) => {
      state.loading = true;
    },
    [getDepartmentList.fulfilled]: (state, action) => {
      state.departmentList = action.payload;
      state.loading = false;
    },
    [getDepartmentList.rejected]: (state) => {
      state.loading = false;
    },

    [createDepartment.pending]: (state) => {
      state.postLoading = true;
    },
    [createDepartment.fulfilled]: (state, action) => {
      state.postLoading = false;
    },
    [createDepartment.rejected]: (state) => {
      state.postLoading = false;
    },

    [updateDepartment.pending]: (state) => {
      state.postLoading = true;
    },
    [updateDepartment.fulfilled]: (state, action) => {
      state.postLoading = false;
    },
    [updateDepartment.rejected]: (state) => {
      state.postLoading = false;
    },

    [deleteDepartment.pending]: (state) => {
      state.postLoading = true;
    },
    [deleteDepartment.fulfilled]: (state, action) => {
      state.postLoading = false;
    },
    [deleteDepartment.rejected]: (state) => {
      state.postLoading = false;
    },

    [getDeptItemList.pending]: (state) => {
      state.loading = true;
    },
    [getDeptItemList.fulfilled]: (state, action) => {
      state.deptItemList = action.payload;
      state.loading = false;
    },
    [getDeptItemList.rejected]: (state) => {
      state.loading = false;
    },

    [createDeptItem.pending]: (state) => {
      state.postLoading = true;
    },
    [createDeptItem.fulfilled]: (state, action) => {
      state.postLoading = false;
    },
    [createDeptItem.rejected]: (state) => {
      state.postLoading = false;
    },

    [updateDeptItem.pending]: (state) => {
      state.postLoading = true;
    },
    [updateDeptItem.fulfilled]: (state, action) => {
      state.postLoading = false;
    },
    [updateDeptItem.rejected]: (state) => {
      state.postLoading = false;
    },

    [deleteDeptItem.pending]: (state) => {
      state.postLoading = true;
    },
    [deleteDeptItem.fulfilled]: (state, action) => {
      state.postLoading = false;
    },
    [deleteDeptItem.rejected]: (state) => {
      state.postLoading = false;
    },
    //department Report
    [getDepartmentProductionList.pending]: (state) => {
      state.loading = true;
    },
    [getDepartmentProductionList.fulfilled]: (state, action) => {
      state.loading = false;
      state.deptProductionList = action.payload;
    },
    [getDepartmentProductionList.rejected]: (state) => {
      state.loading = false;
    },
  },
});

export default departmentSlice.reducer;
