import PropTypes from 'prop-types';

import logoImg from '../assets/logo.png';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
   disabledLink: PropTypes.bool,
   sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {



   const logo = (
      // <Box sx={{ width: 10, height: 10, ...sx }}>

      <img src={logoImg} alt="factorylogo" style={{ borderRadius: '80%' }} />
      // </Box>
   );

   if (disabledLink) {
      return <>{logo}</>;
   }

   return <>{logo}</>;
}
