import React, { useState } from 'react';
import QrCodeScanner from '../../components/QrCodeScanner';
import TimeComponent from './TimeComponent';
import { useFormContext } from 'react-hook-form';
import { Button, Card, CardContent, Typography } from '@mui/material';

function ProcessingLoadingScreen() {
  const [barcodeScan, setBarcodeScan] = useState(false);
  const [barcode, setBarCode] = useState(null);

  const { setValue } = useFormContext();

  const handleBarcodeScan = (data) => {
    setValue(`newProductSKU`, data?.text);
    setBarCode(data?.text);
    setBarcodeScan(false);
  };

  return (
    <>
      <TimeComponent />

      <Card style={styles.scanCard}>
        <CardContent>
          <Typography>{barcode}</Typography>
          <Button variant="contained" color="primary" onClick={() => setBarcodeScan(true)}>
            Scan SKU
          </Button>
          {barcodeScan && (
            <QrCodeScanner onClose={() => setBarcodeScan(false)} onScan={(result) => handleBarcodeScan(result)} />
          )}
        </CardContent>
      </Card>
    </>
  );
}

const styles = {
  scanCard: {
    width: '300px', // Set the width as needed
    margin: 'auto',
  },
};

export default ProcessingLoadingScreen;
