import { Col, Row } from 'antd';
import React from 'react';
import CustomMultipleAutoCompleteField from '../../../components/hookform/CustomAutoCompleteField';
import { useSelector } from 'react-redux';
import { Controller, useFormContext } from 'react-hook-form';
import RHFTextField from '../../../components/hookform/RHFTextField';
import { Autocomplete, Box, Button, Divider, Grid, Stack, TextField, Typography } from '@mui/material';
import { Icon } from '@iconify/react';
import RHFSwitch from '../../../components/hookform/RHFSwitch';

const IndividualIngredient = ({ index, remove }) => {
  const { referenceProducts, loading: productRefLoading } = useSelector((state) => state.products);
  const { control, watch, setValue } = useFormContext();
  const product_name = watch(`ingredients[${index}].productName`);

  return (
    <>
      <Stack direction={'row'} flexWrap={'wrap'} spacing={1} marginTop={'10px'}>
        <CustomMultipleAutoCompleteField
          name={`ingredients[${index}].productName`}
          label="Select Product*"
          options={referenceProducts?.data?.filter(
            (value, index, self) => self.findIndex((obj) => obj.productName === value.productName) === index
          )}
          onChange={(event, data, reason, details) => {
            setValue(`ingredients[${index}].productName`, data);
            setValue(`ingredients[${index}].unit`, data?.productUnit);
            setValue(`ingredients[${index}].quantity`, '');
            setValue(`ingredients[${index}].productCategory`, null);
            setValue(`ingredients[${index}].isCount`, false);
          }}
          getOptionLabel={(option) => option?.productName}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          sx={{ width: '10rem' }}
        />
        <CustomMultipleAutoCompleteField
          name={`ingredients[${index}].productCategory`}
          label="Category"
          options={referenceProducts?.data
            ?.filter((i) => i.productName === product_name?.productName)
            .map((item) => item?.productCategory)}
          sx={{ width: '7rem' }}
        />
        <RHFTextField name={`ingredients[${index}].quantity`} type="number" label="Quantity" sx={{ width: '6rem' }} />
        <RHFTextField disabled name={`ingredients[${index}].unit`} label="Unit" sx={{ width: '4rem' }} />
        <RHFSwitch name={`ingredients[${index}].isCount`} label="Use Count" />
        <Stack direction="row" spacing={2} marginTop={'1rem'} justifyContent="flex-end">
          <Button variant="text" color="error" onClick={() => remove(index)} startIcon={<Icon icon={'ph:trash'} />} />
        </Stack>
      </Stack>
      {/* <Grid container spacing={2} style={{ marginBottom: '10px' }}>
        <Grid item xs={12} sm={6} lg={3}>
          <CustomMultipleAutoCompleteField
            name={`ingredients[${index}].productName`}
            label="Select Product*"
            options={referenceProducts?.data?.filter(
              (value, index, self) => self.findIndex((obj) => obj.productName === value.productName) === index
            )}
            onChange={(event, data, reason, details) => {
              setValue(`ingredients[${index}].productName`, data);
              setValue(`ingredients[${index}].unit`, data?.productUnit);
              setValue(`ingredients[${index}].quantity`, '');
              setValue(`ingredients[${index}].productCategory`, null);
            }}
            //  onChange={(event, data, reason, details) => handleProductChange(data)}
            getOptionLabel={(option) => option?.productName}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            //  sx={{ width: '14.5rem' }}
          />
          <CustomMultipleAutoCompleteField
            name={`ingredients[${index}].productCategory`}
            label="Category"
            options={referenceProducts?.data
              ?.filter((i) => i.productName === product_name?.productName)
              .map((item) => item?.productCategory)}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={2}>
          <CustomMultipleAutoCompleteField
            name={`ingredients[${index}].productCategory`}
            label="Category"
            options={referenceProducts?.data
              ?.filter((i) => i.productName === product_name?.productName)
              .map((item) => item?.productCategory)}
          />
        </Grid>
        <Grid item xs={12} sm={4} lg={2}>
          <RHFTextField name={`ingredients[${index}].quantity`} type="number" label="Quantity" />
        </Grid>
        <Grid item xs={12} sm={4} lg={1.5}>
          <RHFTextField disabled name={`ingredients[${index}].unit`} label="Unit" />
        </Grid>
        <Grid item xs={12} sm={4} lg={1}>
          <RHFSwitch name={`ingredients[${index}].isCount`} label="isCount" />
        </Grid>
        <Grid item xs={12} sm={4} lg={1}>
          <Stack direction="row" spacing={2} marginTop={'1rem'} justifyContent="flex-end">
            <Button variant="text" color="error" onClick={() => remove(index)} startIcon={<Icon icon={'ph:trash'} />} />
          </Stack>
        </Grid>
        <Divider />
      </Grid> */}
    </>
    //  <Row gutter={[8, 12]} key={index}>
    //    <Col span={12}>
    //   <CustomMultipleAutoCompleteField
    //     name={`ingredients[${index}].productName`}
    //     label="Select Product*"
    //     options={referenceProducts?.data?.filter(
    //       (value, index, self) => self.findIndex((obj) => obj.productName === value.productName) === index
    //     )}
    //     onChange={(event, data, reason, details) => {
    //       setValue(`ingredients[${index}].productName`, data);
    //       setValue(`ingredients[${index}].unit`, data?.productUnit);
    //       setValue(`ingredients[${index}].quantity`, '');
    //       setValue(`ingredients[${index}].productType`, null);
    //     }}
    //     //  onChange={(event, data, reason, details) => handleProductChange(data)}
    //     getOptionLabel={(option) => option?.productName}
    //     isOptionEqualToValue={(option, value) => option.id === value.id}
    //     sx={{ width: '14.5rem' }}
    //   />
    //    </Col>
    //    <Col span={12}>
    //      <CustomMultipleAutoCompleteField
    //        name={`ingredients[${index}].productType`}
    //        label="Type"
    //        options={referenceProducts?.data
    //          ?.filter((i) => i.productName === product_name?.productName)
    //          .map((item) => item?.productType)}
    //      />
    //    </Col>
    //    <Col span={12}>
    //      <RHFTextField name={`ingredients[${index}].quantity`} type="number" label="Quantity" />
    //    </Col>
    //    <Col span={12}>
    //      <RHFTextField disabled name={`ingredients[${index}].unit`} label="Unit" />
    //    </Col>
    //  </Row>
  );
};

export default IndividualIngredient;
