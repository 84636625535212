import React from 'react';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import { PATH_DASHBOARD } from '../../../routes/paths';
import { Button } from '@mui/material';
import Iconify from '../../../components/Iconify';
import AddInventoryItem from './AddInventory';



const InventoryHeader = () => {
  const user = JSON.parse(localStorage.getItem('factoryUserDetails'));
  const userGroup = user?.roles;
  const [addInventoryItem, setAddInventoryItem] = React.useState(false);
  return (
    <>
      <HeaderBreadcrumbs
        heading="Inventory"
        links={[
          { name: 'Dashboard', href: PATH_DASHBOARD.root },
          { name: 'Inventory', href: PATH_DASHBOARD.inventory.root },
        ]}
        action={
          userGroup.includes('ADMIN') && (
            <Button
              variant="contained"
              startIcon={<Iconify icon={'eva:plus-fill'} width={20} height={20} />}
              onClick={setAddInventoryItem}
            >
              Add Inventory
            </Button>
          )
        }
      />
      {addInventoryItem && <AddInventoryItem isModalOpen={addInventoryItem} setIsModalOpen={setAddInventoryItem} />}
    </>
  );
};

export default InventoryHeader;
