import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { getDepartmentAllData, getDepartmentList } from '../../../redux/slices/departmentSlice';
import Page from '../../../components/Page';
import RawMeatDepartmentStepper from './RawMeatDepartmentStepper';

const RawMeatDepartmentSchema = Yup.object().shape({
  selectedCategory: Yup.string().required('Category is required'),
  selectedBatch: Yup.string().required('Batch is required'),
});
function RawMeatIndex() {
  const dispatch = useDispatch();

  const defaultValues = {
    selectedCategory: '',
    selectedBatch: '',
    estimatedProductionMet: true,
    productDesc: '',
  };

  const methods = useForm({ defaultValues, resolver: yupResolver(RawMeatDepartmentSchema), mode: 'onChange' });

  const FlowCompleteHandler = (data) => {};

  useEffect(() => {
    dispatch(getDepartmentList());
    dispatch(getDepartmentAllData());
  }, [dispatch]);
  return (
    <Page title="Raw Meat Dept">
      <FormProvider {...methods}>
        <RawMeatDepartmentStepper onSubmit={FlowCompleteHandler} />
      </FormProvider>
    </Page>
  );
}

export default RawMeatIndex;
