import { Button, Space, Table } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import React from 'react';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import CustomDeleteDialog from '../../../../components/CustomDeleteDialog';
import { deleteDeptItem } from '../../../../redux/slices/departmentSlice';
import AddDepartmentItem from './AddDepartmentItem';

const DeptItemList = ({ items }) => {
   const dispatch = useDispatch();
   const [isEdit, setIsEdit] = React.useState(false);
   const [recordData, setRecordData] = React.useState(null);
   const [isDelete, setIsDelete] = React.useState({ id: null, status: false });

   const { loading } = useSelector((state) => state.department);

   const deleteDepartmentItem = () => {
      const isSuccessful = (isSuccess) => {
         if (isSuccess) {
            setIsDelete({ id: null, status: false });
            toast.success('Department Item deleted successfully');
         }
      };
      dispatch(deleteDeptItem({ id: isDelete.id, isSuccess: isSuccessful }))
   };

   const columns = [
      { title: 'S.N', dataIndex: 'deptItem_id', key: 'deptItem_id' },
      { title: 'Item Name', dataIndex: 'item_name', key: 'item_name' },
      {
         title: 'Ingredients',
         dataIndex: 'ingredients',
         key: 'ingredients',
         render: (text) => {
            return text?.map((item) => item?.dept_name).join(', ');
         },
      },
      {
         title: 'Quality',
         dataIndex: 'quality_ref',
         key: 'quality_ref',
         render: (text) => {
            return text?.map((item) => item?.dept_name).join(', ');
         },
      },
      {
         title: 'Action',
         key: 'action',
         render: (record) => (
            <Space size="middle">
               <Button
                  onClick={() => {
                     setRecordData(record);
                     setIsEdit(true);
                  }}
               >
                  <EditOutlined />
               </Button>
               <Button
                  onClick={() => {
                     setIsDelete({ id: record.deptItem_id, status: true });
                  }}
               >
                  <DeleteOutlined style={{ color: 'red' }} />
               </Button>
            </Space>
         ),
      },
   ];
   return (
      <>
         <Table columns={columns} dataSource={Array.isArray(items) ? items : []} loading={loading} rowKey="deptItem_id" />

         {isEdit && <AddDepartmentItem open={isEdit} setOpen={setIsEdit} recordData={recordData} isEdit={true} />}

         {isDelete.status && <CustomDeleteDialog status={isDelete.status} handleClose={() => setIsDelete({ id: null, status: false })} onAccept={deleteDepartmentItem} />}
      </>
   )
};

export default DeptItemList;
