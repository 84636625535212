import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import Login from '../pages/auth/Login';
import { logoutAction } from '../redux/slices/authSlice';

AuthGuard.propTypes = {
  children: PropTypes.node,
};

export default function AuthGuard({ children }) {
  const dispatch = useDispatch();
  const localStorages = JSON.parse(localStorage.getItem('factorytoken'))?.tokenDTO?.accessToken;

  const { pathname } = useLocation();

  const [requestedLocation, setRequestedLocation] = useState(null);

  useEffect(() => {
    const handleInvalidToken = (e) => {
      if (!e.key) {
        dispatch(logoutAction());
      }

      // when a user logs in
      if (e.key === 'factorytoken') {
        document.location.reload();
      }
    };

    window.addEventListener('storage', handleInvalidToken);
    return function cleanup() {
      window.removeEventListener('storage', handleInvalidToken);
    };
  });

  if (!localStorages) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }
    return <Login />;
  }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return <>{children}</>;
}
