import React from 'react'
import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';



const GuestGuard = ({ children }) => {
   const token = localStorage.getItem('factorytoken');
   if (token) {
      return <Navigate to="/dashboard" replace />;
   } else {
      return <>{children}</>

   }

}
GuestGuard.propTypes = {
   children: PropTypes.node,
};

export default GuestGuard