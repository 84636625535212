import { Button, Space, Table } from 'antd';
import React, { useEffect } from 'react';

import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import AddDepartment from './AddDepartment';
import CustomDeleteDialog from '../../../components/CustomDeleteDialog';
import { toast } from 'react-toastify';
import { deleteDepartment, getDepartmentList } from '../../../redux/slices/departmentSlice';
import { useDispatch } from 'react-redux';

const DepartmentBodyTable = ({ department }) => {
  // console.log('🚀 => DepartmentBodyTable => department=>', department);
  const dispatch = useDispatch();

  const [sort, setSort] = React.useState('descend');
  const [isEdit, setIsEdit] = React.useState({ status: false, record: {}, deptName: '' });
  const [isDelete, setIsDelete] = React.useState({ status: false, recordId: null });
  const user = JSON.parse(localStorage.getItem('factoryUserDetails'));
  const userGroup = user?.roles;

  const toggleSortOrder = () => {
    setSort((prev) => (prev === 'ascend' ? 'descend' : 'ascend'));
  };
  // useEffect(() => {
  //   dispatch(getDepartmentList());
  // }, [isEdit.status]);
  const columns = [
    {
      title: 'Category',
      dataIndex: 'deptCategory',
      key: 'deptCategory',
      sorter: (a, b) => a.deptCategory.localeCompare(b.deptCategory),
      sortOrder: sort,
      onHeaderCell: () => ({
        onClick: toggleSortOrder,
      }),
    },
    {
      title: 'Batch',
      dataIndex: 'batch',
      key: 'batch',
    },
    {
      title: 'Ingredients',
      dataIndex: 'ingredients',
      key: 'ingredients',
      render: (ingredients, record) => {
        const ingredientsList = ingredients.map((ingredient) => {
          return `${ingredient.productName}-${ingredient.productCategory} ${ingredient.quantity} ${ingredient.unit}`;
        });
        return ingredientsList.join(', ');
      },
    },

    userGroup.includes('ADMIN') || userGroup.includes('MANAGER')
      ? {
          title: 'Action',
          key: 'action',
          render: (text, record) => (
            <Space size="middle">
              {(userGroup.includes('ADMIN') || userGroup.includes('MANAGER')) && (
                <Button
                  onClick={() => {
                    setIsEdit({ status: true, record: record, deptName: department.deptName });
                  }}
                >
                  <EditOutlined style={{ color: 'green' }} />
                </Button>
              )}
              {userGroup.includes('ADMIN') && (
                <Button
                  onClick={() => {
                    setIsDelete({ status: true, recordId: record.deptId });
                  }}
                >
                  <DeleteOutlined style={{ color: 'red' }} />
                </Button>
              )}
            </Space>
          ),
        }
      : {},
  ];

  const deleteDepartmentRecord = () => {
    const isSuccessful = (isSuccess) => {
      if (isSuccess) {
        toast.success('Department deleted successfully');
        setIsDelete({ id: null, status: false });
      }
    };
    dispatch(deleteDepartment({ id: isDelete.recordId, isSuccess: isSuccessful }));
  };

  return (
    <>
      <Table
        columns={columns}
        dataSource={Array.isArray(department?.data) ? department?.data : []}
        rowKey={department.deptName}
      />
      {isEdit.status && (
        <AddDepartment
          isEdit
          open={isEdit.status}
          setOpen={setIsEdit}
          recordData={isEdit.record}
          departmentName={isEdit.deptName}
        />
      )}
      {isDelete.status && (
        <CustomDeleteDialog
          status={isDelete.status}
          open={isDelete.status}
          // loading={postLoading}
          handleClose={() => setIsDelete({ id: null, status: false })}
          onAccept={deleteDepartmentRecord}
        />
      )}
    </>
  );
};

export default DepartmentBodyTable;
