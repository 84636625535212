import React, { useEffect } from 'react';
import WorkerCard from '../../components/WorkerCard';
import Page from '../../components/Page';
import { Container, Grid, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { getWorkerPerfomance } from '../../redux/slices/productSlice';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import { PATH_DASHBOARD } from '../../routes/paths';

const WorkerPerformance = () => {
  const dispatch = useDispatch();

  const workerPerfomance = useSelector((state) => state.products.workerPerfomance);

  const sortedByPoints = workerPerfomance.slice().sort((a, b) => b?.totalReward - a?.totalReward);


  useEffect(() => {
    dispatch(getWorkerPerfomance());
  }, []);
  return (
    <Page title="Worker Performance">
      <HeaderBreadcrumbs
        heading="Worker Performance"
        links={[
          { name: 'Dashboard', href: PATH_DASHBOARD.root },
          { name: 'Worker Performance', href: PATH_DASHBOARD.workerPerformance.root },
        ]}
      />
      {/* <Typography style={{ fontSize: '30px', marginLeft: '35px' }}>Worker Performance</Typography> */}
      {/* <Container> */}
      <Grid container spacing={3} md={6} sm={12} lg={12}>
        {(sortedByPoints || []).map((item, index) => (
          <Grid item key={index} md={6} sm={12} lg={4}>
            <WorkerCard username={item?.userId} totalPoints={item?.totalReward} finishedGoods={item?.totalProduction} />
          </Grid>
        ))}
      </Grid>
      {/* </Container> */}
    </Page>
  );
};

export default WorkerPerformance;
