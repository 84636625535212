import React from 'react';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import { PATH_DASHBOARD } from '../../../routes/paths';
import { Button } from '@mui/material';
import Iconify from '../../../components/Iconify';
import AddProduct from './AddProduct';
const ProductHeader = () => {

  const user = JSON.parse(localStorage.getItem('factoryUserDetails'));
  const userGroup = user?.roles;

  const [addProdcut, setAddProduct] = React.useState(false);
  return (
    <>
      <HeaderBreadcrumbs
        heading="Products"
        links={[
          { name: 'Dashboard', href: PATH_DASHBOARD.root },
          { name: 'Products', href: PATH_DASHBOARD.product.root },
        ]}
        action={
          (userGroup.includes('ADMIN') || userGroup.includes('MANAGER')) && (
            <Button
              variant="contained"
              startIcon={<Iconify icon={'eva:plus-fill'} width={20} height={20} />}
              onClick={setAddProduct}
            >
              Add Products
            </Button>
          )
        }
      />
      {addProdcut && <AddProduct isModalOpen={addProdcut} setIsModalOpen={setAddProduct} />}
    </>
  );
};

export default ProductHeader;
