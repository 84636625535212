function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';
const ROOTS_FACTORY = '/factory';

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  register: path(ROOTS_AUTH, '/register'),
};

export const PATH_FACTORY = {
  root: ROOTS_FACTORY,
  momo: {
    root: path(ROOTS_FACTORY, '/momo'),
  },
  sause: {
    root: path(ROOTS_FACTORY, '/sause'),
  },
  dessert: {
    root: path(ROOTS_FACTORY, '/dessert'),
  },
  cookedFood: {
    root: path(ROOTS_FACTORY, '/cookedFood'),
  },
  rawMeat: {
    root: path(ROOTS_FACTORY, '/rawMeat'),
  },
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    app: path(ROOTS_DASHBOARD, '/app'),
  },
  notFound: {
    root: path(ROOTS_DASHBOARD, '/not-found'),
  },

  department: {
    root: path(ROOTS_DASHBOARD, '/department'),
    list: path(ROOTS_DASHBOARD, '/department/list'),
    deptItems: path(ROOTS_DASHBOARD, '/department/deptItems'),
  },
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
  },
  product: {
    root: path(ROOTS_DASHBOARD, '/product'),
    list: path(ROOTS_DASHBOARD, '/product/list'),
    category: path(ROOTS_DASHBOARD, '/product/category'),
  },
  inventory: {
    root: path(ROOTS_DASHBOARD, '/inventory'),
  },
  finishedGoods: {
    root: path(ROOTS_DASHBOARD, '/inventory/finishedGoods'),
  },
  vendor: {
    root: path(ROOTS_DASHBOARD, '/vendors'),
  },
  workerPerformance: {
    root: path(ROOTS_DASHBOARD, '/performance'),
  },
  haccpPlan: {
    root: path(ROOTS_DASHBOARD, '/haccp'),
  },
  factoryProcessing: {
    root: ROOTS_FACTORY,
  },
};
