import React, { useEffect, useState } from 'react';
import { Grid, Typography, FormControl, RadioGroup, FormControlLabel, Radio, Button, Stack, Box } from '@mui/material';
import goodMomo from './../../../assets/good.jpg';
import okMomo from './../../../assets/ok.jpg';
import badMomo from './../../../assets/bad.jpg';
import CaptureImage from '../../../components/CaptureImage';
import { useFormContext } from 'react-hook-form';

function DessertQuality() {
  const { setValue } = useFormContext();

  const [selectedQuality, setSelectedQuality] = useState('good');
  const [captureImage, setCaptureImage] = React.useState(false);
  const [capturedImage, setCapturedImage] = useState(null); // State for storing the captured image

  const handleQualityChange = (event) => {
    setSelectedQuality(event.target.value);
    setValue('productQuality', event.target.value);
  };

  const imageStyle = {
    width: '250px', // Set the width you desire
    height: '250px', // Set the height you desire
    objectFit: 'cover', // This will ensure the aspect ratio is maintained without stretching
  };

  // Add a media query for large screens
  const largeScreenStyle = {
    ...imageStyle,
    '@media (min-width: 1000px)': {
      width: '300px',
      height: '300px',
    },
  };

  const handleImageCapture = (imageDataUrl) => {
    setCapturedImage(imageDataUrl); // Store the captured image
    setValue(`productImage`, imageDataUrl);
    setCaptureImage(false); // Close the camera capture component
  };

  useEffect(() => {
    setValue('productQuality', 'good');
  }, [setValue]);
  return (
    <Grid container spacing={2}>
      <FormControl component="fieldset" style={{ width: '100%' }}>
        <RadioGroup
          aria-label="momo-quality"
          name="momo-quality"
          value={selectedQuality}
          onChange={handleQualityChange}
        >
          <Grid container item spacing={2}>
            <Grid item md={5} lg={4}>
              <FormControlLabel
                value="good"
                control={<Radio />}
                label={
                  <div
                    style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}
                  >
                    <Typography variant="h6">Good</Typography>
                    <img src={goodMomo} alt="good momo" style={largeScreenStyle} />
                  </div>
                }
              />
            </Grid>
            <Grid item md={5} lg={4}>
              <FormControlLabel
                value="ok"
                control={<Radio />}
                label={
                  <div
                    style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}
                  >
                    <Typography variant="h6">Ok</Typography>
                    <img src={okMomo} alt="ok momo" style={largeScreenStyle} />
                  </div>
                }
              />
            </Grid>
            <Grid item md={5} lg={4}>
              <FormControlLabel
                value="bad"
                control={<Radio />}
                label={
                  <div
                    style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}
                  >
                    <Typography variant="h6">Bad</Typography>
                    <img src={badMomo} alt="bad momo" style={largeScreenStyle} />
                  </div>
                }
              />
            </Grid>
          </Grid>
        </RadioGroup>
      </FormControl>
      {/* components to open camera for image capture */}
      <Grid item xs={12}>
        <Stack justifyContent={'flex-start'} alignItems={'flex-start'}>
          {!captureImage && !capturedImage && <Button onClick={() => setCaptureImage(true)}>Open Camera</Button>}
          {!captureImage && capturedImage && <Button onClick={() => setCaptureImage(true)}>Capture Again</Button>}
        </Stack>
      </Grid>
      <Stack direction={'row'} justifyContent={'center'} alignItems={'center'}>
        {captureImage && <CaptureImage onCapture={handleImageCapture} onClose={() => setCaptureImage(false)} />}
        {capturedImage && <img src={capturedImage} alt="Captured" style={{ width: '250px', height: '250px' }} />}
      </Stack>
    </Grid>
  );
}

export default DessertQuality;
