import { DeleteOutlined, EditOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Button, Modal, Space, Switch, Table, Tag } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CustomDeleteDialog from '../../../components/CustomDeleteDialog';
import { createVendor, deleteVendor, getAllVendors } from '../../../redux/slices/vendorSlice';
import AddVendor from './AddVendor';

const VendorBody = () => {
  const dispatch = useDispatch();
  const [isEdit, setIsEdit] = React.useState(false);
  const [record, setRecord] = React.useState(null);
  const [isDelete, setIsDelete] = React.useState({ id: null, status: false });
  const { vendors, loading, postLoading } = useSelector((state) => state.vendors);
  const [isSwitchModalVisible, setIsSwitchModalVisible] = useState(false);
  const [changedVendorStatus, setChangedVendorStatus] = useState(false);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedVendor, setSelectedVendor] = useState(null);
  const user = JSON.parse(localStorage.getItem('factoryUserDetails'));
  const userGroup = user?.roles;

  const deleteVendorData = () => {
    const isSuccessful = (isSuccess) => {
      if (isSuccess) {
        setIsDelete({ id: null, status: false });
      }
    };
    dispatch(deleteVendor({ id: isDelete.id, isSuccess: isSuccessful }));
  };

  const handleSwitchChange = (record) => {
    setIsSwitchModalVisible(true);
    setChangedVendorStatus({
      ...record,
      vendorActive: !record.vendorActive,
      vendorPriority: record?.vendorPriority || 1,
    });
  };

  const vendorChangeModalOk = () => {
    dispatch(
      createVendor({
        data: changedVendorStatus,
        id: changedVendorStatus?.vendorId,
      })
    );

    setIsSwitchModalVisible(false);
    dispatch(getAllVendors());
  };

  const columns = [
    {
      title: 'ID',
      dataIndex: 'vendorId',
      key: 'vendorId',
    },
    {
      title: 'Vendor Name',
      dataIndex: 'vendorName',
      key: 'VendorName',
      filterSearch: true,
      render: (text, record) => (
        <>
          {text}
          <Button
            type="link"
            onClick={() => {
              setSelectedVendor(record);
              setIsModalVisible(true);
            }}
          >
            <InfoCircleOutlined />
          </Button>
        </>
      ),
    },

    {
      title: 'Email',
      dataIndex: 'vendorEmail',
      key: 'vendorEmail',
    },
    //  {
    //    title: 'Address',
    //    dataIndex: 'address',
    //    key: 'address',
    //  },
    {
      title: 'Phone Number',
      dataIndex: 'vendorPhone',
      key: 'vendorPhone',
    },
    {
      title: 'Priority',
      dataIndex: 'vendorPriority',
      key: 'vendorPriority',
      render: (text, record) => (
        <Tag color={text === 2 ? 'green' : text === 1 ? 'orange' : text === 0 ? 'red' : ''}>
          {text === 2 ? 'High' : text === 1 ? 'Mid' : text === 0 ? 'Low' : 'Undetermined'}
        </Tag>
      ),
    },
    {
      title: 'Status', // New column for the switch
      dataIndex: 'vendorActive',
      key: 'vendorActive',
      render: (text, record) => (
        <Switch
          checked={record.vendorActive} // Set the switch state based on vendorActive
          onChange={() => handleSwitchChange(record)}
          style={{ background: record?.vendorActive ? 'green' : '' }}
          disabled={userGroup.includes('FACTORYTECH')}
        />
      ),
    },

    userGroup.includes('ADMIN' || userGroup.includes('MANAGER'))
      ? {
          title: 'Action',
          key: 'action',
          render: (text, record) => (
            <Space size="middle">
              {(userGroup.includes('ADMIN') || userGroup.includes('MANAGER')) && (
                <Button
                  onClick={() => {
                    setRecord(record);
                    setIsEdit(true);
                  }}
                >
                  <EditOutlined style={{ color: 'green' }} />
                </Button>
              )}
              {userGroup.includes('ADMIN') && (
                <Button
                  onClick={() => {
                    setIsDelete({ id: record.vendorId, status: true });
                  }}
                >
                  <DeleteOutlined style={{ color: 'red' }} />
                </Button>
              )}
            </Space>
          ),
        }
      : {},
  ];

  useEffect(() => {
    dispatch(getAllVendors());
  }, [dispatch]);
  return (
    <>
      <Modal
        title={`Additional details for ${selectedVendor?.vendorName}`}
        open={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={[
          <Button key="back" onClick={() => setIsModalVisible(false)}>
            Cancel
          </Button>,
        ]}
      >
        <p style={{ marginBottom: '-15px' }}>Years in Business: {selectedVendor?.yearsInBusiness}</p>
        <p style={{ marginBottom: '-15px' }}>Vendor Reliability: {selectedVendor?.reliabilityPts}</p>
      </Modal>

      <Table
        columns={columns}
        dataSource={Array.isArray(vendors) ? vendors : []}
        loading={loading}
        rowKey={(vendors) => vendors?.id}
      />

      {isEdit && <AddVendor open={isEdit} setOpen={setIsEdit} record={record} isEdit={true} />}
      {isDelete.status && (
        <CustomDeleteDialog
          status={isDelete.status}
          loading={postLoading}
          handleClose={() => {
            setIsDelete({ id: null, status: false });
          }}
          onAccept={deleteVendorData}
        />
      )}
      <Modal
        title="Do you want to update Vendor Status?"
        open={isSwitchModalVisible}
        onOk={() => {
          vendorChangeModalOk();
        }}
        onCancel={() => setIsSwitchModalVisible(false)}
      ></Modal>
    </>
  );
};

export default VendorBody;
