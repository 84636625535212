import PropTypes from 'prop-types';
// @mui
import { Box, Tooltip } from '@mui/material';
// hooks
// import useLocales from '../../../hooks/useLocales';
// guards
// import RoleBasedGuard from '../../../guards/RoleBasedGuard';
//
import Iconify from '../../Iconify';
import { ListItemStyle, ListItemTextStyle, ListItemIconStyle } from './style';

export default function NavItem({ item, depth, active, open, isCollapse, ...other }) {
  // const { translate } = useLocales();

  const { title, icon, info, children, disabled, caption, roles } = item;

  const renderContent = (
    <ListItemStyle depth={depth} active={active} disabled={disabled} {...other}>
      {icon && (
        <ListItemIconStyle style={{ color: active ? '#fff' : 'rgba(255,255,255,0.7)' }}>{icon}</ListItemIconStyle>
      )}
      {/* NOTE: Here we change the color of icon */}

      {depth !== 1 && (
        <DotIcon style={{ color: active ? '#fff' : 'rgba(255,255,255,0.7)' }} active={active && depth !== 1} />
      )}
      {/* NOTE: here we change the color of dot-icon */}

      <ListItemTextStyle
        isCollapse={isCollapse}
        primary={title}
        style={{ color: active ? '#fff' : 'rgba(255,255,255,0.7)' }}
        // NOTE: here we change the color of test
        secondary={
          caption && (
            <Tooltip title={caption} placement="top-start">
              <span>{caption}</span>
            </Tooltip>
          )
        }
        primaryTypographyProps={{
          noWrap: true,
          variant: active ? 'subtitle2' : 'body2',
        }}
        secondaryTypographyProps={{
          noWrap: true,
          variant: 'caption',
        }}
      />

      {!isCollapse && (
        <>
          {info && (
            <Box component="span" sx={{ lineHeight: 0 }}>
              {info}
            </Box>
          )}

          {!!children && (
            <Iconify
              icon={open ? 'eva:arrow-ios-downward-fill' : 'eva:arrow-ios-forward-fill'}
              sx={{ width: 16, height: 16, ml: 1, flexShrink: 0, color: '#fff' }}
            />
          )}
        </>
      )}
    </ListItemStyle>
  );

  // return <RoleBasedGuard roles={roles}>{renderContent}</RoleBasedGuard>;
  return renderContent;
}

// ----------------------------------------------------------------------

DotIcon.propTypes = {
  active: PropTypes.bool,
};

export function DotIcon({ active }) {
  return (
    <ListItemIconStyle>
      <Box
        component="span"
        sx={{
          width: 4,
          height: 4,
          borderRadius: '50%',
          bgcolor: 'text.disabled',
          transition: (theme) =>
            theme.transitions.create('transform', {
              duration: theme.transitions.duration.shorter,
            }),
          ...(active && {
            transform: 'scale(2)',
            bgcolor: 'primary.main',
          }),
        }}
      />
    </ListItemIconStyle>
  );
}
