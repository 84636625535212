import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { getDepartmentAllData, getDepartmentList } from '../../../redux/slices/departmentSlice';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import Page from '../../../components/Page';
import CookedFoodDepartmentStepper from './CookedFoodDepartmentStepper';

const CookedFoodSchema = Yup.object().shape({
  selectedCategory: Yup.string().required('Category is required'),
  selectedBatch: Yup.string().required('Batch is required'),
});

function CookedFoodIndex() {
  const dispatch = useDispatch();

  const defaultValues = {
    selectedCategory: '',
    selectedBatch: '',
    estimatedProductionMet: true,
    productDesc: '',
  };

  const methods = useForm({ defaultValues, resolver: yupResolver(CookedFoodSchema), mode: 'onChange' });

  const FlowCompleteHandler = (data) => {};

  useEffect(() => {
    dispatch(getDepartmentList());
    dispatch(getDepartmentAllData());
  }, [dispatch]);
  return (
    <Page title="Cooked Food Dept">
      <FormProvider {...methods}>
        <CookedFoodDepartmentStepper onSubmit={FlowCompleteHandler} />
      </FormProvider>
    </Page>
  );
}

export default CookedFoodIndex;
