import React, { useEffect, useRef } from 'react';
import { BrowserMultiFormatReader, NotFoundException } from '@zxing/library';
import { Button } from '@mui/material';

const QrCodeScanner = ({ onClose, onScan }) => {
  const videoRef = useRef(null);
  const codeReader = useRef(new BrowserMultiFormatReader()); // Initialize new reference here so that it can be set to null in stopStream which stops the camera access
  const streamRef = useRef(null); // New reference for the stream

  // Function to stop the camera stream
  const stopStream = () => {
    if (streamRef.current) {
      streamRef.current.getTracks().forEach((track) => track.stop());
      streamRef.current = null;
    }
    if (videoRef.current) {
      videoRef.current.srcObject = null;
    }
  };

  useEffect(() => {
    const startScanner = async () => {
      try {
        const constraints = {
          audio: false,
          video: { facingMode: 'environment' },
        };

        const stream = await navigator.mediaDevices.getUserMedia(constraints);
        streamRef.current = stream; // Store the stream
        videoRef.current.srcObject = stream;

        // codeReader.current = new BrowserMultiFormatReader();
        codeReader.current.decodeFromVideoDevice(undefined, videoRef.current, (result, error) => {
          if (result) {
            onScan(result);
            codeReader.current.reset();
            stopStream();
          } else if (error && !(error instanceof NotFoundException)) {
            console.error(error);
          }
        });
      } catch (error) {
        console.error(error);
      }
    };

    startScanner();

    return () => {
      codeReader.current.reset();
      stopStream(); // Use the stopStream function to clean up
    };
  }, [onScan]);

  return (
    <div style={{ height: '100%', width: '100%', zIndex: '999' }}>
      {!videoRef && 'Loading'}
      <video ref={videoRef} style={{ width: '100%' }} />
      <Button onClick={onClose}>Close Scanner</Button>
    </div>
  );
};

export default QrCodeScanner;
