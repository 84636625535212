import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

function CustomMultipleAutoCompleteField({ label, limitTags = 2, name, options = [], multiple = false, ...other }) {
  const { control } = useFormContext();
  // const shouldAutoSelect = options && options.length === 1;
  return (
    <div className="form--input">
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, ...field }, fieldState: { error } }) => (
          <Autocomplete
            multiple={multiple}
            onChange={(_, data) => onChange(data)}
            label={label}
            {...field}
            // autoSelect={shouldAutoSelect}
            limitTags={limitTags}
            error={!!error}
            options={options}
            renderInput={(params) => <TextField {...params} label={label} error={!!error} />}
            {...other}
          />
        )}
      />
    </div>
  );
}

export default CustomMultipleAutoCompleteField;
