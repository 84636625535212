import { Box, Button, CircularProgress, Stack, Step, StepLabel, Stepper, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { PATH_FACTORY } from '../../../routes/paths';
import { toast } from 'react-toastify';
import DessertDepartmentActiveStepper from './DessertDepartmentActiveStepper';
import apiURL from '../../../api';
import moment from 'moment';
import Iconify from '../../../components/Iconify';

const steps = [
  'Choose Category',
  'Choose Batch',
  'Sanitation Checklist',
  'Verify Ingredients',
  'Processing',
  'Quality Check',
  'Confirm',
];

const content = [
  {
    point: '416.1 General rules.',
    content:
      'Each official establishment must be operated and maintained in a manner sufficient to prevent the creation of insanitary conditions and to ensure that product is not adulterated. ',
    isChecked: false,
  },
  {
    isChecked: false,
    point: '416.2 Establishment grounds and facilities.',
    content:
      'The grounds about an establishment must be maintained to prevent conditions that could lead to insanitary conditions, adulteration of product, or interfere with inspection by FSIS program employees. Establishments must have in place a pest management program to prevent the harborage and breeding of pests on the grounds and within establishment facilities. Pest control substances used must be safe and effective under the conditions of use and not be applied or stored in a manner that will result in the adulteration of product or the creation of insanitary conditions.',
  },
  {
    isChecked: false,
    point: '416.3 Equipment and utensils.',
    content:
      'Equipment and utensils used for processing or otherwise handling edible product or ingredients must be of such material and construction to facilitate thorough cleaning and to ensure that their use will not cause the adulteration of product during processing, handling, or storage. Equipment and utensils must be maintained in sanitary condition so as not to adulterate product',
  },
  {
    isChecked: false,
    point: '416.4 Sanitary operations.',
    content:
      'All food-contact surfaces, including food-contact surfaces of utensils and equipment, must be cleaned and sanitized as frequently as necessary to prevent the creation of insanitary conditions and the adulteration of product. ',
  },
  {
    isChecked: false,
    point: '416.5 Employee hygiene.',
    content:
      'All persons working in contact with product, food-contact surfaces, and product-packaging materials must adhere to hygienic practices while on duty to prevent adulteration of product and the creation of insanitary conditions. ',
  },
  {
    isChecked: false,
    point: '416.6 Tagging insanitary equipment, utensils, rooms or compartments.',
    content:
      'When an FSIS program employee finds that any equipment, utensil, room, or compartment at an official establishment is insanitary or that its use could cause the adulteration of product, he will attach to it a “U.S. Rejected” tag. Equipment, utensils, rooms, or compartments so tagged cannot be used until made acceptable. Only an FSIS program employee may remove a “U.S. Rejected” tag.',
  },
  {
    isChecked: false,
    point: '416.11 General rules.',
    content:
      "Each official establishment shall develop, implement, and maintain written standard operating procedures for sanitation (Sanitation SOP's) in accordance with the requirements of this part.",
  },
  {
    isChecked: false,
    point: "416.12 Development of Sanitation SOP's.",
    content:
      "The Sanitation SOP's shall describe all procedures an official establishment will conduct daily, before and during operations, sufficient to prevent direct contamination or adulteration of product(s).",
  },
  {
    isChecked: false,
    point: "416.13 Implementation of SOP's.",
    content:
      "Each official establishment shall conduct the pre-operational procedures in the Sanitation SOP's before the start of operations \n Each official establishment shall conduct all other procedures in the Sanitation SOP's at the frequencies specified. \n Each official establishment shall monitor daily the implementation of the procedures in the Sanitation SOP's.",
  },
  {
    isChecked: false,
    point: "416.14 Maintenance of Sanitation SOP's.",
    content:
      "Each official establishment shall routinely evaluate the effectiveness of the Sanitation SOP's and the procedures therein in preventing direct contamination or adulteration of product(s) and shall revise both as necessary to keep them effective and current with respect to changes in facilities, equipment, utensils, operations, or personnel. ",
  },
  {
    isChecked: false,
    point: '416.15 Corrective Actions.',
    content:
      "Each official establishment shall take appropriate corrective action(s) when either the establishment or FSIS determines that the establishment's Sanitation SOP's or the procedures specified therein, or the implementation or maintenance of the Sanitation SOP's, may have failed to prevent direct contamination or adulteration of product(s).  ",
  },
  {
    isChecked: false,
    point: '416.16 Recordkeeping requirements.',
    content:
      "Each official establishment shall maintain daily records sufficient to document the implementation and monitoring of the Sanitation SOP's and any corrective actions taken. The establishment employee(s) specified in the Sanitation SOP's as being responsible for the implementation and monitoring of the procedure(s) specified in the Sanitation SOP's shall authenticate these records with his or her initials and the date.",
  },
  {
    isChecked: false,
    point: '416.17 Agency verification.',
    content:
      "FSIS shall verify the adequacy and effectiveness of the Sanitation SOP's and the procedures specified therein by determining that they meet the requirements of this part. Such verification may include:  Reviewing the Sanitation SOP's; Reviewing the daily records documenting the implementation of the Sanitation SOP's and the procedures specified therein and any corrective actions taken or required to be taken;  Direct observation of the implementation of the Sanitation SOP's and the procedures specified therein and any corrective actions taken or required to be taken; and  Direct observation or testing to assess the sanitary conditions in the establishment. ",
  },
];
function DessertDepartmentStepper({ onSubmit }) {
  const [isLoading, setIsLoading] = useState(false);

  const [activeStep, setActiveStep] = useState(0);

  const navigate = useNavigate();
  const isStepFailed = (step) => step === activeStep;

  const {
    clearErrors,
    trigger,
    control,
    handleSubmit,
    setValue,
    formState: { errors },
    reset,
  } = useFormContext();

  const ingredientsList = useWatch({
    control,
    name: 'ingredients',
  });

  const viewData = useWatch({
    control,
    name: 'checkedList',
  });
  const selectedCategory = useWatch({
    control,
    name: 'selectedCategory',
  });
  const selectedBatch = useWatch({
    control,
    name: 'selectedBatch',
  });
  const newProductSKU = useWatch({
    control,
    name: 'newProductSKU',
  });
  const productImage = useWatch({
    control,
    name: 'productImage',
  });

  const handleNext = async () => {
    let isOk = false;

    switch (activeStep) {
      case 0:
        isOk = await trigger(['selectedCategory']);
        break;
      case 1:
        isOk = await trigger(['selectedBatch']);
        break;
      case 2:
        isOk = viewData?.every((item) => item?.isChecked);
        break;
      case 3:
        isOk = ingredientsList?.every((item) => item?.sku?.every((sku) => sku.isVerified));
        break;
      case 4:
        isOk = newProductSKU;
        break;
      default:
        isOk = true;
    }
    if (isOk && !Object.keys(errors).length) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    } else {
      isStepFailed(activeStep);
      if (activeStep === 2 && !isOk) {
        const errorMessage = 'Please Select all of the Steps';
        toast.error(errorMessage);
      }
      if (activeStep === 4 && !isOk) {
        const errorMessage = 'Please verify the product by scanning QR code';
        toast.error(errorMessage);
      }
    }
  };

  const handleBack = () => {
    clearErrors();
    if (activeStep === 0) return navigate(PATH_FACTORY.root);
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  useEffect(() => {
    setValue('checkedList', content);
  }, [setValue]);
  const startProcessing = async () => {
    const isOk = ingredientsList?.every((item) => item?.sku?.every((sku) => sku.isVerified));

    if (!isOk) {
      const errorMessage = 'Please verify all the ingredients';
      return toast.error(errorMessage);
    }
    setIsLoading(true);
    const data = {
      deptName: 'Dessert',
      deptCategory: selectedCategory,
      batch: selectedBatch,
      skuList: ingredientsList?.flatMap((ingredient) => ingredient.sku.map((sku) => sku.code)),
    };
    try {
      const response = await apiURL.post('factory-processing', data);

      if (response?.data === 'Accepted') {
        setIsLoading(false);
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    } catch (e) {
      setIsLoading(false);
      toast.error(e.response.data?.message || 'Something went wrong');
    }
    setIsLoading(false);
  };
  const submitQualityImage = async () => {
    const isOk = productImage;
    if (!isOk) {
      const errorMessage = 'Please add quality image';
      return toast.error(errorMessage);
    }

    setIsLoading(true);
    const dataUrlParts = productImage?.split(',');
    const mime = dataUrlParts[0].match(/:(.*?);/)[1];
    const b64Data = atob(dataUrlParts[1]);
    let byteArray = new Uint8Array(b64Data.length);

    for (let i = 0; i < b64Data.length; i++) {
      byteArray[i] = b64Data.charCodeAt(i);
    }

    const blob = new Blob([byteArray], { type: mime });
    const formData = new FormData();
    formData.append('file', blob, 'image.png');
    formData.append('departmentName', 'Dessert');
    formData.append('deptCategory', selectedCategory);
    formData.append('sku', newProductSKU);

    try {
      const response = await apiURL.post('quality/upload', formData);
      if (response?.status === 200) {
        setIsLoading(false);

        setValue('imageUrl', response?.data?.data?.url);
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        return;
      }
    } catch (e) {
      toast.error(e?.response?.data?.message || 'Something went wrong');
      console.error(e);
      setIsLoading(false);
    }
  };

  const FinishProcessing = async (data) => {
    let user = localStorage.getItem('factoryUserDetails');
    user = JSON.parse(user);

    const finalData = {
      productName: data?.selectedCategory,
      productSku: data?.newProductSKU,
      productType: 'FINISHED_GOOD',
      productCategory: 'Dessert',
      productDesc: data?.productDesc,
      productExpiry: moment().add(7, 'days').format('YYYY-MM-DD'),
      productQty: 1,
      productUnit: data?.productUnit,
      productPrice: 0,
      freshness: 0,
      quality: {
        qualityName: data?.productQuality,
        pictureUrl: data?.imageUrl,
      },
      deptName: 'Dessert',
      deptCategory: data?.selectedCategory,
      batch: data?.selectedBatch,
      userId: user?.username,
      productWeight: '',
    };

    try {
      const response = await apiURL.post('product/save', finalData);

      if (response?.status === 200) {
        toast.success('Product Saved Successfully');
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    } catch (e) {
      toast.error(e?.response?.data?.message || 'Something went wrong');
      console.log(e?.response?.data?.message);
    }
  };
  const handleReset = () => {
    reset(); // Reset the form values
    setActiveStep(0); // Set the active step back to the first step
  };

  return (
    <Box sx={{ margin: '40px', marginLeft: '80px', marginRight: '80px', paddingLeft: '60px', paddingRight: '60px' }}>
      <Stepper activeStep={activeStep}>
        {steps?.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          if (Object.keys(errors).length && isStepFailed(index)) {
            labelProps.optional = (
              <Typography variant="caption" color="error">
                {'Select required field'}
              </Typography>
            );

            labelProps.error = true;
          }
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label} </StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {activeStep === steps.length ? (
        <>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '30px',
            }}
          >
            <Iconify
              style={{ color: 'green', marginTop: '5px', marginRight: '5px' }}
              height={20}
              width={20}
              icon={'charm:square-tick'}
            />
            <Typography variant="h6" sx={{ mt: 2, mb: 1 }} align="center">
              Congratulations!!! The product has been saved Successfully
            </Typography>
          </div>

          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 5 }}>
            <Box sx={{ flex: '1 1 auto' }} />
            <Button variant="contained" style={{ marginRight: '15px' }} onClick={handleReset}>
              Restart
            </Button>

            <Button variant="contained" onClick={() => navigate('/factory')}>
              Finish
            </Button>
          </Box>
        </>
      ) : (
        <>
          <Stack mt={'2rem'}>
            <DessertDepartmentActiveStepper activeStep={activeStep} />
          </Stack>
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Button color="inherit" onClick={handleBack} sx={{ mr: 1 }}>
              Back
            </Button>
            <Box sx={{ flex: '1 1 auto' }} />
            {activeStep === steps.length - 1 && (
              <Button variant="contained" type="button" onClick={handleSubmit(FinishProcessing)}>
                Finish Processing
              </Button>
            )}
            {activeStep === 3 &&
              (isLoading ? (
                <CircularProgress />
              ) : (
                <Button variant="contained" type="button" onClick={handleSubmit(startProcessing)}>
                  Start Processing
                </Button>
              ))}
            {activeStep === 5 &&
              (isLoading ? (
                <CircularProgress />
              ) : (
                <Button variant="contained" onClick={handleSubmit(submitQualityImage)} type="button">
                  Submit Image
                </Button>
              ))}
            {activeStep !== 3 && activeStep !== 5 && activeStep !== steps.length - 1 && (
              <Button variant="contained" onClick={handleNext} type="button">
                Next
              </Button>
            )}
          </Box>
        </>
      )}
    </Box>
  );
}

export default DessertDepartmentStepper;
