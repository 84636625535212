import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { Button, Modal } from 'antd';
import { createDepartment, getDepartmentList, updateDepartment } from '../../../redux/slices/departmentSlice';
import { toast } from 'react-toastify';
import { Grid, Icon, InputAdornment, MenuItem, Stack } from '@mui/material';
import RHFSelect from '../../../components/hookform/RHFSelect';
import DepartmentIngredients from './DepartmentIngredients';
import { getProductsReference } from '../../../redux/slices/productSlice';
import RHFTextField from '../../../components/hookform/RHFTextField';
import Iconify from '../../../components/Iconify';
import CustomLoadingScreen from '../../../components/CustomLoadingScreen';
import { DollarOutlined } from '@ant-design/icons';
import CustomSelectField from '../../../components/CustomSelectField';
import RHFSwitch from '../../../components/hookform/RHFSwitch';

const AddDepartment = ({ open, setOpen, recordData, departmentName, isEdit, isDept }) => {
  console.log('🚀 => AddDepartment => recordData=>', recordData);

  const dispatch = useDispatch();
  const [categoryList, setCategoryList] = React.useState([]);
  const [isNewDept, setIsNewDept] = React.useState(false);
  const [isNewCategory, setIsNewCategory] = React.useState(false);

  const { postLoading, loading, departmentList } = useSelector((state) => state.department);
  const { referenceProducts, loading: productRefLoading } = useSelector((state) => state.products);
  // console.log('🚀 => AddDepartment => referenceProducts=>', referenceProducts);
  const units = ['tbsp', 'oz', 'lb', 'g', 'kg', 'ml', 'fl oz', 'qt', 'liters', 'cup', 'gallon'];

  const defaultValues = {
    deptName: null,
    deptCategory: null,
    batch: null,
    ingredients: [{ unit: '', isCount: false }],
  };

  const departmentSchema = Yup.object().shape({
    deptName: Yup.string().required('Name is required'),
  });

  const methods = useForm({ defaultValues, resolver: yupResolver(departmentSchema) });
  const { handleSubmit, reset, setValue } = methods;

  const DepartmentSubmit = (data) => {
    const isSuccessful = (isSuccess) => {
      if (isSuccess) {
        dispatch(getDepartmentList());
        isEdit ? setOpen({ status: false, record: {}, deptName: '' }) : setOpen(false);

        isEdit ? toast.success('Department Updated successfully') : toast.success('Department created successfully');
      }
    };
    if (isEdit) {
      const finalData = {
        batch: data.batch,
        deptCategory: data.deptCategory,
        deptId: data.deptId,
        deptName: data.deptName,

        status: data.status,
        ingredients: data?.ingredients?.map((item) => ({
          ...item,
          productCategory: item?.productCategory,
          unit: item?.isCount ? 'COUNT' : item?.unit,
          quantity: parseInt(item.quantity),
          productName: item?.productName?.productName,
        })),
      };
      dispatch(updateDepartment({ data: finalData, id: recordData.dept_id, isSuccess: isSuccessful }));
    } else {
      const finalData = {
        ...data,

        ingredients: data?.ingredients?.map((item) => ({
          ...item,
          productCategory: item?.productCategory,
          unit: item?.isCount ? 'COUNT' : item?.unit,
          quantity: parseInt(item.quantity),
          productName: item?.productName?.productName,
        })),
      };
      console.log('🚀 => DepartmentSubmit => finalData=>', finalData);

      dispatch(createDepartment({ data: finalData, isSuccess: isSuccessful }));
    }
  };
  const Batch = ['SMALL', 'MEDIUM', 'LARGE'];

  useEffect(() => {
    dispatch(getProductsReference());
  }, [dispatch]);

  useEffect(() => {
    if (!isEdit) return;
    reset({
      ...recordData,
      deptName: departmentName,

      ingredients: recordData?.ingredients?.map((item) => ({
        ...item,
        isCount: item?.unit === 'COUNT' ?? false,
        productName: referenceProducts?.data?.find(
          (i) => i.productName?.toUpperCase() === item.productName?.toUpperCase()
        ),
      })),
    });
  }, [departmentName, isEdit, recordData, referenceProducts, reset]);

  useEffect(() => {
    if (!isDept) return;
    setValue('deptName', departmentName);
  }, [departmentName, isDept, reset, setValue]);

  const watchDept = methods.watch('deptName');

  useEffect(() => {
    if (!watchDept) {
      setCategoryList([]);
      return;
    }
    const category = departmentList?.filter((el) => el.deptName === watchDept);
    setCategoryList(category[0]?.categoryList);
  }, [watchDept, isNewDept]);

  console.log('data', methods.watch());

  return (
    <Modal
      title={isEdit ? 'Edit Department' : 'Add Department'}
      width={750}
      open={open}
      footer={[
        <Button key="back" onClick={() => setOpen(false)}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" onClick={handleSubmit(DepartmentSubmit)} disabled={postLoading}>
          OK
        </Button>,
      ]}
      onCancel={() => setOpen(false)}
    >
      {loading || productRefLoading ? (
        <CustomLoadingScreen height="30vh" />
      ) : (
        <FormProvider {...methods}>
          <div style={{ marginTop: '1rem' }} />
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} lg={4}>
              {isNewDept ? (
                <Stack spacing={2} direction={'row'}>
                  <RHFTextField name="deptName" label="Department Name*" />
                  <Iconify
                    icon="carbon:close-outline"
                    onClick={() => {
                      setIsNewDept(false);
                    }}
                    style={{ cursor: 'pointer', marginTop: '15px' }}
                    height={20}
                    width={20}
                    color="red"
                  />
                </Stack>
              ) : (
                <RHFSelect name="deptName" label="Department Name*" disabled={isDept}>
                  {departmentList?.map((dept, index) => (
                    <MenuItem key={index} value={dept.deptName}>
                      {dept?.deptName}
                    </MenuItem>
                  ))}
                  <MenuItem
                    value={''}
                    onClick={() => {
                      setIsNewDept(true);
                      setIsNewCategory(true);
                    }}
                  >
                    Custom Department
                  </MenuItem>
                </RHFSelect>
              )}
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              {isNewCategory ? (
                <Stack spacing={2} direction={'row'}>
                  <RHFTextField name="deptCategory" label="Category" />
                  <Iconify
                    icon="carbon:close-outline"
                    onClick={() => {
                      setIsNewCategory(false);
                    }}
                    style={{ cursor: 'pointer', marginTop: '15px' }}
                    height={20}
                    width={20}
                    color="red"
                  />
                </Stack>
              ) : (
                <RHFSelect name="deptCategory" label="Category">
                  {categoryList.map((category, index) => (
                    <MenuItem key={index} value={category}>
                      {category}
                    </MenuItem>
                  ))}
                  <MenuItem
                    value={''}
                    onClick={() => {
                      setIsNewCategory(true);
                    }}
                  >
                    Custom Category
                  </MenuItem>
                </RHFSelect>
              )}
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <RHFSelect name="batch" label="Batch">
                {Batch.map((batch, index) => (
                  <MenuItem key={index} value={batch}>
                    {batch}
                  </MenuItem>
                ))}
              </RHFSelect>
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <RHFTextField
                name="deptItemBatchPrice"
                type="number"
                label="Batch Price"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <DollarOutlined />
                    </InputAdornment>
                  ),
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                prefix={true}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <RHFTextField name="estimatedProduction" type="number" label="Estimated Production" />
            </Grid>
            <Grid item xs={12} sm={3} lg={3}>
              <CustomSelectField name="deptItemProductionUnit" label="Production Unit" labelShrink={true}>
                {units?.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </CustomSelectField>
            </Grid>
            <Grid item xs={12} sm={3} lg={3}>
              <RHFSwitch
                label="Needs Chilling"
                name="needsChilling"
                style={{ whiteSpace: 'nowrap' }}
                defaultValues={false}
              />
            </Grid>
          </Grid>

          <div style={{ marginTop: '1rem' }} />

          <DepartmentIngredients />
        </FormProvider>
      )}
    </Modal>
  );
};

export default AddDepartment;
