import React from 'react';
import FactoryIndexHeader from './FactoryIndexHeader';
import FactoryIndexBody from './FactoryIndexBody';
import Page from '../../components/Page';

const FactoryIndex = () => {
  return (
    <Page title="Factory">
      <div style={{ padding: '20px' }}>
        {/* <FactoryIndexHeader /> */}
        <FactoryIndexBody />
      </div>
    </Page>
  );
};

export default FactoryIndex;
