import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import apiURL from '../../api';
import { PATH_AUTH } from '../../routes/paths';

export const LoginUser = createAsyncThunk(
  'auth/LoginUser',
  async ({ storeId, phone, password, navigate }, thunkAPI) => {
    try {
      const response = await apiURL.post(`/auth/webLogin`, {
        storeIdentifier: storeId,
        phone,
        password,
      });
      if (response.status === 200) {
        localStorage.removeItem('loginData');
        navigate('/dashboard/app');
      }

      return response.data;
    } catch (error) {
      localStorage.removeItem('profiles');
      localStorage.setItem('loginData', JSON.stringify({ storeId, phone, password }));
      if (error.response.status === 404) {
        toast.error('User Not Found');
      } else if (error.response.status === 401) {
        localStorage.removeItem('profiles');
        toast.error('User is not authorized');
      } else {
        toast.error(error?.response?.data?.message);
      }
      navigate('/auth/login');
    }
  }
);

export const logoutAction = (navigate) => (dispatch) => {
  dispatch(setLogout());
  dispatch({ type: 'USER_LOGOUT' });
  navigate(PATH_AUTH.login, { replace: true });
};

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    user: {},
    // error: '',
    isAuth: null,
    loading: false,
  },
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
      state.isAuth = true;
    },
    setLogout: (state) => {
      localStorage.removeItem('factoryUserDetails');
      localStorage.removeItem('factorytoken');
      state.user = null;
      state.isAuth = null;
    },
  },

  extraReducers: {
    [LoginUser.pending]: (state, action) => {
      state.postLoading = true;
    },
    [LoginUser.fulfilled]: (state, action) => {
      state.postLoading = false;
      localStorage.setItem('profiles', JSON.stringify({ tokenDTO: action?.payload?.tokenDTO }));
      state.user = action?.payload;
      state.isAuth = true;
    },
    [LoginUser.rejected]: (state, action) => {
      state.postLoading = false;
      state.error = action?.payload?.message;
    },
  },
});
export const { setUser, setLogout } = authSlice.actions;

export default authSlice.reducer;
