import { Stack } from '@mui/material';
import React from 'react';
import DessertChooseCategory from './DessertChooseCategory';
import DessertChooseBatch from './DessertChooseBatch';
import SanitationCheckList from '../SanitationCheckList';
import DessertIngredients from './DessertIngredients';
import ProcessingLoadingScreen from '../ProcessingLoadingScreen';
import DessertQuality from './DessertQuality';
import DessertSummary from './DessertSummary';

function DessertDepartmentActiveStepper({ activeStep }) {
  return (
    <Stack>
      {activeStep === 0 && <DessertChooseCategory />}
      {activeStep === 1 && <DessertChooseBatch />}
      {activeStep === 2 && <SanitationCheckList />}
      {activeStep === 3 && <DessertIngredients />}
      {activeStep === 4 && <ProcessingLoadingScreen />}
      {activeStep === 5 && <DessertQuality />}
      {activeStep === 6 && <DessertSummary />}
    </Stack>
  );
}

export default DessertDepartmentActiveStepper;
