import React from 'react';
import RawMeatChooseCategory from './RawMeatChooseCategory';
import RawMeatChooseBatch from './RawMeatChooseBatch';
import SanitationCheckList from '../SanitationCheckList';
import RawMeatIngredient from './RawMeatIngredient';
import ProcessingLoadingScreen from '../ProcessingLoadingScreen';
import RawMeatQuality from './RawMeatQuality';
import RawMeatSummary from './RawMeatSummary';
import { Stack } from '@mui/material';

function RawMeatDepartmentActiveStepper({ activeStep }) {
  return (
    <Stack>
      {activeStep === 0 && <RawMeatChooseCategory />}
      {activeStep === 1 && <RawMeatChooseBatch />}
      {activeStep === 2 && <SanitationCheckList />}
      {activeStep === 3 && <RawMeatIngredient />}
      {activeStep === 4 && <ProcessingLoadingScreen />}
      {activeStep === 5 && <RawMeatQuality />}
      {activeStep === 6 && <RawMeatSummary />}
    </Stack>
  );
}

export default RawMeatDepartmentActiveStepper;
