import axios from 'axios';

const api_url = process.env.REACT_APP_API_BASE_URL;

export function checkLocalStorage() {
  const getToken = localStorage.getItem('factorytoken');
  if (!getToken) return '';
  const localStorageToken = JSON.parse(getToken)?.tokenDTO?.accessToken;
  return `Bearer ${localStorageToken}`;
}

const instance = axios.create({
  baseURL: `${api_url}/`,
  headers: {
    Authorization: checkLocalStorage(),
  },
});

instance.interceptors.request.use(
  (config) => {
    config.headers.Authorization = checkLocalStorage();
    return config;
  },
  (error) => {
    localStorage.removeItem('factoryToken');
    Promise.reject(error);
  }
);

export default instance;
