import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getDepartmentList, getDeptItemList } from '../../../../redux/slices/departmentSlice';
import { FormProvider, useForm } from 'react-hook-form';
import { Card } from 'antd';
import CustomSelectField from '../../../../components/CustomSelectField';
import { MenuItem } from '@mui/material';
import DeptItemList from './DeptItemList';

const DeptItemBody = () => {
   const dispatch = useDispatch();
   const [deptItemFilteredList, setDeptItemFilteredList] = React.useState([]);

   const { deptItemList, departmentList, loading } = useSelector((state) => state.department);

   const defaultValues = {
      dept_id: null,
   };
   const methods = useForm({ defaultValues });
   const { watch } = methods;
   const watchDeptId = watch('dept_id');

   useEffect(() => {
      dispatch(getDeptItemList());
      dispatch(getDepartmentList());
   }, [dispatch]);

   useEffect(() => {
      if (!watchDeptId) return;
      if (!deptItemList?.length) return;
      setDeptItemFilteredList(deptItemList?.filter((item) => item?.dept_id?.dept_id === watchDeptId));
   }, [watchDeptId, deptItemList]);

   return (
      <Card sx={{ p: 2 }}>
         <FormProvider {...methods}>
            <CustomSelectField name="dept_id" label="Select Department" sx={{ width: '15rem' }}>
               {departmentList?.map((option) => (
                  <MenuItem key={option.dept_id} value={option.dept_id}>
                     {option.dept_name}
                  </MenuItem>
               ))}
            </CustomSelectField>
            {watchDeptId && <DeptItemList items={deptItemFilteredList} />}
         </FormProvider>
      </Card>
   );
};

export default DeptItemBody;
