import DeptItemHeader from './DeptItemHeader'
import DeptItemBody from './DeptItemBody'
import React from 'react'
import Page from '../../../../components/Page'

const DepartmentItemIndex = () => {
   return (
      <Page title="Department Item">
         <DeptItemHeader />
         <DeptItemBody />
      </Page>
   )
}

export default DepartmentItemIndex