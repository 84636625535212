import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import { PATH_FACTORY } from '../../routes/paths';

const FactoryDepartmentSection = () => {
  const navigate = useNavigate();
  const deptList = [
    { name: 'Sause', url: 'sauce', width: '32.5%' },
    { name: 'Momo', url: 'cooked-food', width: '32.5%' },
    { name: 'Cooked Food', url: 'cooked-food', width: '32.5%' },
    { name: 'Raw Meat', url: 'raw-meat', width: '32.5%' },
    { name: 'Dessert', url: 'dessert', width: '32.5%' },
  ];
  return (
    <Box
      sx={{
        backgroundColor: 'RGB(111, 111, 111)', // Average color of the background image.
        marginTop: '10px',
        display: 'flex',
        flexWrap: 'wrap',
        minWidth: 300,
        width: '100%',
        justifyContent: 'center', // Center the box horizontally
        gap: 1, // Spacing between ImageButtons
      }}
    >
      {deptList.map((dept) => (
        <ImageButton
          focusRipple
          key={dept.name}
          style={{
            width: dept.width,
          }}
          onClick={() => {
            if (dept.name === 'Momo') {
              console.log('yes to momo');
              navigate(PATH_FACTORY.momo.root);
            }
            if (dept.name === 'Sause') {
              navigate(PATH_FACTORY.sause.root);
              console.log('yes to sauce');
            }
            if (dept.name === 'Dessert') {
              console.log('yes to dessert');
              navigate(PATH_FACTORY.dessert.root);
            }
            if (dept.name === 'Cooked Food') {
              console.log('yes to Cooked Food');
              navigate(PATH_FACTORY.cookedFood.root);
            }
            if (dept.name === 'Raw Meat') {
              console.log('yes to Raw Meat');
              navigate(PATH_FACTORY.rawMeat.root);
            }
            console.log('dept.name', dept.name);
          }}
        >
          <ImageSrc style={{ backgroundImage: `url(${require(`./../../assets/${dept.url}.jpg`)})` }} />
          <ImageBackdrop className="MuiImageBackdrop-root" />
          <Image>
            <Typography
              component="span"
              variant="subtitle1"
              color="inherit"
              sx={{
                position: 'relative',
                p: 4,
                pt: 2,
                pb: (theme) => `calc(${theme.spacing(1)} + 6px)`,
              }}
            >
              {dept.name}
              <ImageMarked className="MuiImageMarked-root" />
            </Typography>
          </Image>
        </ImageButton>
      ))}
    </Box>
  );
};

export default FactoryDepartmentSection;

const ImageButton = styled(ButtonBase)(({ theme }) => ({
  position: 'relative',
  height: 200,
  [theme.breakpoints.down('sm')]: {
    width: '100% !important', // Overrides inline-style
    height: 100,
  },
  '&:hover, &.Mui-focusVisible': {
    zIndex: 1,
    '& .MuiImageBackdrop-root': {
      opacity: 0.15,
    },
    '& .MuiImageMarked-root': {
      opacity: 0,
    },
    '& .MuiTypography-root': {
      border: '4px solid currentColor',
    },
  },
}));

const ImageSrc = styled('span')({
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  backgroundSize: 'cover',
  backgroundPosition: 'center 40%',
});

const Image = styled('span')(({ theme }) => ({
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: theme.palette.common.white,
}));

const ImageBackdrop = styled('span')(({ theme }) => ({
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  backgroundColor: theme.palette.common.black,
  opacity: 0.4,
  transition: theme.transitions.create('opacity'),
}));

const ImageMarked = styled('span')(({ theme }) => ({
  height: 3,
  width: 18,
  backgroundColor: theme.palette.common.white,
  position: 'absolute',
  bottom: -2,
  left: 'calc(50% - 9px)',
  transition: theme.transitions.create('opacity'),
}));
