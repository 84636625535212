import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { FormControlLabel, Checkbox, Card, CardContent, Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import CustomLoadingScreen from '../../../components/CustomLoadingScreen';

function RawMeatChooseCategory() {
  const { departmentList, loading } = useSelector((state) => state.department);

  const [selectedCategory, setSelectedCategory] = useState('');
  const { setValue, watch } = useFormContext();
  const formSelectedCategory = watch('selectedCategory');
  const [uniqueCategories, setUniqueCategories] = useState(new Set());

  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
    setValue('selectedCategory', category); // Set the form value
  };

  useEffect(() => {
    if (formSelectedCategory) {
      setSelectedCategory(formSelectedCategory);
    }
  }, [formSelectedCategory]);

  useEffect(() => {
    const momoDepartment = departmentList.find((dept) => dept?.deptName?.toLowerCase() === 'raw meat');
    const dataSet = new Set();
    momoDepartment?.categoryList.forEach((category) => {
      dataSet.add(category);
    });
    setUniqueCategories(dataSet);
  }, [departmentList]);
  return (
    <div>
      <Typography variant="h5" gutterBottom>
        Choose Raw Meat Category
      </Typography>
      {loading ? (
        <CustomLoadingScreen height="20vh" />
      ) : (
        <>
          {Array.from(uniqueCategories).map((category, index) => (
            <Card key={index} sx={{ marginBottom: 2 }}>
              <CardContent>
                <FormControlLabel
                  control={
                    <Checkbox checked={selectedCategory === category} onChange={() => handleCategoryChange(category)} />
                  }
                  label={category}
                />
              </CardContent>
            </Card>
          ))}
        </>
      )}
    </div>
  );
}

export default RawMeatChooseCategory;
