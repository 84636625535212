import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

const DashboardCard = ({ title, total, icon, color = 'primary', sx, textColor = '#fff', ...other }) => {
  return (
    <Card
      component={Stack}
      spacing={3}
      direction="row"
      sx={{
        px: 3,
        py: 5,
        borderRadius: 2,
        mr: 2,
        mt: 2,
        ...sx,
      }}
      {...other}
      style={{ backgroundColor: color }}
    >
      {icon && <Box sx={{ width: 64, height: 64 }}>{icon}</Box>}

      <Stack spacing={0.5}>
        <Typography variant="h4" style={{ color: textColor, whiteSpace: 'nowrap' }}>
          {total}
        </Typography>

        <Typography style={{ color: textColor }}>{title}</Typography>
      </Stack>
    </Card>
  );
};

export default DashboardCard;
