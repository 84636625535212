import { CodeSandboxOutlined, DollarCircleFilled, DotChartOutlined, WarningOutlined } from '@ant-design/icons';
import { Container, Grid, Paper, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Bar, BarChart, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import Page from '../../components/Page';
import DashboardCard from '../../components/Widgets/DashboardCard';
import { getDepartmentProductionList } from '../../redux/slices/departmentSlice';
import { getChartData, getProductReport } from '../../redux/slices/productSlice';
import noData from '../../assets/nodata.jpg';

function GeneralApp() {
  const dispatch = useDispatch();

  const productsReport = useSelector((state) => state.products.productReport);
  const finishedData = useSelector((state) => state.products.chartData);
  const deptReport = useSelector((state) => state.department.deptProductionList);
  const chartData = Object.entries(finishedData).map(([date, value]) => ({ name: date, value }));

  console.log(deptReport, 'report');
  const { totalInventory, expiredCount, inventoryValue, lowFreshnessCount, todaysProduction, monthlyProduction } =
    productsReport;
  useEffect(() => {
    dispatch(getProductReport());
    dispatch(getChartData());
    dispatch(getDepartmentProductionList());
  }, []);


  console.log(deptReport, 'deptReport');
  return (
    <Page title="Overview">
      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>
          Welcome to Inventory Management
        </Typography>

        <Grid container spacing={2}>
          <Grid xs={12} sm={6} md={3}>
            <DashboardCard
              title="Total Inventory"
              total={totalInventory}
              color="#1FB57D"
              icon={<CodeSandboxOutlined style={{ fontSize: '64px', color: '#FFF' }} />}
            />
          </Grid>
          <Grid xs={12} sm={6} md={3}>
            <DashboardCard
              title="Monthly Production"
              total={monthlyProduction}
              color="#A1C349"
              icon={<DotChartOutlined style={{ fontSize: '64px', color: '#FFF' }} />}
            />
          </Grid>
          <Grid xs={12} sm={6} md={3}>
            <DashboardCard
              title="Inventory Value"
              total={inventoryValue}
              color="#084887"
              icon={<DollarCircleFilled style={{ fontSize: '64px', color: '#FFF' }} />}
            />
          </Grid>
          <Grid xs={12} sm={6} md={3}>
            <DashboardCard
              title="Expiry Warning"
              total={Number(lowFreshnessCount + expiredCount)}
              color="#C3423F"
              icon={<WarningOutlined style={{ fontSize: '64px', color: '#FFF' }} />}
            />
          </Grid>
        </Grid>
      </Container>

      <Container maxWidth="xl" sx={{ marginTop: '32px' }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6}>
            {' '}
            <Paper
              sx={{
                py: 2.5,
                textAlign: 'center',
                borderStyle: 'solid',
                width: '100%',
                height: '100%',
              }}
              variant="outlined"
            >
              <Typography variant="h6" style={{ margin: '1rem' }}>
                Weekly Productions
              </Typography>
              <ResponsiveContainer width="100%" height="80%">
                <LineChart
                  width={400}
                  height={400}
                  data={chartData.reverse()}
                  margin={{
                    top: 10,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                >
                  <XAxis dataKey="name" />
                  <Tooltip />
                  <Legend />
                  <Line
                    type="monotone"
                    dataKey="value"
                    strokeWidth={5}
                    stroke="#8884d8"
                    activeDot={{ r: 9, color: '#1FB57D' }}
                    animationBegin={0}
                    name="Finished Goods"
                  />
                </LineChart>
              </ResponsiveContainer>
            </Paper>
          </Grid>

          <Grid item xs={12} sm={12} md={6}>
            <Paper
              sx={{
                py: 2.5,
                textAlign: 'center',
                borderStyle: 'solid',
                width: '100%',
                height: '100%',
              }}
              variant="outlined"
            >
              <Typography variant="h6" style={{ margin: '1rem' }}>
                {deptReport.length > 0 ? 'Finished Goods in Past 7 days' : 'No finished Goods in Past 7 Days'}
              </Typography>

              {deptReport.length === 0 ? (
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <img src={noData} alt="no-data-found" style={{ height: '100%', width: '100%' }} />
                </div>
              ) : (
                <ResponsiveContainer width="100%" height="80%">
                  <BarChart width={150} height={40} data={deptReport}>
                    <XAxis dataKey="deptName" />
                    <YAxis />

                    <Tooltip />
                    <Legend />
                    <Bar
                      dataKey="pastSevenDayProduction"
                      fill="#1FB57D"
                      name="Finished Goods"
                      animationBegin={0}
                      animationDuration={100}
                      isAnimationActive={true}
                      animationEasing="ease-in-out"
                    />
                  </BarChart>
                </ResponsiveContainer>
              )}
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}

export default GeneralApp;
