import { ToastContainer } from "react-toastify";
import Router from "./routes";
import 'react-toastify/dist/ReactToastify.css';
import './css/darkday.scss';
import axios from 'axios';



function App() {
    return (
        <>
            <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored" />
            <Router />
        </>
    );
}

export default App;
