import React from 'react';
import Page from '../../../components/Page';
import VendorHeader from './VendorHeader';
import VendorBody from './VendorBody';

const VendorIndex = () => {
  return (
    <Page title="Vendors">
      <VendorHeader />
      <VendorBody />
    </Page>
  );
};

export default VendorIndex;
