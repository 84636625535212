export const baseUrl = process.env.REACT_APP_API_BASE_URL;


export const API = {
  products: 'https://653fdb2b45bedb25bfc15bf0.mockapi.io/api/products/',
  productRef: {
    allProductsRef: '/productref',
    addProductRef: '/productref/add',
    deleteProductRef: '/productref/delete/',
    updateProductRef: '/productref/update',
  },

  vendor: {
    allVendors: '/vendor/all',
    addVendors: '/vendor/save',
    delete: '/vendor/delete/',
  },
  inventoryProducts: {
    allProducts: '/product/all',
    batchProducts: '/product/all-batch',
    addProducts: '/product/save',
    deleteProduct: '/product/delete/',
    batchDeleteProduct: '/product/batch-delete',
  },
  report: {
    productReport: '/product/report',
    finishedData: '/product/finished-good-report',
  },
  workerPerfomance: {
    performanceReport: '/user/performance',
  },
  haccpPlan: {
    uploadFile: '/file/upload',
  },
};
