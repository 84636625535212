import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CustomLoadingScreen from '../../../components/CustomLoadingScreen';
import { Accordion, AccordionDetails, AccordionSummary, Button, Stack } from '@mui/material';
import DepartmentBodyTable from './DepartmentBodyTable';
import Iconify from '../../../components/Iconify';
import AddDepartment from './AddDepartment';
import { deleteDepartment } from '../../../redux/slices/departmentSlice';
import { toast } from 'react-toastify';
import CustomDeleteDialog from '../../../components/CustomDeleteDialog';

const DepartmentBody = () => {
  const { departmentAllData, loading } = useSelector((state) => state.department);
  const [openAdd, setOpenAdd] = React.useState({ status: false, deptName: '' });
  const [resultList, setResultList] = React.useState([]);
  const [deleteDept, setDeleteDept] = React.useState({ status: false, deptId: null });
  const [expanded, setExpanded] = React.useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    const groupedData = departmentAllData.reduce((acc, currentItem) => {
      const { deptName, ...rest } = currentItem;
      if (!acc[deptName]) {
        acc[deptName] = { deptName, data: [] };
      }
      acc[deptName].data.push(rest);
      return acc;
    }, {});

    const resultList = Object.values(groupedData);
    setResultList(resultList);

    if (resultList.length > 0 && expanded === null) {
      setExpanded(resultList[0].deptName);
    }
  }, [departmentAllData]);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : null);
  };

  const handleDelete = (deptId) => {};
  const user = JSON.parse(localStorage.getItem('factoryUserDetails'));
  const userGroup = user?.roles;

  return (
    <>
      {deleteDept.status && (
        <CustomDeleteDialog
          status={deleteDept.status}
          handleClose={() => setDeleteDept({ id: null, status: false })}
          onAccept={() => {
            dispatch(
              deleteDepartment({
                id: deleteDept.id,
                isSuccess: (success) => {
                  if (success) {
                    toast.success('Department deleted successfully');
                    setDeleteDept({ id: null, status: false });
                  }
                },
              })
            );
          }}
        />
      )}

      {loading ? (
        <CustomLoadingScreen height="30vh" />
      ) : (
        <div>
          {resultList?.map((department, index) => (
            <Accordion
              sx={{ paddingBlock: '0.1rem' }}
              key={index}
              expanded={expanded === department.deptName}
              onChange={handleChange(department.deptName)}
              defaultExpanded={index === 0}
            >
              {console.log(department, 'department')}
              <AccordionSummary
                expandIcon={<Iconify icon="ic:outline-expand-more" width="20px" height="20px" />}
                aria-controls="Department with Items"
                id={department.deptName}
                style={{ height: '3.5rem', borderBottom: '0.5px solid rgba(145, 158, 171, 0.24)' }}
              >
                <Stack direction={'row'} justifyContent={'space-between'} width="100%">
                  {department.deptName}
                  <Stack className={'manageDeptItemAccordionButton'}>
                    {(userGroup.includes('MANAGER') || userGroup.includes('ADMIN')) && (
                      <>
                        <Button
                          variant="outlined"
                          size="small"
                          onClick={(e) => {
                            e.stopPropagation(); // Stop event propagation
                            setOpenAdd({ status: true, data: department.deptName });
                          }}
                          startIcon={<Iconify icon="ic:outline-add" width="20px" height="20px" />}
                          style={{ textTransform: 'none' }}
                        >
                          Item
                        </Button>
                        <Button
                          variant="outlined"
                          color="error"
                          size="small"
                          onClick={(e) => {
                            e.stopPropagation();
                            setDeleteDept({ id: department.data[0].deptId, status: true });
                          }}
                          startIcon={<Iconify icon="ic:outline-delete" width="20px" height="20px" />}
                          style={{ textTransform: 'none' }}
                        >
                          Delete Department
                        </Button>
                      </>
                    )}
                  </Stack>
                </Stack>
              </AccordionSummary>
              <AccordionDetails>
                <DepartmentBodyTable department={department} />
              </AccordionDetails>
            </Accordion>
          ))}
        </div>
      )}
      {openAdd.status && (
        <AddDepartment isDept open={openAdd.status} setOpen={setOpenAdd} departmentName={openAdd.data} />
      )}
    </>
  );
};

export default DepartmentBody;
