import React from 'react';
import DashboardDefaultHeader from '../layouts/dashboard/header/DashboardDefaultHeader';

const SorryIndex = () => {
  const containerStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    textAlign: 'center',
    padding: '0 20px',
  };

  const textStyle = {
    fontSize: '24px',
    fontWeight: 'bold',
    color: '#333', // Change color to your preference
  };

  return (
    <>
      <DashboardDefaultHeader />
      <div style={containerStyle}>
        <p style={textStyle}>
          Sorry, you are not allowed to access this resource. <br /> Please contact the admin.
        </p>
      </div>
    </>
  );
};

export default SorryIndex;
