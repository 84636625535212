import { CircularProgress } from '@mui/material';
import { Box } from '@mui/system';
import PropTypes from 'prop-types';
import React from 'react';

CustomLoadingScreen.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  circularWidth: PropTypes.string,
  color: PropTypes.string,
};

function CustomLoadingScreen({ width = '100%', height = '100%', circularWidth = '40px', color = 'primary' }) {
  return (
    <Box
      style={{
        width: `${width}`,
        height: `${height}`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <CircularProgress size={circularWidth} color={color} />
    </Box>
  );
}

export default CustomLoadingScreen;
