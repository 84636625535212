import React from 'react';
import DepartmentHeader from './DepartmentHeader';
import DepartmentBody from './DepartmentBody';
import Page from '../../../components/Page';
import { useDispatch } from 'react-redux';
import { getDepartmentAllData, getDepartmentList } from '../../../redux/slices/departmentSlice';

const DepartmentIndex = () => {
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(getDepartmentList());
    dispatch(getDepartmentAllData());
  }, [dispatch]);
  return (
    <Page title="Departments">
      <DepartmentHeader />
      <DepartmentBody />
    </Page>
  );
};

export default DepartmentIndex;
