import React, { useEffect } from 'react';
import { useForm, FormProvider, Controller } from 'react-hook-form';
import { MenuItem, Button, Grid, Typography, Input, Paper } from '@mui/material';
import RHFSelect from '../../../components/hookform/RHFSelect';
import { Upload } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import Page from '../../../components/Page';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import { PATH_DASHBOARD } from '../../../routes/paths';
import { useDispatch, useSelector } from 'react-redux';
import { getDepartmentList } from '../../../redux/slices/departmentSlice';
import { API } from '../../../utils/url';
import instance from '../../../api';
import { toast } from 'react-toastify';
import { UploadFile } from '@mui/icons-material';

const HACCP = () => {
  const methods = useForm();
  const dispatch = useDispatch();
  const { handleSubmit, control, register, reset, watch } = methods;

  const { departmentList } = useSelector((state) => state.department);

  const onSubmit = async (data) => {
    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      if (key === 'file') {
        formData.append(key, data[key][0]);
      } else {
        formData.append(key, data[key]);
      }
    });

    try {
      const res = await instance.post(API.haccpPlan.uploadFile, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      if (res.status === 200) {
        
        toast.success(`${res.data.data.message} successfully`);
       
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  useEffect(() => {
    dispatch(getDepartmentList());
  }, []);

  const selectedDepartment = watch('departmentName');
  const selectedDepartmentData = departmentList.find((department) => department.deptName === selectedDepartment);
  const uniqueCategories = selectedDepartmentData ? [...new Set(selectedDepartmentData.categoryList)] : [];
  return (
    <Page title="Manage HACCP Plan">
      <HeaderBreadcrumbs
        links={[
          { name: 'Dashboard', href: PATH_DASHBOARD.root },
          { name: 'HACCP Plan', href: PATH_DASHBOARD.haccpPlan.root },
        ]}
        heading="Manage HACCP Plan"
      />

      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={3} style={{ padding: '20px' }}>
            <Grid item xs={12} sm={6}>
              <RHFSelect name="departmentName" label="Department" selectProps={{ ...register('departmentName') }}>
                {departmentList.map((dept, index) => (
                  <MenuItem key={index} value={dept.deptName}>
                    {dept.deptName}
                  </MenuItem>
                ))}
              </RHFSelect>
            </Grid>
            <Grid item xs={12} sm={6}>
              <RHFSelect name="deptCategory" label="Department Category" selectProps={{ ...register('deptCategory') }}>
                {uniqueCategories.map((category, index) => (
                  <MenuItem key={index} value={category}>
                    {category}
                  </MenuItem>
                ))}
              </RHFSelect>
            </Grid>
          </Grid>

          <div
            style={{
              marginTop: '20px',
              padding: '20px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
            }}
          >
            <h4 style={{ display: 'flex' }}>File Upload</h4>
            <div style={{ display: 'flex' }}>
              <Grid container style={{ display: 'flex' }}>
                <Grid item xs={12} md={12} lg={12}>
                  <Paper elevation={3} style={{ padding: '20px', marginTop: '10px' }}>
                    <Controller
                      name="file"
                      control={control}
                      defaultValue={[]}
                      render={({ field: { onChange, value } }) => (
                        <Upload.Dragger
                          name="file"
                          beforeUpload={(file) => {
                            onChange([file]);
                            return false;
                          }}
                          fileList={value || []}
                        >
                          <p className="ant-upload-drag-icon">
                            <UploadFile fontSize="large" />
                          </p>
                          <p className="ant-upload-text">Click or drag file to this area to upload</p>
                        </Upload.Dragger>
                      )}
                    />
                  </Paper>
                </Grid>
              </Grid>
            </div>
          </div>

          <div style={{ marginTop: '20px', padding: '20px' }}>
            <Button type="submit" variant="contained" color="primary">
              Submit
            </Button>
          </div>
        </form>
      </FormProvider>
    </Page>
  );
};

export default HACCP;
