// import React from 'react';
// import Page from '../../components/Page';
// import { styled } from '@mui/material/styles';
// import Logo from '../../components/logo';
// import Image from '../../components/Image';

// import { Card } from 'antd';
// import { Box, Container, Typography } from '@mui/material';
// import useResponsive from '../../hooks/useResponsive';
// import LoginForm from '../../sections/auth/LoginForm';

// const RootStyle = styled('div')(({ theme }) => ({
//   [theme.breakpoints.up('md')]: {
//     display: 'flex',
//   },
// }));

// const HeaderStyle = styled('header')(({ theme }) => ({
//   top: 0,
//   zIndex: 9,
//   lineHeight: 0,
//   width: '100%',
//   display: 'flex',
//   alignItems: 'center',
//   position: 'absolute',
//   padding: theme.spacing(3),
//   justifyContent: 'space-between',
//   [theme.breakpoints.up('md')]: {
//     alignItems: 'flex-start',
//     padding: theme.spacing(7, 5, 0, 7),
//   },
// }));

// const SectionStyle = styled(Card)(({ theme }) => ({
//   width: '100%',
//   maxWidth: 464,
//   display: 'flex',
//   flexDirection: 'column',
//   justifyContent: 'center',
//   margin: theme.spacing(2, 0, 2, 2),
// }));

// const Login = () => {
//   const mdUp = useResponsive('up', 'md');
//   return (
//     <Page title="Login">
//       <RootStyle>
//         <HeaderStyle>
//           <Logo />
//         </HeaderStyle>

//         {mdUp && (
//           <SectionStyle>
//             <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
//               Hi, Welcome Back
//             </Typography>
//             <Image visibleByDefault disabledEffect src="/assets/TASTY-N-BESTY-LOGO.png" alt="login" />
//           </SectionStyle>
//         )}
//         <Container maxWidth="sm">
//           <Box style={{ marginTop: '80px' }}>
//             <Typography variant="h3" gutterBottom>
//               Sign in
//             </Typography>

//             <Typography sx={{ color: 'text.secondary' }}>Enter your details below.</Typography>
//             <LoginForm />
//             {/* <Stack direction="row" spacing={0.5}>
//                      <Typography variant="body2">New user?</Typography>

//                      <Link component={RouterLink} to={PATH_AUTH.register} variant="subtitle2">
//                         Create an account
//                      </Link>
//                   </Stack> */}
//           </Box>
//         </Container>
//       </RootStyle>
//     </Page>
//   );
// };

// export default Login;

import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Logo from '../../components/logo';
import { useNavigate } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { CognitoUser, AuthenticationDetails } from 'amazon-cognito-identity-js';
import UserPool from '../../UserPool';
import {
  Alert,
  Card,
  FormControl,
  FormLabel,
  IconButton,
  InputAdornment,
  Radio,
  RadioGroup,
  Stack,
} from '@mui/material';
import Iconify from '../../components/Iconify';
import CustomLoadingScreen from '../../components/CustomLoadingScreen';
import Page from '../../components/Page';
import LogoImg from '../../assets/tnb-logo.png';

const defaultTheme = createTheme();

export default function SignInSide() {
  const loginData = JSON.parse(localStorage.getItem('loginData'));
  const [loading, setLoading] = React.useState(false);
  const [selectedRole, setSelectedRole] = React.useState('inventory'); // Default role
  console.log('🚀 => SignInSide => selectedRole=>', selectedRole);

  const [password, setPassword] = React.useState(loginData?.password || '');
  const [username, setUsername] = React.useState(loginData?.username || '');

  const [showPassword, setShowPassword] = React.useState(false);
  const navigate = useNavigate();

  const methods = useForm({});

  const {
    formState: { errors },
  } = methods;

  const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
  const handleSubmit = (e) => {
    e.preventDefault();
    if (username === '' || password === '') {
      return toast.error('Please fill the details.');
    }
    if (password.length < 8 || !password.match(passwordRegex)) {
      return toast.error(
        'Password must be at least 8 characters and contain at least one lowercase letter, one uppercase letter, one digit, and one special character.'
      );
    }
    setLoading(true);

    const user = new CognitoUser({
      Username: username,
      Pool: UserPool,
    });

    const authDetails = new AuthenticationDetails({
      Username: username,
      Password: password,
    });

    user.authenticateUser(authDetails, {
      onChangePassword: (data) => {
        console.log('🚀 => handleSubmit => data=>', data);
      },
      onSuccess: (data) => {
        const payload = data.getIdToken().decodePayload();

        const username = payload['cognito:username'];
        const email = payload.email;
        const familyName = payload.family_name;
        const givenName = payload.given_name;
        const roles = payload['cognito:groups'];

        const userDetails = {
          username,
          email,
          roles,
          fullname: `${givenName} ${familyName}`,
          selectedRole,
        };
        const userDetailsJSON = JSON.stringify(userDetails);
        localStorage.setItem('factoryUserDetails', userDetailsJSON);

        // Extract access token and refresh token from data
        const accessToken = data.getAccessToken().getJwtToken();
        const refreshToken = data.getRefreshToken().getToken();

        // Create the object you want to store
        const factoryToken = {
          tokenDTO: {
            accessToken,
            refreshToken,
          },
        };

        // Serialize the object to JSON
        const factoryTokenJSON = JSON.stringify(factoryToken);

        // Store it in localStorage
        localStorage.setItem('factorytoken', factoryTokenJSON);
        if (roles === null || roles === undefined) {
          return navigate('/', { replace: true });
        }
        if (selectedRole === 'inventory') {
          navigate('/dashboard', { replace: true });
        } else if (selectedRole === 'factory') {
          navigate('/factory', { replace: true });
        }
      },
      onFailure: (err) => {
        setLoading(false);
        toast.error(err.message || JSON.stringify(err));
      },
    });
  };

  return (
    <Page title="login">
      <ThemeProvider theme={defaultTheme}>
        <Box
          sx={{
            py: 2,
            px: 4,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '10vh',
            backgroundColor: 'black',
          }}
        >
          <div>
            <img src={LogoImg} alt="logo" style={{ width: '140px' }} />
          </div>
        </Box>
        <Grid container component="main" sx={{ height: '90vh' }}>
          <CssBaseline />
          {/* <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: `url(${require('../../assets/logo512.png')})`,
            // backgroundImage: 'url(https://images.unsplash.com/photo-1549472579-e133f59d8b23?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8d2FsbHBhcGVyc3x8fHx8fDE3MDA2NjgwMDA&ixlib=rb-4.0.3&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1080)',
            // backgroundImage: 'url(https://source.unsplash.com/random?wallpapers)',
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) => (t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900]),
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        /> */}
          <Grid item xs={12} component={Paper} elevation={6} square>
            <Box
              sx={{
                my: 8,
                mx: 4,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Card
                sx={{
                  padding: '20px',
                  textAlign: 'center',
                  //backgroundColor: '#a1a19a'
                }}
              >
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <Avatar>
                    <LockOutlinedIcon />
                    {/* <img src={LogoImg} alt="logo" style={{ width: '100%' }} /> */}
                  </Avatar>
                  <Typography component="h1" variant="h5">
                    Sign in
                  </Typography>
                </Box>
                <FormProvider {...methods}>
                  <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
                    {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>}

                    <TextField
                      value={username}
                      fullWidth
                      type="text"
                      onChange={(e) => setUsername(e.target.value)}
                      label="Username"
                      style={{ marginBottom: '10px' }}
                    />
                    <TextField
                      value={password}
                      fullWidth
                      type={showPassword ? 'text' : 'password'}
                      onChange={(e) => setPassword(e.target.value)}
                      label="Password"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                              <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      style={{ marginBottom: '10px' }}
                    />
                    {/* <Stack direction={'row'}>
                      <FormControl component="fieldset" sx={{ my: 2, display: 'flex', flexDirection: 'row' }}>
                        <FormLabel component="legend">Select Role</FormLabel>
                        <RadioGroup
                          row
                          aria-label="role"
                          name="role"
                          value={selectedRole}
                          onChange={(e) => setSelectedRole(e.target.value)}
                        >
                          <FormControlLabel value="inventory" control={<Radio />} label="Inventory" />
                          <FormControlLabel value="factory" control={<Radio />} label="Factory" />
                        </RadioGroup>
                      </FormControl>
                    </Stack> */}
                    {/* Add RadioGroup for role selection */}
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                      <FormControl component="fieldset" sx={{ my: 2 }}>
                        <FormLabel component="legend">Select Dashboard</FormLabel>
                        <RadioGroup
                          row
                          aria-label="role"
                          name="role"
                          value={selectedRole}
                          onChange={(e) => setSelectedRole(e.target.value)}
                        >
                          <Stack direction="row">
                            <FormControlLabel value="inventory" control={<Radio />} label="Inventory" />
                            <FormControlLabel value="factory" control={<Radio />} label="Factory" />
                          </Stack>
                        </RadioGroup>
                      </FormControl>
                    </Box>
                    <Stack>
                      <Button
                        disabled={loading}
                        fullWidth
                        size="large"
                        onClick={handleSubmit}
                        variant="contained"
                        loading={loading}
                      >
                        {loading ? <CustomLoadingScreen circularWidth="20px" /> : 'Login'}
                      </Button>
                    </Stack>
                  </Box>
                </FormProvider>
              </Card>
            </Box>
          </Grid>
        </Grid>
      </ThemeProvider>
    </Page>
  );
}
